// import Editor from './Editor'
import React, { Component } from 'react'
import { FaTrash } from 'react-icons/fa'
import Paper from '@material-ui/core/Paper'
import {
  ServicoModeloUm,
  ServicoModeloDois
} from '../modelos/servico/ModeloServico'
import {
  TrabalhoModeloUm,
  TrabalhoModeloDois,
  TrabalhoModeloTres
} from '../modelos/trabalho/ModeloTrabalho'
import { ModeloBlogUm, ModeloBlogDois } from '../modelos/blog/ModeloBlog'

import templateTrabalhoUm from '../modelos/trabalho/templateTrabalhoUm'
import templateTrabalhoDois from '../modelos/trabalho/templateTrabalhoDois'
import templateTrabalhoTres from '../modelos/trabalho/templateTrabalhoTres'
import templateServico from '../modelos/servico/template'
import templateBlogUm from '../modelos/blog/template'

import EditorJs from 'react-editor-js'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import Image from '@editorjs/image'
import Embed from '@editorjs/embed'
import Quote from '@editorjs/quote'
const templates = {
  'servico-modelo-um': {
    modelo: ServicoModeloUm,
    template: templateServico,
    uploadPath: 'servicos/do_upload'
  },
  'servico-modelo-dois': {
    modelo: ServicoModeloDois,
    template: templateServico,
    uploadPath: 'servicos/do_upload'
  },
  'trabalho-modelo-um': {
    modelo: TrabalhoModeloUm,
    template: templateTrabalhoUm
  },
  'trabalho-modelo-dois': {
    modelo: TrabalhoModeloDois,
    template: templateTrabalhoDois
  },
  'trabalho-modelo-tres': {
    modelo: TrabalhoModeloTres,
    template: templateTrabalhoTres,
    uploadPath: 'trabalhos/upload_modelo_tres'
  },
  'blog-modelo-um': {
    modelo: ModeloBlogUm,
    template: templateBlogUm,
    uploadPath: 'blog/do_upload_modelo_um'
  },
  'blog-modelo-dois': {
    modelo: ModeloBlogDois,
    template: templateServico,
    uploadPath: 'blog/do_upload_modelo_dois'
  }
}
const sanitizerConfig = {
  br: false,
}

class Bloco extends Component {
  constructor (props) {
    super(props)
    this.state = {
      id: this.props.levelBlocos,
      modelo: this.props.modelo,
      conteudo: this.props.blocos
        ? this.props.blocos.conteudo
        : templates[this.props.modelo].template
    }
  }

  handleChangeOnEditor (newData) {
    this.editorInstance.sanitizer.clean(newData, sanitizerConfig)
    const data = {
      id: this.state.id,
      modelo: this.state.modelo,
      conteudo: newData.blocks
    }
    this.setState({ conteudo: data.conteudo })
    this.props.handleSave(data)
  }
  handleClick () {
    this.props.delete(this.state.id)
  }

  render () {
    return (
      <>
        <div className='bloco-action mb--20 right' style={{ display: 'flex' }}>
          <FaTrash
            style={{ marginLeft: 'auto', cursor: 'pointer' }}
            size={30}
            onClick={() => this.handleClick()}
          />
        </div>

        {/* Vizualização do bloco */}
        <Paper className='mb--25' style={{ padding: '15px' }} elevation={3}>
          <div
            style={{ display: 'flex' }}
            className='justify-content-around mb--65 '
          >
            <label>{`Pré-visualização`}</label>
          </div>
          {React.createElement(templates[this.props.modelo].modelo, {
            bloco: this.state.conteudo
          })}
        </Paper>

        {/* Lógica do editor-js */}
        <>

          <div className='w-100' id={`editor-${this.state.id}`}></div>

          <EditorJs
            onChange={e => {
              e.saver.save().then(data => this.handleChangeOnEditor(data))
            }}
            holder={`editor-${this.state.id}`}
            autofocus={true}
            instanceRef={instance => (this.editorInstance = instance)}
            data={{ blocks: this.state.conteudo }}
            tools={{
              embed: Embed,
              quote: Quote,
              image: {
                class: Image,
                config: {
                  uploader: {
                    props: this.props,
                    uploadByFile (file) {
                      let fd = new FormData()
                      fd.append('bgImage', file)
                      let path = templates[this.props.modelo].uploadPath
                      return fetch(`https://artcenter.ind.br/api/${path}`, {
                        method: 'POST',
                        body: fd
                      })
                        .then(data => data.json())
                        .then(json => {
                          if (json.success === 0) {
                            this.props.showModal(json.proporcao)
                          }
                          return json
                        })
                    }
                  }
                }
              },
              header: Header,
              list: List
            }}
          />

        </>

      </>
    )
  }
}
export default Bloco
