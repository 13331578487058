import React from 'react'

import { readEditor } from '../readEditor'

export const ServicoModeloUm = data => {
  let { ...conteudo } = readEditor(data)
  let o = conteudo.textos.map(el => {
    if(el.texto.type.split('')[0] === 'h'){
      return  React.cloneElement(el.texto, {className: 'title'})
    }
    else{
      return el.texto
    }
  })

  return (
    <>
      <div className='row sercice-details-content mt--10 pb--80 align-items-center'>
        <div className='col-lg-6 col-12'>
          <div className='thumb'>
            {conteudo.media.map(m => (
              <img className='w-100' src={m.src} alt={m.caption} />
            ))}
          </div>
        </div>
        <div className='col-lg-6 col-12'>
          <div className='details mt_md--30 mt_sm--30'>
            {o.map(v => (
              <>{v}</>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export const ServicoModeloDois = data => {
  let { ...conteudo } = readEditor(data)
  let o = conteudo.textos.map(el => {
    if(el.texto.type.split('')[0] === 'h'){
      return  React.cloneElement(el.texto, {className: 'title'})
    }
    else{
      return el.texto
    }
  })

  return (

    <div className='row sercice-details-content align-items-center pb--80'>
      <div className='col-lg-6 col-12 order-2 order-lg-1'>
        <div className='details mt_md--30 mt_sm--30 '>
          {o.map(v => (
            <>{v}</>
          ))}
        </div>
      </div>
      <div className='col-lg-6 col-12 order-1 order-lg-2'>
        <div className='thumb position-relative'>
          {conteudo.media.map(m => (
            <img className='w-100' src={m.src} alt={m.caption} />
          ))}
        </div>
      </div>
    </div>
  )
}
