import React, { Component } from 'react'
import EditableText from '../EditableText'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { MdInsertPhoto, MdSave } from 'react-icons/md'
import { FiHeadphones, FiMail, FiMapPin } from 'react-icons/fi'
import GoogleMapReact from 'google-map-react'
import { get, post, upload } from '../../api'
import Modal from 'react-bootstrap/Modal'
import Fab from '@material-ui/core/Fab';
const AnyReactComponent = ({ text }) => <div>{text}</div>

const templateContato = {
  breadcrumbTitulo: 'Breadcrumb',
  breadcrumbDescricao: 'descricao',
  breadcrumbImagem: 'breadcrumb-modelo.jpg',

  tituloTelefone: 'Telefone',
  tituloEmail: 'Emails',
  tituloLocalizacao: 'Localização',

  telefone1: '54 3268 1380',
  telefone2: '54 99971 2341',

  email1: 'comercial@artcenter.ind.br',
  endereco:
    'R. Cenira E. Cambruzzi, 280 - Sala 01 B. Planalto | Farroupilha RS',
  formTitulo: 'Contato',
  formDescricao:
    'Occaecat occaecat reprehenderit do commodo dolore est adipisicing. Veniam fugiat commodo esse ad ea exercitation do proident elit labore laboris dolor sint. ',
  formImagem: 'form-modelo.jpg'
}

class AddContato extends Component {
  constructor (props) {
    super()
    this.state = {
      id: null,
      breadcrumbTitulo: '',
      breadcrumbDescricao: '',
      breadcrumbImagem: '',

      tituloTelefone: '',
      tituloEmail: '',
      tituloLocalizacao: '',

      telefone1: '',
      telefone2: '',

      email1: '',
      endereco:
        '',
      formTitulo: '',
      formDescricao:
        '',
      formImagem: '',

      modo_editar: false
    }
  }
  handleBreadcrumb (event) {
    const formData = new FormData()
    formData.append('usuarioImagem', event.target.files[0])
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      if (img.width === 1920 && img.height === 950) {
        upload('contato/upload_breadcrumb', formData)
          .then(data => {
            event.preventDefault()
            console.log(data)
            this.setState({ breadcrumbImagem: data.upload_data.file_name })
          })
          .catch(err => console.log(err))
        return true
      }
      this.setState({ modalUm: true })
      return false
    }
  }
  handleFormPic (event) {
    const formData = new FormData()
    formData.append('usuarioImagem', event.target.files[0])
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      if (img.width === 800 && img.height === 845) {
        upload('contato/upload_form_pic', formData)
          .then(data => {
            event.preventDefault()
            this.setState({ formImagem: data.upload_data.file_name })
          })
          .catch(err => console.log(err))
        return true
      }
      this.setState({ modalDois: true })
      return false
    }
  }
  salvar () {
    let data = { ...this.state }
    delete data.modo_editar
    if (this.state.modo_editar) {
      post('contato/editar', data).then(r => window.location.href = '/gdi')
    } else {
      post('contato/salvar', data).then(r => window.location.href = '/gdi')
    }
  }
  componentDidMount () {
    get('contato/index')
      .then(data =>{

        if(data.length > 0){
          this.setState(data[0])
          this.setState({modo_editar:true})
          this.setState({id:  data[0].id})
        }else{
          this.setState(templateContato)
        }
      })

  }

  render () {
    return (
      <>
        <>
          <Modal
            size='lg'
            show={this.state.modalUm}
            onHide={() => this.setState({ modalUm: false })}
            aria-labelledby='example-modal-sizes-title-sm'
          >
            <Modal.Header closeButton>
              <Modal.Title id='example-modal-sizes-title-sm'>
                Dimensões da imagem
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              As dimensões da imagem estão incorretas. As dimensões corretas são
              1920x950
            </Modal.Body>
          </Modal>
        </>
        <>
          <Modal
            size='lg'
            show={this.state.modalDois}
            onHide={() => this.setState({ modalDois: false })}
            aria-labelledby='example-modal-sizes-title-sm'
          >
            <Modal.Header closeButton>
              <Modal.Title id='example-modal-sizes-title-sm'>
                Dimensões da imagem
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              As dimensões da imagem estão incorretas. As dimensões corretas são
              800x845
            </Modal.Body>
          </Modal>
        </>

        <div className='container'>
          <div className='row mb--50'>
            <div className='col-lg-12'>
              <h2>Pagina de contato</h2>
              <h4>Personalização inline</h4>
            </div>
          </div>

          <div className='row mb--50'>
            <h4
              style={{
                width: '100%',
                textAlign: 'center',
                borderBottom: '1px solid #212121',
                lineHeight: '0.1em',
                margin: '10px 0 20px'
              }}
            >
              <span style={{ backgroundColor: '#fff', padding: '0 10px' }}>
                Edição Inline
              </span>
            </h4>
          </div>
          {/* Breadcrumb */}
          <div className='row '>
            <div
              className='w-100 rn-page-title-area pt--120 pb--190 bg_image'
              style={{
                backgroundImage: `url("/static/images/contatos/breadcrumb/${this.state.breadcrumbImagem}")`
              }}
              data-black-overlay='6'
            >
              <div className='col-lg-12'>
                <div className='rn-page-title text-center pt--100 '>
                  <h2 className='title theme-gradient'>
                    {this.state.breadcrumbTitulo}
                  </h2>
                  {this.state.breadcrumbTitulo ? (
                    <EditableText
                      value={this.state.breadcrumbTitulo}
                      onChange={e => this.setState({ breadcrumbTitulo: e })}
                    />
                  ) : (
                    ''
                  )}
                  {this.state.breadcrumbDescricao ? (
                    <p>
                      <EditableText value={this.state.breadcrumbDescricao} onChange={e => this.setState({ breadcrumbDescricao: e })}/>
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center justify-content-center w-100'>
              <input
                accept='image/*'
                id='imageDois'
                type='file'
                style={{ display: 'none' }}
                onChange={e => this.handleBreadcrumb(e)}
              />
              <label style={{ cursor: 'pointer' }} htmlFor='imageDois'>
                <IconButton
                  color='primary'
                  aria-label='upload picture'
                  component='span'
                >
                  <MdInsertPhoto size={40} />
                </IconButton>
                Adicionar imagem (1920x950)
              </label>
            </div>
          </div>
          {/* Fim Breadcrumb */}
          {/* Start Contact Top Area  */}
          <div className='rn-contact-top-area ptb--120 bg_color--5'>
            <div className='container'>
              <div className='row'>
                {/* Start Single Address  */}
                <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                  <div className='rn-address'>
                    <div className='icon'>
                      <FiHeadphones />
                    </div>
                    <div className='inner'>
                      <h4 className='title'>
                        {this.state.tituloTelefone ? (
                          <EditableText value={this.state.tituloTelefone} onChange={e => this.setState({ tituloTelefone: e })} />
                        ) : (
                          ''
                        )}
                      </h4>
                      <p>
                        <a>
                          {this.state.telefone1 ? (
                            <EditableText value={this.state.telefone1}
                              onChange={e => this.setState({ telefone1: e })}
                             />
                          ) : (
                            ''
                          )}
                        </a>
                      </p>
                      <p>
                        <a>
                          {this.state.telefone2 ? (
                            <EditableText value={this.state.telefone2}
                              onChange={e => this.setState({ telefone2: e })}
                             />
                          ) : (
                            ''
                          )}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}

                {/* Start Single Address  */}
                <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50'>
                  <div className='rn-address'>
                    <div className='icon'>
                      <FiMail />
                    </div>
                    <div className='inner'>
                      <h4 className='title'>
                        {this.state.tituloEmail ? (
                          <EditableText value={this.state.tituloEmail}
                              onChange={e => this.setState({ tituloEmail: e })}

                           />
                        ) : (
                          ''
                        )}
                      </h4>
                      <p>
                        <a>
                          {this.state.email1 ? (
                            <EditableText value={this.state.email1}
                              onChange={e => this.setState({ email1: e })}
                            />
                          ) : (
                            ''
                          )}
                        </a>
                        <a>
                          {this.state.email2 ? (
                            <EditableText value={this.state.email2}
                              onChange={e => this.setState({ email2: e })}
                            />
                          ) : (
                            ''
                          )}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}

                {/* Start Single Address  */}
                <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50'>
                  <div className='rn-address'>
                    <div className='icon'>
                      <FiMapPin />
                    </div>
                    <div className='inner'>
                      <h4 className='title'>
                        {this.state.tituloLocalizacao ? (
                          <EditableText value={this.state.tituloLocalizacao}
                              onChange={e => this.setState({ tituloLocalizacao: e })}

                           />
                        ) : (
                          ''
                        )}
                      </h4>
                      <p>
                        {this.state.endereco ?
                          <EditableText value={this.state.endereco  }
                          onChange={e => this.setState({ endereco: e })}
                         />
                         : ''
                        }

                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}
              </div>
            </div>
          </div>
          {/* End Contact Top Area  */}
          <div className='rn-contact-page ptb--120 bg_color--1'>
            <div className='contact-form--1'>
              <div className='container'>
                <div className='row row--35 align-items-start'>
                  <div className='col-lg-6 order-2 order-lg-1'>
                    <div className='section-title text-left mb--50'>
                      <h2 className='title'>
                        {this.state.formTitulo ? (
                          <EditableText value={this.state.formTitulo}
                              onChange={e => this.setState({ formTitulo: e })}

                           />
                        ) : (
                          ''
                        )}
                      </h2>
                      <p className='description'>
                        {this.state.formDescricao ? (
                          <EditableText value={this.state.formDescricao}
                              onChange={e => this.setState({ formDescricao: e })}

                           />
                        ) : (
                          ''
                        )}
                        .
                      </p>
                    </div>
                    <div className='form-wrapper'>
                      <label htmlFor='item01'>
                        <input
                          type='text'
                          name='Nome'
                          id='item01'
                          value={this.state.rnName}
                          onChange={e => {
                            this.setState({ rnName: e.target.value })
                          }}
                          placeholder='Nome *'
                        />
                      </label>

                      <label htmlFor='item02'>
                        <input
                          type='text'
                          name='Email'
                          id='item02'
                          value={this.state.rnEmail}
                          onChange={e => {
                            this.setState({ rnEmail: e.target.value })
                          }}
                          placeholder='Email *'
                        />
                      </label>

                      <label htmlFor='item03'>
                        <input
                          type='text'
                          name='subject'
                          id='item03'
                          value={this.state.rnSubject}
                          onChange={e => {
                            this.setState({ rnSubject: e.target.value })
                          }}
                          placeholder='Assunto'
                        />
                      </label>
                      <label htmlFor='item04'>
                        <textarea
                          type='text'
                          id='item04'
                          name='message'
                          value={this.state.rnMessage}
                          onChange={e => {
                            this.setState({ rnMessage: e.target.value })
                          }}
                          placeholder='Mensagem'
                        />
                      </label>
                      <button className='rn-button-style--2 btn-solid'>
                        Enviar
                      </button>
                    </div>
                  </div>
                  <div className='col-lg-6 order-1 order-lg-2'>
                    <div className='thumbnail mb_md--30 mb_sm--30'>
                      <img
                        src={`/static/images/contatos/form/${this.state.formImagem}`}
                        alt='trydo'
                      />
                    </div>
                    <div className='d-flex align-items-center justify-content-center w-100'>
                      <input
                        accept='image/*'
                        id='formPic'
                        type='file'
                        style={{ display: 'none' }}
                        onChange={e => this.handleFormPic(e)}
                      />
                      <label style={{ cursor: 'pointer' }} htmlFor='formPic'>
                        <IconButton
                          color='primary'
                          aria-label='upload picture'
                          component='span'
                        >
                          <MdInsertPhoto size={40} />
                        </IconButton>
                        Adicionar imagem (800x845)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <h4
              style={{
                width: '100%',
                textAlign: 'center',
                borderBottom: '1px solid #212121',
                lineHeight: '0.1em',
                margin: '10px 0 20px'
              }}
            >
              <span style={{ backgroundColor: '#fff', padding: '0 10px' }}>
                Fim Edição Inline
              </span>
            </h4>
          </div>
          <div className='row mt--50'>
            <div className='col-lg-12'>
              <div className='ml-auto d-flex justify-content-end'>
                <Fab
              onClick={() => this.salvar()}
              color='primary'
              style={{position: 'fixed'}}
            >
              <MdSave size={30} />
            </Fab>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default AddContato
