import React, { Component } from 'react'
import { slideSlick } from '../page-demo/script'
import BannerIcones from './banners/BannerIcones'
import Banner from './banners/Banner'

import Slider from 'react-slick'
class Slide extends Component {
  render () {
    return (
      <div className='slider-wrapper' id='home'>
        <div className='slider-activation'>
          <Slider className='rn-slick-dot dot-light' {...slideSlick}>
            {this.props.SliderList.map(
              (value, index) => (
                <li key={index}>
                  <Banner
                    bg={value.bg}
                    titulo={value.titulo}
                    subtitulo={value.subtitulo}
                    tituloAlign={value.tituloAlign}
                    subtituloAlign={value.subtituloAlign}
                    link={value.linkBtn1}
                  />
                </li>
              )

              //  : (
              //   <BannerIcones
              //     key={index}
              //     bg={value.bg}
              //     titulo={value.titulo}
              //     descricao={value.descricao}
              //     btn1={value.btn1}
              //     btn1_icone={value.btn1_icone}
              //     btn2={value.btn2}
              //     btn2_icone={value.btn2_icone}
              //     linkBtn1={value.linkBtn1}
              //     linkBtn2={value.linkBtn2}
              //   />
              // )
            )}
          </Slider>
        </div>
      </div>
    )
  }
}
export default Slide
