import React, { Component } from "react";
import { FaFacebookF  , FaTwitter, FaInstagram } from "react-icons/fa";
import {get} from '../api';



class Team extends Component{
    constructor(props){
        super(props);
        this.state = {
            Equipe: []
        }
    }
    componentDidMount() {
        get('equipe/index').then(data =>{
            this.setState({Equipe: data});
        })
    }

    render(){
        const {column} = this.props;
        return(
            <React.Fragment>
                {this.state.Equipe.map((value , i ) => (
                    <div className={`${column}`} key={i}>
                        <div className="team">
                            <div className="thumbnail">
                                <img src={`/static/images/equipe/${value.imagem}`} alt="Blog Images"/>
                            </div>
                            <div className="content">
                                <h4 className="title">{value.nome}</h4>
                                <p className="designation">{value.cargo}</p>
                            </div>

                            <ul className="social-icon" >
                                {
                                    value.facebook ? (
                                    <li><a target="_blank" rel="noopener noreferrer" href={`https://fb.com/${value.facebook}`}><FaFacebookF/></a></li>) : null
                                }
                                {
                                    value.instagram ? (
                                    <li><a target="_blank" rel="noopener noreferrer" href={`https://instagram.com/${value.instagram}`}><FaInstagram/></a></li>) : null
                                }
                                {
                                    value.twitter ? (
                                    <li><a target="_blank" rel="noopener noreferrer" href={`https://twitter.com/${value.twitter}`}><FaTwitter/></a></li>) : null
                                }
                            </ul>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}
export default Team;