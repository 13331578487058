import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import EditableText from '../EditableText'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { MdInsertPhoto, MdSave } from 'react-icons/md'
import { get, post, upload } from '../../api'
import Modal from 'react-bootstrap/Modal'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab';
const modeloTestemunhos = {
  descricao:
    'Magna labore deserunt non adipisicing duis mollit ut dolor. Amet eiusmod id consectetur cillum amet ut velit dolor est irure.',
  autor: 'Autor Nome',
  empresa: 'empresa do autor',
  imagem: 'testimonial-1.jpg'
}

class AddTestemunhos extends Component {
  constructor (props) {
    super()
    this.state = {
      descricao: '',
      autor: '',
      empresa: '',
      imagem: '',

      modalUm: false,
      modo_editar: false
    }
  }
  handleChangeImage (event) {
    const formData = new FormData()
    formData.append('usuarioImagem', event.target.files[0])
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      if (img.width / img.height === 1) {
        upload('testemunhos/upload_image', formData)
          .then(data => {
            event.preventDefault()
            this.setState({ imagem: data.upload_data.file_name })
          })
          .catch(err => console.log(err))
        return true
      }
      this.setState({ modalUm: true })
      return false
    }
  }
  salvar () {
    let data = { ...this.state }
    delete data.modalUm
    delete data.modo_editar

    if (this.state.modo_editar) {
      post('testemunhos/editar', data).then(data => window.location.href = '/gdi/index/testemunhos')
    } else {
      post('testemunhos/salvar', data).then(data => window.location.href = '/gdi/index/testemunhos')
    }
  }
  componentDidMount () {
    let id_testemunho = this.props.match.params.id
    if (id_testemunho) {
      this.setState({ modo_editar: true })
      get(`testemunhos/index/${id_testemunho}`).then(data =>
        this.setState(data)
      )
    } else {
      this.setState(modeloTestemunhos)
    }
  }

  render () {
    return (
      <>
        <>
          <Modal
            size='lg'
            show={this.state.modalUm}
            onHide={() => this.setState({ modalUm: false })}
            aria-labelledby='example-modal-sizes-title-sm'
          >
            <Modal.Header closeButton>
              <Modal.Title id='example-modal-sizes-title-sm'>
                Dimensões da imagem
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              As dimensões da imagem estão incorretas. As dimensões de altura e
              largura devem ser iguais
            </Modal.Body>
          </Modal>
        </>
        <div className='container'>
          <div className='row mb--50'>
            <div className='col-lg-12'>
              <h3>Adicionar testemunho</h3>
              <p>Adicione o testemunho dos seus clientes e vizualize como irá aparecer na sua página inicial</p>
            </div>
          </div>
                        <Paper className='mb--25 ' style={{ padding: '30px 15px' }}>
          <div className='row mb--50'>
          <div className="col-lg-12">
            <h4 className="text-center">Começo Edição Inline</h4>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <Tabs>
                <TabPanel>
                  <div className='rn-testimonial-content text-center'>
                    <div className='inner'>
                      {this.state.descricao ? (
                        <p>
                          <EditableText value={this.state.descricao}
                            onChange={e => this.setState({descricao: e})}
                          />
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className='author-info'>
                      <h6>
                        <div className='d-inline-flex'>
                          {this.state.autor ? (
                            <span>
                              <EditableText value={this.state.autor}
                                onChange={e => this.setState({autor: e})}

                              />
                            </span>
                          ) : (
                            ''
                          )}
                          -
                          {this.state.empresa ? (
                            <span>
                              <EditableText value={this.state.empresa}
                                onChange={e => this.setState({empresa: e})}
                               />
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </h6>
                    </div>
                  </div>
                </TabPanel>
                <TabList
                  className='testimonial-thumb-wrapper'
                  htmlFor='imageDois'
                >
                  <Tab>
                    <div className='testimonial-thumbnai '>
                      <div className='thumb'>
                        <img
                          src={`/static/images/testemunhos/${this.state.imagem}`}
                          alt='Testimonial Images'
                        />
                        <div className='text-center'>
                          <input
                            accept='image/*'
                            id='imageDois'
                            type='file'
                            style={{ display: 'none' }}
                            onChange={e => this.handleChangeImage(e)}
                          />
                          <label
                            style={{ cursor: 'pointer' }}
                            htmlFor='imageDois'
                          >
                            <IconButton
                              color='primary'
                              aria-label='upload picture'
                              component='span'
                            >
                              <MdInsertPhoto size={40} />
                            </IconButton>
                            <small> Imagem Quadrada + </small>
                          </label>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </TabList>
              </Tabs>
            </div>
          </div>
          <div className='row mt--50'>
          <div className="col-lg-12">
            <h4 className="text-center">Fim Edição inline</h4>
            </div>
          </div>


        </Paper>
        <div className='row mt--50 mb--25'>
            <div className='col-lg-12'>
              <div className='ml-auto d-flex justify-content-end'>
                <Fab
              onClick={() => this.salvar()}
              color='primary'
              style={{position: 'fixed'}}
            >
              <MdSave size={30} />
            </Fab>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default AddTestemunhos
