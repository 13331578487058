import React, { Fragment } from 'react'
import { useAuth0 } from '../react-auth0-spa'
import Avatar from '@material-ui/core/Avatar'
const Profile = () => {
  const { loading, user } = useAuth0()
  if (loading || !user) {
    return <div>Loading...</div>
  }

  return (
    <Fragment>
      <div className='d-flex justify-content-around flex-column text-center'>
        <Avatar
          src={user.picture}
          alt='Imagem do usuário'
          style={{ width: '64px', height: '64px' , margin: '0 auto'}}
        />
        <span className="mt--10 font--12">{user.email}</span>
        <h4>{user.name}</h4>
      </div>
    </Fragment>
  )
}

export default Profile
