import React, { Component } from 'react'
import { NavLink as RouterNavLink } from 'react-router-dom'
import { Index } from '../elements/gdi/Index'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import {
  MdSettings,
  MdClose,
  MdMenu,
  MdViewCarousel,
  MdWork,
  MdGroup,
  MdFace,
  MdChromeReaderMode,
  MdBrush,
  MdPerson,
  MdLocalPhone,
  MdGraphicEq,
  MdAssignment
} from 'react-icons/md'
import { RiHandHeartLine } from 'react-icons/ri'
import { FaHandsHelping } from 'react-icons/fa'
import { IoIosAt, IoMdGitNetwork } from 'react-icons/io'
import { IconContext } from 'react-icons'
import { GiTatteredBanner } from 'react-icons/gi'

import error404 from '../elements/error404'
import AddBanner from '../elements/gdi/AddBanner'
import AddTrabalho from '../elements/gdi/AddTrabalho'
import AddServico from '../elements/gdi/AddServico'
import AddAbout from '../elements/gdi/AddAbout'
import AddEquipe from '../elements/gdi/AddEquipe'
import AddBlog from '../elements/gdi/AddBlog'
import AddContato from '../elements/gdi/AddContato'
import AddTestemunhos from '../elements/gdi/AddTestemunhos'
import AddParceiro from '../elements/gdi/AddParceiro'
import EmailList from '../elements/gdi/EmailList'
import AddSocial from '../elements/gdi/AddSocial'
import AddStatico from '../elements/gdi/AddStatico'
import Home from '../elements/gdi/Home'
import { useAuth0 } from '../react-auth0-spa'
import history from '../utils/history'

const HeaderBar = props => {
  const { user, logout } = useAuth0()
  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin
    })


  return (
    <div className='header'>
      <div className='icon'>
        {props.leftOpen ? (
          <IconContext.Provider value={{ color: 'white', size: '2em' }}>
            <MdClose onClick={() => props.toggle()} />
          </IconContext.Provider>
        ) : (
          <IconContext.Provider value={{ color: 'white', size: '2em' }}>
            <MdMenu onClick={() => props.toggle()} />
          </IconContext.Provider>
        )}
      </div>
      <div className='logo'>
        <RouterNavLink
          to='/'
          exact
          activeClassName='router-link-exact-active'
        ></RouterNavLink>
        <img src='/static/images/logo/taura-gdi.png' alt='Logo do GDI Taura' />
      </div>

      <div className='user-profile'>
        <UncontrolledDropdown style={{ listStyle: 'none' }} nav inNavbar>
          <DropdownToggle nav caret id='profileDropDown'>
            <img
              style={{ width: '2.5rem' }}
              src={user.picture}
              alt='Profile'
              className='nav-user-profile rounded-circle'
            />
          </DropdownToggle>
          <DropdownMenu style={{ left: '-164px' }} className='pr--50'>
            <DropdownItem header>{user.name}</DropdownItem>
            <DropdownItem
              tag={RouterNavLink}
              to='/profile'
              className='dropdown-profile'
              activeClassName='router-link-exact-active'
            ></DropdownItem>
            <DropdownItem id='qsLogoutBtn' onClick={() => logoutWithRedirect()}>
              Deslogar
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  )
}

class Gdi extends Component {
  constructor (props) {
    super(props)
    this.state = {
      leftOpen: true
    }
  }
  toggleSidebar = event => {
    let key = `leftOpen`
    this.setState({ leftOpen: !this.state.leftOpen })
  }

  render () {
    let leftOpen = this.state.leftOpen ? 'open' : 'closed'
    let resize = this.state.leftOpen ? 'resize' : null

    return (
      <div id='layout'>
        <Router history={history}>
          <div id='left' className={leftOpen}>
            <div className={`sidebar ${leftOpen}`}>
              <div className='conteudo_gdi'>
                <nav>
                  <label>Geral</label>
                  <ul>
                    <Link to='/gdi'>
                      <li>
                        <GiTatteredBanner size={20} />
                        Inicio
                      </li>
                    </Link>

                    <Link to='/gdi/email-list'>
                      <li>
                        <IoIosAt size={20} />
                        Emails
                      </li>
                    </Link>
                  </ul>
                  <label>Conteúdo</label>
                  <ul>
                    <Link to='/gdi/estatico/add'>
                      <li>
                        <MdGraphicEq size={20} />
                        Estático
                      </li>
                    </Link>
                    <Link to='/gdi/index/banners'>
                      <li>
                        <MdViewCarousel size={20} />
                        Banners
                      </li>
                    </Link>
                    <Link to='/gdi/index/servicos'>
                      <li>
                        <MdBrush size={20} />
                        Serviços
                      </li>
                    </Link>
                    <Link to='/gdi/about'>
                      <li>
                        <MdGroup size={20} />
                        Sobre Nós
                      </li>
                    </Link>
                    <Link to='/gdi/index/trabalhos'>
                      <li>
                        <MdWork size={20} />
                        Trabalhos
                      </li>
                    </Link>
                    <Link to='/gdi/index/equipe'>
                      <li>
                        <MdFace size={20} />
                        Equipe
                      </li>
                    </Link>
                    <Link to='/gdi/index/blog'>
                      <li>
                        <MdChromeReaderMode size={20} />
                        Blog
                      </li>
                    </Link>
                    <Link to='/gdi/index/testemunhos'>
                      <li>
                        <RiHandHeartLine size={20} />
                        Testemunhos
                      </li>
                    </Link>
                    <Link to='/gdi/contato/add'>
                      <li>
                        <MdLocalPhone size={20} />
                        Contato
                      </li>
                    </Link>
                    <Link to='/gdi/index/parceiros'>
                      <li>
                        <FaHandsHelping size={20} />
                        Parceiros
                      </li>
                    </Link>
                  </ul>
                  <label>Configurações</label>
                  <ul>
                    <Link to='/gdi/social-medias'>
                      <li>
                        <IoMdGitNetwork size={20} />
                        Redes Socias
                      </li>
                    </Link>
                    <Link to='/gdi/social-medias'>
                      <li>
                        <MdAssignment size={20} />
                        Gerenciamento
                      </li>
                    </Link>
                    <Link to='/gdi/usuarios'>
                      <li>
                        <MdPerson size={20} /> Usuarios
                      </li>
                    </Link>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <div id='main'>
            <HeaderBar
              leftOpen={this.state.leftOpen}
              toggle={this.toggleSidebar}
            />

            <div className={`conteudo_gdi ${resize}`}>
              <Switch>
                <Route exact path='/gdi/' component={Home}/>
                <Route exact path='/gdi/email-list' component={EmailList} />
                <Route exact path='/gdi/social-medias' component={AddSocial} />
                <Route exact path='/gdi/index/:id' component={Index} />
                {/* <Route exact path='/gdi/banners' component={IndexBanners}/> */}
                <Route
                  exact
                  path='/gdi/banners/add/:id'
                  component={AddBanner}
                />
                <Route exact path='/gdi/banners/add/' component={AddBanner} />

                {/* <Route exact path='/gdi/servicos' component={IndexServicos}/> */}
                <Route
                  exact
                  path='/gdi/servicos/add/:id'
                  component={AddServico}
                />
                <Route exact path='/gdi/servicos/add' component={AddServico} />

                <Route exact path='/gdi/about' component={AddAbout} />

                <Route exact path='/gdi/equipe/add/:id' component={AddEquipe} />
                <Route exact path='/gdi/equipe/add' component={AddEquipe} />

                <Route
                  exact
                  path='/gdi/testemunhos/add/:id'
                  component={AddTestemunhos}
                />
                <Route
                  exact
                  path='/gdi/testemunhos/add'
                  component={AddTestemunhos}
                />
                <Route
                  exact
                  path='/gdi/parceiros/add/:id'
                  component={AddParceiro}
                />
                <Route
                  exact
                  path='/gdi/parceiros/add'
                  component={AddParceiro}
                />

                <Route
                  exact
                  path='/gdi/contato/add/:id'
                  component={AddContato}
                />
                <Route exact path='/gdi/contato/add' component={AddContato} />

                <Route
                  exact
                  path='/gdi/trabalhos/add/:id'
                  component={AddTrabalho}
                />

                <Route
                  exact
                  path='/gdi/trabalhos/add'
                  component={AddTrabalho}
                />

                <Route exact path='/gdi/blog/add' component={AddBlog} />
                <Route exact path='/gdi/blog/add/:id' component={AddBlog} />
                <Route exact path='/gdi/estatico/add' component={AddStatico} />
                <Route exact path='' component={error404} />
              </Switch>
            </div>
          </div>
        </Router>
      </div>
    )
  }
}

export default Gdi
