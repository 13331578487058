import React, {Component} from 'react'
import Scrollspy from 'react-scrollspy'
import { FiChevronUp, FiX, FiMenu } from 'react-icons/fi'
import ScrollToTop from 'react-scroll-up'
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom'
import DigitalAgency from './home'
import About from '../elements/About'
import ServiceDetails from '../elements/ServiceDetails'
import Service from '../elements/Service'
import Footer from '../components/footer/Footer'
import FooterTeste from '../components/footer/FooterTeste'
import history from '../utils/history'
import BlogDetails from '../elements/BlogDetails'
import Blog from '../elements/Blog'
import Portfolio from '../elements/Portfolio'
import PortfolioDetails from '../elements/PortfolioDetails'
import Contact from '../elements/Contact'
import { get } from '../api'
import error404 from '../elements/error404'
import TagManager from 'react-gtm-module'
class IndexArtcenter extends Component {
  constructor(props){
    super();
    this.state = {Estatico: {}, redes:{}}
    this.menuTrigger = this.menuTrigger.bind(this)
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this)
    this.stickyHeader = this.stickyHeader.bind(this)
  }
  stickyHeader () {}
  menuTrigger () {
    document.querySelector('.header-wrapper').classList.toggle('menu-open')
  }
  CLoseMenuTrigger () {
    document.querySelector('.header-wrapper').classList.remove('menu-open')
  }
  componentDidMount() {
    get('estatico/index').then(data => {
      this.setState({Estatico: data[0]})
      get('gerenciamento/index').then(data => {
        this.setState({gtm: data.tagmanager})
      })
    })

   window.scrollTo(0, 0)
  }
  setCurrent = (id) => {
    let prevIsCurrent = document.querySelectorAll('.is-current')

    if(prevIsCurrent) {
      prevIsCurrent.forEach(e => e.classList.remove('is-current'))
    }
    let navItem = document.querySelector(id)
    navItem.classList.add("is-current")

  }

  render(){

    return (
      <>
      {
        this.state.gtm
        ? TagManager.initialize({
          gtmId: this.state.gtm
        })
        : null
      }
      <BrowserRouter history={history}>

       <header className='header-area formobile-menu header--fixed default-color'>
          <div className='header-wrapper' id='header-wrapper'>
            <div className='header-left'>
              <div className='logo'>
                <Link to='/home'>
                  <img
                    className='logo-1'
                    src={`/static/images/logo/${this.state.Estatico.logo}`}
                    alt='Logo Images'
                  />
                  <img
                    className='logo-2'
                    src={`/static/images/logo/${this.state.Estatico.logo}`}
                    alt='Logo Images'
                  />
                </Link>
              </div>
            </div>
            <div className='header-right'>
              <nav className='mainmenunav d-lg-block'>
                <ul
                  className='mainmenu'
                >
                  <li id="home">
                    <Link  to='/home'>Home</Link>
                  </li>
                  <li id="about">
                    <Link  to='/sobre'>Sobre</Link>
                  </li>
                  <li id="servicos">
                    <Link to='/servicos/'>Serviços</Link>
                  </li>
                  <li id="trabalhos">
                    <Link to='/trabalhos'>Trabalhos</Link>
                  </li>
                  <li id="blog">
                    <Link to='/blog'>Blog</Link>
                  </li>
                  <li id="contato">
                    <Link to='/contato'>Contato</Link>
                  </li>
                </ul>
              </nav>
              {/* Start Humberger Menu  */}
              <div className='humberger-menu d-block d-lg-none pl--20'>
                <span
                  onClick={this.menuTrigger}
                  className='menutrigger text-white'
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className='close-menu d-block d-lg-none'>
                <span onClick={this.CLoseMenuTrigger} className='closeTrigger'>
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>
          <Switch>
            <Route path='/home' exact component={() => <DigitalAgency setCurrent={this.setCurrent} Estatico={this.state.Estatico}/>}/>
            <Route path='/sobre' exact component={() => <About setCurrent={this.setCurrent} Estatico={this.state.Estatico}/>} />
            <Route path='/servicos/:titulo' exact component={ServiceDetails}/>
            <Route path='/servicos/' exact component={() => <Service setCurrent={this.setCurrent} Estatico={this.state.Estatico}/>}/>

            <Route path='/blog/:titulo'  exact component={BlogDetails}/>
            <Route path='/blog/' exact component={() => <Blog setCurrent={this.setCurrent} Estatico={this.state.Estatico}/>}/>

            <Route path='/trabalhos/:titulo' exact component={PortfolioDetails}/>
            <Route path='/trabalhos' exact render={() => <Portfolio estatico={this.state.Estatico} setCurrent={this.setCurrent}/>}/>

            <Route path='/contato/' exact component={() => <Contact setCurrent={this.setCurrent}/>}/>
            <Route exact path='' component={error404} />
          </Switch>
          <div className='backto-top'>
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
          {/* <Footer Estatico={this.state.Estatico}/> */}
          <FooterTeste Estatico={this.state.Estatico}/>
        </BrowserRouter>

        </>
    )
  }
}

export default IndexArtcenter