import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { upload } from '../../api'
import { MdInsertPhoto, MdEdit, MdDelete } from 'react-icons/md'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Modal from 'react-bootstrap/Modal'

export default class DropzoneComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      url: this.props.url,
      path: this.props.path,
      imagens: this.props.galeria || [],
      imagemSelecionada: {},
      modal: false
    }
  }
  updateImages = () => {
    this.props.setImage([...this.state.imagens])
  }
  onValueChange = e => {
    let property = e.target.name
    let value = e.target.value
    const copia = { ...this.state.imagemSelecionada, [property]: value }
    this.setState({ imagemSelecionada: copia })
  }
  salvarDialog = index => {
    const copia = { ...this.state.imagemSelecionada }

    this.setState(
      prevState => ({
        imagens: prevState.imagens.map(el =>
          prevState.imagens.indexOf(el) === index ? copia : el
        ),
        open: false,
        imagemSelecionada: {}
      }),
      () => {
        this.updateImages()
      }
    )
  }

  doUpload = files => {
    files.forEach(file => {
      const formData = new FormData()
      formData.append('userfile', file)
      let img = new Image()
      img.onload = () => {
        if (
          img.width === this.props.width &&
          img.height === this.props.height
        ) {
          upload(this.props.url, formData)
            .then(data => {
              this.setState({
                imagens: this.state.imagens.concat({
                  nome: data.upload_data.file_name,
                  titulo: '',
                  descricao: '',
                  alt: data.upload_data.raw_name
                })
              })
              this.props.setImage(this.state.imagens)
            })
            .catch(err => console.log(err))
        }else{this.setState({modal:true})}
      }
      img.src = window.URL.createObjectURL(file)
    })
  }
  deletar = nomeDaImagem => {
    this.setState(
      {
        imagens: [...this.state.imagens].filter(
          imagem => imagem.nome !== nomeDaImagem
        )
      },
      this.updateImages
    )
  }
  deletarTodas = () => {
    this.setState({ imagens: [] }, this.updateImages)
  }

  handleClick = imagem =>
    this.setState({
      imagemSelecionada: imagem,
      open: true,
      imagemSelecionadaTemp: imagem
    })
  handleClose = () => this.setState({ open: false, imagemSelecionada: {} })

  render () {
    return (
      <div className='mt--15 mb--15 dropzone'>
        <Modal
          className='mt--45 '
          size='lg'
          show={this.state.modal}
          onHide={() => this.setState({ modal: false })}
          aria-labelledby='example-modal-sizes-title-sm'
        >
          <Modal.Header closeButton>
            <Modal.Title id='example-modal-sizes-title-sm'>
              Dimensões da imagem
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            As dimensões da imagem estão incorretas. As dimensões devem ser {this.props.width}x{this.props.height}.
          </Modal.Body>
        </Modal>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Editar Imagem</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Edite a imagem adicionando um titulo e uma descrição para o
              usuário, e preencha o campo alt para o buscador do google
              identificar a imagem.
            </DialogContentText>

            <>
              <TextField
                onChange={e => this.onValueChange(e)}
                defaultValue={this.state.imagemSelecionada.titulo}
                fullWidth
                variant='outlined'
                autoFocus
                name='titulo'
                label='Titulo'
                type='name'
                className='mt--15'
              />
              <TextField
                onChange={e => this.onValueChange(e)}
                defaultValue={this.state.imagemSelecionada.descricao}
                className='mt--15'
                fullWidth
                variant='outlined'
                autoFocus
                name='descricao'
                label='Descrição'
                type='name'
              />
              <TextField
                defaultValue={this.state.imagemSelecionada.alt}
                onChange={e => this.onValueChange(e)}
                className='mt--15'
                fullWidth
                variant='outlined'
                autoFocus
                name='alt'
                label='Alt'
                type='name'
              />
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Cancelar
            </Button>
            <Button
              onClick={() =>
                this.salvarDialog(
                  this.state.imagens.indexOf(this.state.imagemSelecionadaTemp)
                )
              }
              color='primary'
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
        <Paper className='p-5'>
          <div className='row align-items-center'>
            <div className='col-lg-9'>
              <h3>Dropzone</h3>
              <p>Adicione um titulo, descrição e alt tag.</p>
            </div>
            <div className='col-lg-3'>
              <Button
                variant='contained'
                style={{ backgroundColor: '#f44336', color: '#fff' }}
                size='large'
                startIcon={<MdDelete />}
                onClick={this.deletarTodas}
              >
                Apagar Todas
              </Button>
            </div>
          </div>

          <div className='editar-imagens mt--15'>
            {this.state.imagens.map(imagem => (
              <div className='imagem'>
                <div className='layer'>
                  <div className='icones'>
                    <MdEdit onClick={() => this.handleClick(imagem)} />
                    <MdDelete onClick={() => this.deletar(imagem.nome)} />
                  </div>
                </div>
                <img
                  src={`${this.props.path}/${imagem.nome}`}
                  alt='Descrição da imagem'
                />
              </div>
            ))}
          </div>
          <Paper
            elevation={10}
            className='mt--35 p-3'
            style={{
              cursor: 'pointer',
              border: '1px dashed #000'
            }}
          >
            <Dropzone onDrop={files => this.doUpload(files)}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input id='dropzone' {...getInputProps()} />
                    <div className='text-center '>
                      <label style={{ cursor: 'pointer' }} htmlFor='dropzone'>
                        <IconButton
                          color='primary'
                          aria-label='upload picture'
                          component='span'
                        >
                          <MdInsertPhoto size={40} />
                        </IconButton>
                        <br />
                        <p className='font--12' style={{ fontWeight: '200' }}>
                          Dropzone para galeria, arraste as imagens aqui.
                          <br />
                          Resolução: {this.props.width}x{this.props.height}
                        </p>
                      </label>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </Paper>
        </Paper>
      </div>
    )
  }
}
