import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import { MdSave, MdInsertPhoto } from 'react-icons/md'
import IconButton from '@material-ui/core/IconButton'
import Modal from 'react-bootstrap/Modal'

import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab'
import Paper from '@material-ui/core/Paper'
import { get, post, upload } from '../../api'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import { ReactSVG } from 'react-svg'
class Gerenciamento extends Component {
  constructor () {
    super()
    this.state = {
      id: 0,
      open: false
    }
  }

  handleClickOpen = (titulo, label, name, value) =>
    this.setState({
      tituloD: titulo,
      label: label,
      name: name,
      value: value,
      open: true
    })

  handleClose = () => this.setState({ open: false })

  handleChange = event =>
    this.setState({ [event.target.name]: event.target.value })
  salvar = () => {
    let data = { ...this.state }
    delete data.open

    if (data.label) delete data.label
    if (data.tituloD) delete data.tituloD
    if (data.name) delete data.name
    delete data.value

    post('gerenciamento/salvar', data).then(
      data => (window.location.href = '/gdi')
    )
  }
  componentDidMount () {
    get('gerenciamento/index').then(data => this.setState(data))
  }
  handleFavicon = e => {
    const formData = new FormData()
    formData.append('favicon', e.target.files[0])
    let img = new Image()
    img.src = window.URL.createObjectURL(e.target.files[0])
    img.onload = () => {
      if (img.width / img.height === 1) {
        upload('gerenciamento/upload_favicon', formData)
          .then(data => {
            e.preventDefault()
            this.setState({ favicon: data.upload_data.file_name })
          })
          .catch(err => console.log(err))
        return true
      }
      this.setState({ modalUm: true })
      return false
    }
  }

  render () {
    return (
      <>
        <Dialogo
          value={this.state.value}
          open={this.state.open}
          handleClose={this.handleClose}
          handleChange={this.handleChange}
          label={this.state.label}
          name={this.state.name}
          titulo={this.state.tituloD}
        />
        <Modal
          className='mt--45'
          size='lg'
          show={this.state.modalUm}
          onHide={() => this.setState({ modalUm: false })}
          aria-labelledby='example-modal-sizes-title-sm'
        >
          <Modal.Header closeButton>
            <Modal.Title id='example-modal-sizes-title-sm'>
              Dimensões da imagem
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            As dimensões da imagem estão incorretas. As dimensões devem ser
            16x16
          </Modal.Body>
        </Modal>

        <div className='container'>
          <Paper className='pt--60 pb--60 pl--60 pr--60 mb--25'>
            <div className='row'>
              <div className='col-lg-12'>
                <h3>Gerenciamento</h3>
                <p>
                  Aqui você pode adicionar informações importantes para o
                  gerenciamento do seu site, como por exemplo habilitar o google
                  analytics ou tag manager, também adicionar adicionar a titulo,
                  descrição e favicon para sua página.
                </p>
              </div>
            </div>
          </Paper>
          <Paper className='pt--60 pb--60 pl--60 pr--60 mb--25'>
            <div className='row'>
              <div className='col-lg-12'>
                <h3>Meta Tag's da Página Principal</h3>
                <p>
                  Adicione as metatags para melhor indexação da sua página no
                  google.
                </p>
                <div className='row mt--25 '>
                  <div className='col-lg-6 m-auto'>
                    <TextField
                      value={this.state.titulo ? this.state.titulo : ''}
                      label='Titulo'
                      name='titulo'
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      id='input-with-icon-adornment'
                    />
                  </div>
                </div>
                <div className='row mt--15 '>
                  <div className='col-lg-6 m-auto'>
                    <TextField
                      defaultValue={
                        this.state.descricao ? this.state.titulo : ''
                      }
                      label='Descrição'
                      name='descricao'
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      id='input-with-icon-adornment'
                    />
                  </div>
                </div>
                <div className='row mt--15 '>
                  <div className='col-lg-6 m-auto'>
                    {this.state.favicon ? (
                      <div className='m-auto' style={{ width: '32px' }}>
                        <img
                          src={`/static/images/gerenciamento/${this.state.favicon}`}
                          alt='fav'
                          style={{ width: '32px' }}
                        />
                      </div>
                    ) : null}
                    <div className='text-center '>
                      <input
                        accept='image/*'
                        id='imageDois'
                        type='file'
                        style={{ display: 'none' }}
                        onChange={e => this.handleFavicon(e)}
                      />
                      <label style={{ cursor: 'pointer' }} htmlFor='imageDois'>
                        <IconButton
                          color='primary'
                          aria-label='upload picture'
                          component='span'
                        >
                          <MdInsertPhoto size={40} />
                        </IconButton>
                        Adicionar Favicon (16x16)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
          <Paper className='pt--60 pb--60 pl--60 pr--60 mb--25'>
            <div className='row mt--25'>
              <div className='col-lg-12'>
                <h3>Habilitar Plugins</h3>
                <p>Adicionar as chaves necessárias</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-4'>
                <Button
                  variant='contained'
                  className='mt--15'
                  style={{
                    backgroundColor: '#e8eaf6',
                    padding: '1rem',
                    width: '90%'
                  }}
                  onClick={() =>
                    this.handleClickOpen(
                      'Adicionar Tag Manager',
                      'GTM-XXXX',
                      'tagmanager',
                      this.state.tagmanager
                    )
                  }
                  startIcon={
                    <ReactSVG
                      className='mr--10'
                      src='/static/images/svg/ic_tag_manager.svg'
                    />
                  }
                >
                  Adicionar Tag Manager
                </Button>
              </div>
              <div className='col-lg-4'>
                <Button
                  className='mt--15'
                  variant='contained'
                  style={{
                    backgroundColor: '#f0f4c3',
                    padding: '1rem',
                    width: '90%'
                  }}
                  onClick={() =>
                    this.handleClickOpen(
                      'Adicionar Analytics',
                      'UA-XXXXX-Y',
                      'analytics',
                      this.state.analytics
                    )
                  }
                  startIcon={
                    <ReactSVG
                      beforeInjection={svg => {
                        svg.setAttribute('style', 'width: 24px')
                        svg.setAttribute('style', 'height: 24px')
                      }}
                      className='mr--10'
                      src='/static/images/svg/google_analytics-icon.svg'
                    />
                  }
                >
                  Adicionar Analytics
                </Button>
              </div>
              <div className='col-lg-4'>
                <Button
                  className='mt--15'
                  variant='contained'
                  style={{
                    backgroundColor: '#c5cae9',
                    padding: '1rem',
                    width: '90%'
                  }}
                  titulo={'Ativar Analytics'}
                  label='id'
                  name=' facebook'
                  onClick={() =>
                    this.handleClickOpen(
                      'Adicionar Pixel',
                      'pixel-id',
                      'pixel',
                      this.state.pixel
                    )
                  }
                  startIcon={
                    <ReactSVG
                      beforeInjection={svg => {
                        svg.setAttribute('style', 'width: 24px')
                        svg.setAttribute('style', 'height: 24px')
                      }}
                      className='mr--10'
                      src='/static/images/svg/fb.svg'
                    />
                  }
                >
                  Adicionar Pixel
                </Button>
              </div>
              <div className='col-lg-4'>
                <Button
                  className='mt--15'
                  variant='contained'
                  style={{
                    backgroundColor: '#e0f2f1',
                    padding: '1rem',
                    width: '90%'
                  }}
                  onClick={() =>
                    this.handleClickOpen(
                      'Adicionar Google Maps',
                      'api-key',
                      'maps',
                      this.state.maps
                    )
                  }
                  startIcon={
                    <ReactSVG
                      beforeInjection={svg => {
                        svg.setAttribute('style', 'width: 24px')
                        svg.setAttribute('style', 'height: 24px')
                      }}
                      className='mr--10'
                      src='/static/images/svg/google-maps.svg'
                    />
                  }
                >
                  Adicionar Maps Key
                </Button>
              </div>
            </div>
          </Paper>
          <div className='row mt--25'>
            <div className='col-lg-12'>
              <div className='ml-auto d-flex justify-content-end'>
                <Fab
                  onClick={() => this.salvar()}
                  color='primary'
                  style={{ position: 'fixed' }}
                >
                  <MdSave size={30} />
                </Fab>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default Gerenciamento

const Dialogo = data => {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={data.open}
      onClose={data.handleClose}
    >
      <DialogTitle>{data.titulo}</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            defaultValue={data.value}
            label={data.label}
            fullWidth
            variant='outlined'
            id='input-with-icon-adornment'
            name={data.name}
            onChange={data.handleChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={data.handleClose} color='primary'>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
