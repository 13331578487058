import React, { Component } from 'react'
import { get } from '../../api'
import {Link} from 'react-router-dom'

class PortfolioList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      trabalhos: [],
      estatico: this.props.estatico
    }
  }
  componentDidMount () {
    get('trabalhos/index').then(data => {
      this.setState({ trabalhos: data })
    })
  }

  render () {
    const { column, styevariation } = this.props

    return (
      <React.Fragment>
        {this.state.trabalhos.map((value, index) => (
          <div className={`${column}`} key={index}>
            <div className={`portfolio ${styevariation}`}>
              <div className='thumbnail-inner'>
                <div
                  className={`thumbnail`}
                  style={{
                    backgroundImage:
                      `url("/static/images/trabalhos/card/${value.cardImagem}`,

                  }}
                ></div>
                <div
                  className={`bg-blr-image `}
                ></div>
              </div>
              <div className='content'>
                <div className='inner'>
                  <p>{value.categoria}</p>
                  <h4>
                    <Link to={`/trabalhos/${value.slug}`}>{value.titulo}</Link>
                  </h4>
                  <div className='portfolio-button'>
                    <Link className='rn-btn' to={`/trabalhos/${value.slug}`}>
                      Veja Mais
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    )
  }
}
export default PortfolioList
