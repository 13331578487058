const template = [
  {
    type: 'header',
    data: {
      level: 2,
      text: 'Proceess of metel'
    }
  },
  {
    type: 'header',
    data: {
      level: 5,
      text: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
    }
  },
  {
    type: 'paragraph',
    data: {
      text:
        'hidden in the middle of text. All the Lorem Ipsum generators tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet.'
    }
  },

]

export default template
