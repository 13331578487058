import React, { Component } from 'react'
import PageHelmet from '../components/common/Helmet'
import Breadcrumb from '../elements/common/Breadcrumb'
import LoadingScreen from 'react-loading-screen'
import ServiceList from './service/ServiceList'

class Service extends Component {
  constructor(){
    super()
    this.state = {loading:true}
  }
  componentDidMount() {
    this.props.setCurrent("#servicos")
    window.scrollTo(0, 0)
    setTimeout(() => {
      this.setState({loading:false})
    }, 100);
  }




  render () {
    return (
      <React.Fragment>
        <LoadingScreen
          loading={this.state.loading}
          bgColor='#f1f1f1'
          spinnerColor='#9ee5f8'
          textColor='#676767'
        >
        <PageHelmet pageTitle={this.props.Estatico.breadcrumb_servicos || ''} />
        <div  id='service'>
        {/* Start Breadcrump Area */}
        <Breadcrumb title={this.props.Estatico.breadcrumb_servicos} description={this.props.Estatico.descricao_breadcrumb_servicos} />
        {/* End Breadcrump Area */}

        {/* Start Service Area */}
        <div className='service-area ptb--120 bg_color--1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='section-title text-center mb--30'>
                  <h2>{this.props.Estatico.titulo_servicos}</h2>
                  <p>
                    {this.props.Estatico.descricao_servicos}
                  </p>
                </div>
              </div>
            </div>
            <div className='row service-one-wrapper'>
              <div className='col-lg-12'>
                <ServiceList
                  item='6'
                  column='col-lg-4 col-md-6 col-sm-6 col-12 text-left'
                />
              </div>
            </div>
          </div>
        </div>
        </div>
        {/* End Service Area */}
        </LoadingScreen>
      </React.Fragment>
    )
  }
}
export default Service
