import React, { Component } from "react";
import PageHelmet from "../components/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import PortfolioList from "../elements/portfolio/PortfolioList";
import LoadingScreen from 'react-loading-screen'


class Portfolio extends Component{
    constructor(){
        super()
        this.state={
            loading: true
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.setCurrent('#trabalhos')
        setTimeout(() => {
             this.setState({loading:false})
        }, 500);

    }

    render(){
        return(
            <React.Fragment>
                <LoadingScreen
          loading={this.state.loading}
          bgColor='#f1f1f1'
          spinnerColor='#9ee5f8'
          textColor='#676767'
        >
                <PageHelmet pageTitle='Trabalhos' description={this.props.estatico.descricao_trabalhos || 'descrição'} />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={this.props.estatico.breadcrumb_trabalhos}  description={this.props.estatico.descricao_breadcrumb_trabalhos || ''}  />
                {/* End Breadcrump Area */}


                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30">
                                        <h2 className="title">{this.props.estatico.titulo_trabalhos}</h2>
                                        <p>{this.props.estatico.descricao_trabalhos}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12"  />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}
                </LoadingScreen>
            </React.Fragment>
        )
    }
}
export default Portfolio;