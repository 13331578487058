const BASEURL = 'https://artcenter.ind.br/api/';

export async function get (url = '') {
  url =  BASEURL + url

  const response = await fetch(url)
  return response.json()
}
export async function post (url = '', data) {
  url = BASEURL + url
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(data)
  })
  return response.json()
}
export async function upload(url='', data){
  url = BASEURL + url
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    body: data
  })
  return response.json()
}