import React, { Component } from 'react'
import PageHelmet from '../components/common/Helmet'
import ModalVideo from 'react-modal-video'
import LoadingScreen from 'react-loading-screen'
import { get } from '../api'
import {
  TrabalhoModeloUm,
  TrabalhoModeloDois,
  TrabalhoModeloTres
} from '../elements/modelos/trabalho/ModeloTrabalho'

class PortfolioDetails extends Component {
  constructor () {
    super()
    this.state = {
      isOpen: false,
      outrosTrabalhos: [],
      loading: false,
      galeria: []
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal () {
    this.setState({ isOpen: true })
  }
  componentDidMount () {
    const slug = this.props.match.params.titulo
    Promise.all([get(`trabalhos/get/${slug}`), get(`trabalhos/index`)]).then(
      data => {
        this.setState({ galeria: JSON.parse(data[0].galeria) === null ? [] : JSON.parse(data[0].galeria)})
        delete data[0].galeria
        this.setState(data[0])
        this.setState({ outrosTrabalhos: data[1], loading: false })
      }
    )

    window.scrollTo(0, 0)
  }

  render () {
    return (
      <React.Fragment>
        <LoadingScreen
          loading={this.state.loading}
          bgColor='#f1f1f1'
          spinnerColor='#9ee5f8'
          textColor='#676767'
        >
          <PageHelmet pageTitle={this.state.titulo || ''} />
          {/* Start Breadcrump Area */}
          <div
            className='rn-page-title-area pt--120 pb--190 bg_image'
            data-black-overlay='7'
            style={{
              backgroundPosition: 'center',
              backgroundImage: `url("/static/images/trabalhos/breadcrumb/${this.state.breadcrumb}`
            }}
          >
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='rn-page-title text-center pt--100'>
                    <h2 className='title theme-gradient'>
                      {this.state.titulo}
                    </h2>
                    <p>{this.state.descricao} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrump Area */}

          {/* Start Portfolio Details */}
          <div className='rn-portfolio-details ptb--120 bg_color--1'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='portfolio-details'>
                    {/* <div className='inner'>
                    <h2>Trydo</h2>
                    <p className='subtitle'>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form.
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                      commod viverra maecenas accumsan lacus vel facilisis. ut
                      labore et dolore magna aliqua.{' '}
                    </p>

                    <div className='portfolio-view-list d-flex flex-wrap'>
                      <div className='port-view'>
                        <span>Branch</span>
                        <h4>Ability</h4>
                      </div>

                      <div className='port-view'>
                        <span>Project Types</span>
                        <h4>Website</h4>
                      </div>

                      <div className='port-view'>
                        <span>Program</span>
                        <h4>View Project</h4>
                      </div>
                    </div>

                    <div className='portfolio-share-link mt--20 pb--70 pb_sm--40'>
                      <ul className='social-share rn-lg-size d-flex justify-content-start liststyle mt--15'>
                        {SocialShare.map((val, i) => (
                          <li key={i}>
                            <a href={`${val.link}`}>{val.Social}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className='portfolio-thumb-inner'>
                    <div className='thumb position-relative mb--30'>
                      <img
                        src='/static/images/portfolio/portfolio-big-03.jpg'
                        alt='Portfolio Images'
                      />
                      <ModalVideo
                        channel='youtube'
                        isOpen={this.state.isOpen}
                        videoId='ZOoVOfieAF8'
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button
                        className='video-popup position-top-center'
                        onClick={this.openModal}
                      >
                        <span className='play-icon'></span>
                      </button>
                    </div>

                    <div className='thumb mb--30'>
                      <img
                        src='/static/images/portfolio/portfolio-big-02.jpg'
                        alt='Portfolio Images'
                      />
                    </div>

                    <div className='thumb'>
                      <img
                        src='/static/images/portfolio/portfolio-big-01.jpg'
                        alt='Portfolio Images'
                      />
                    </div>
                  </div> */}
                    {this.state.blocos
                      ? this.state.blocos.map(bloco => qualModelo(bloco))
                      : ''}
                    {this.state.galeria.map((imagem, index) => (
                      <div className='portfolio-thumb-inner'>
                        <div className='thumb position-relative mb--30'>
                          <img
                            key={index}
                            src={`/static/images/trabalhos/${imagem.nome}`}
                            alt={imagem.alt}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Portfolio Details */}

          {/* Start Related Work */}
          <div className='portfolio-related-work pb--120 bg_color--1'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='section-title text-center'>
                    <span className='theme-color font--18 fontWeight600'>
                      +
                    </span>
                    <h2>Veja outros projetos</h2>
                  </div>
                </div>
              </div>
              <div className='row mt--10'>
                {/* Start Single Portfolio */}
                {this.state.outrosTrabalhos
                  ? this.state.outrosTrabalhos
                      .slice(0, 2)
                      .map((value, index) => (
                        <div className='col-lg-6 col-md-6 col-12' key={index}>
                          <div className='related-work text-center mt--30'>
                            <div className='thumb'>
                              <a href={`/portfolio/${value.slug}`}>
                                <img
                                  src={`/static/images/trabalhos/breadcrumb/${value.breadcrumb}`}
                                  alt='Portfolio-images'
                                />
                              </a>
                            </div>
                            <div className='inner'>
                              <h4>
                                <a href={`/portfolio/${value.slug}`}>
                                  {value.titulo}
                                </a>
                              </h4>
                              <span className='category'>
                                {value.categoria}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                  : ''}
                {/* End Single Portfolio */}
              </div>
            </div>
          </div>
          {/* End Related Work */}
        </LoadingScreen>
      </React.Fragment>
    )
  }
}
export default PortfolioDetails

const qualModelo = bloco => {
  const conteudo = bloco.conteudo
  switch (bloco.modelo) {
    case 'trabalho-modelo-um':
      return React.createElement(TrabalhoModeloUm, {
        bloco: conteudo,
        readOnly: true
      })
    case 'trabalho-modelo-dois':
      return React.createElement(TrabalhoModeloDois, {
        bloco: conteudo,
        readOnly: true
      })
    case 'trabalho-modelo-tres':
      return React.createElement(TrabalhoModeloTres, {
        bloco: conteudo,
        readOnly: true
      })
    default:
      break
  }
}
