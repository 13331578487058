import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import {get} from '../../api'
class PageHelmet extends Component{
    constructor(props){
        super(props)
        this.state={}
    }
    componentDidMount() {
        get('gerenciamento/index').then(data => this.setState({ favicon: data.favicon}))
    }

    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || Artcenter </title>
                    <link rel="icon"  href={`/static/images/gerenciamento/${this.state.favicon}`}/>
                    <meta name="description" content={this.props.description} />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
