import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Gdi from './pages/gdi'
import GdiTeste from './pages/gdi_teste'
import Helmet from './components/common/Helmet.js'
import history from './utils/history'
import { useAuth0 } from './react-auth0-spa'
import PrivateRoute from "./components/PrivateRoute";
import LoadingScreen from 'react-loading-screen';
import IndexArtcenter from './pages/IndexArtcenter'
function App () {
  const { loading } = useAuth0();
  if (loading) {

    return<>
    <Helmet pageTitle="Carregando"/>
     <LoadingScreen
    loading={true}
    bgColor='#f1f1f1'
    spinnerColor='#9ee5f8'
    textColor='#676767'
    children={'...Carregando'}
  >

  </LoadingScreen>
    </>
  }
  return (
    <div>

    <BrowserRouter history={history}>
    {/* <NavBar /> */}
      <Switch>
        <PrivateRoute path='/gdi' component={GdiTeste} />
        <Route path='/' component={IndexArtcenter}/>

      </Switch>
    </BrowserRouter>
    </div>
  )
}
export default App
