import React from 'react'
import ModalVideo from 'react-modal-video'
import {useState, useEffect} from 'react'
import { readEditor } from '../readEditor'
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,FaYoutube
} from 'react-icons/fa'
import { get } from '../../../api'
const SocialShare = {
  facebook: <FaFacebookF />,
  linkedin: <FaLinkedinIn />,
  instagram: <FaInstagram />,
  twitter: <FaTwitter />,
  youtube: <FaYoutube/>
}

const qualMedia = media => {
  switch (media.type) {
    case 'image':
      return (
        <>
          <div className='thumb mb--30'>
            <img src={media.src} alt='Portfolio Images' />
          </div>
        </>
      )
    case 'embed':
      return (
        <>
          <div className='thumb position-relative mb--80'>
            <img
              src='/static/images/portfolio/portfolio-big-03.jpg'
              alt='Portfolio Images'
            />
            <ModalVideo
              channel='youtube'
              isOpen={false}
              videoId='ZOoVOfieAF8'
            />
            <button
              className='video-popup position-top-center'
            >
              <span className='play-icon'></span>
            </button>
          </div>
        </>
      )
    default:
      break
  }
}
export const TrabalhoModeloUm = data => {
  let { ...conteudo } = readEditor(data)
  let o = conteudo.textos.map(v => {
    if (v.texto.type === 'p') {
      return React.cloneElement(v.texto, { className: '' })
    } else {
      return v.texto
    }
  })
  return (
    <>
    <div className="inner">
      {o.map(v => (
        <>{v}</>
      ))}
    </div>
    </>
  )
}
export const TrabalhoModeloDois = data => {
  let { ...conteudo } = readEditor(data)
  let formatacao = []
  let titulos = []
  let span = []
  const [redesSocias, setRedes] = useState([])
  useEffect(() => {
    get('/redes/index').then(d => setRedes(d))
  }, [])

  let o = conteudo.textos.map(v => {
    if (v.texto.type === 'p') {
      return React.createElement('span', { children: v.texto.props.children })
    } else {
      return v.texto
    }
  })
  o.forEach(item => {
    if (item.type.split('')[0] === 'h') {
      titulos.push(item)
    } else {
      span.push(item)
    }
  })

  for (let i = 0; i < span.length; i++) {
    formatacao.push({ titulo: titulos[i], paragrafo: span[i] })
  }

  return (
    <>

      <div className='inner'>
        <div className='portfolio-view-list d-flex flex-wrap justify-content-between'>
          {formatacao.map(v => (
            <div className='port-view'>
              {v.paragrafo}
              {v.titulo}
            </div>
          ))}

        </div>
        <div className='portfolio-share-link mt--20 pb--70 pb_sm--40'>
            <ul className='social-share rn-lg-size d-flex justify-content-start liststyle mt--15'>
              {/* {SocialShare.map((val, i) => (
                <li key={i}>
                  <a href={`${val.link}`}>{val.Social}</a>
                </li>
              ))} */}
              <RedesSocias redes={redesSocias}/>
            </ul>
          </div>
      </div>
    </>
  )
}

export const TrabalhoModeloTres = data => {
  let { ...conteudo } = readEditor(data)
  return (
    <>
      <div className='portfolio-thumb-inner'>
        {conteudo.media.map(m => qualMedia(m))}
      </div>
    </>
  )
}
const RedesSocias = ({redes}) => {
  let components = []
  for (let p in redes) {
    if(p === 'id' || redes[p] === ''){

    }else{
        components.push({
      Social: SocialShare[p],
      link: redes[p]
    })
    }

  }
  return (
    <>
      {components.map((val, i) => (
        <li key={i}>
          <a href={`${val.link}`}>{val.Social}</a>
        </li>
      ))}
    </>
  )
}