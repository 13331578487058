import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import { MdSave } from 'react-icons/md'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaYoutube} from 'react-icons/fa'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Paper from '@material-ui/core/Paper'
import {get, post} from '../../api'
import Fab from '@material-ui/core/Fab';
class AddSocial extends Component {
  constructor () {
    super()
    this.state = {
      facebook: '',
      instagram: '',
      twitter: '',
      linkedin: '',
      youtube: '',
    }
  }
  componentDidMount() {
    get('/redes/index').then(data => this.setState(data))
  }
  salvar = data => post('/redes/salvar', data).then(() => window.location.href = '/gdi')

  handleChange = (value) =>  this.setState({[value.target.name]:value.target.value})

  render () {
    return (
      <>
        <div className='container'>

          <Paper className="pt--60 pb--60 pl--60 pr--60 mb--25">
          <div className='row'>
            <div className='col-lg-12'>
              <h3>Adicionar Redes Sociais</h3>
              <p>As redes sociais da empresa iram aparecer no footer e na area de contato</p>
            </div>
          </div>
            <div className='row mt--25 '>
              <div className='col-lg-6 m-auto'>
                <TextField
                  value={this.state.facebook}
                  label='Facebook'
                  fullWidth
                  variant='outlined'
                  id='input-with-icon-adornment'
                  name='facebook'
                  placeholder="Usuario Facebook"
                  onChange = {this.handleChange}
                  InputProps={{
                  startAdornment:
                    <InputAdornment position='start' className="mb--5">
                      <FaFacebookF size={20}/>
                    </InputAdornment>
                  }}
                />
              </div>
            </div>
            <div className='row mt--15 '>
              <div className='col-lg-6 m-auto'>
                <TextField
                  value={this.state.twitter}
                  label='Twitter'
                  name='twitter'
                  onChange = {this.handleChange}
                  fullWidth
                  variant='outlined'
                  id='input-with-icon-adornment'
                  placeholder="Usuario do Twitter"
                  InputProps={{
                  startAdornment:
                    <InputAdornment position='start' className="mb--5">
                      <FaTwitter size={20}/>
                    </InputAdornment>
                  }}
                />
              </div>
            </div>
            <div className='row mt--15 '>
              <div className='col-lg-6 m-auto'>
                <TextField
                  value={this.state.linkedin}
                  label='Linkedin'
                  fullWidth
                  name='linkedin'
                  onChange = {this.handleChange}
                  variant='outlined'
                  id='input-with-icon-adornment'
                  placeholder="Usuario do Linkedin"
                  InputProps={{
                  startAdornment:
                    <InputAdornment position='start' className="mb--5">
                      <FaLinkedinIn size={20}/>
                    </InputAdornment>
                  }}
                />
              </div>
            </div>
            <div className='row mt--15 '>
              <div className='col-lg-6 m-auto'>
                <TextField
                  value={this.state.instagram}
                  label='Instagram'
                  name='instagram'
                  onChange = {this.handleChange}
                  fullWidth
                  variant='outlined'
                  id='input-with-icon-adornment'
                  placeholder="Usuario Instagram"
                  InputProps={{
                  startAdornment:
                    <InputAdornment position='start' className="mb--5">
                      <FaInstagram size={20}/>
                    </InputAdornment>
                  }}
                />
              </div>
            </div>
            <div className='row mt--15 '>
              <div className='col-lg-6 m-auto'>
                <TextField
                  value={this.state.youtube}
                  label='YouTube'
                  name='youtube'
                  onChange = {this.handleChange}
                  fullWidth
                  variant='outlined'
                  id='input-with-icon-adornment'
                  placeholder="Link do Canal"
                  InputProps={{
                  startAdornment:
                    <InputAdornment position='start' className="mb--5">
                      <FaYoutube size={20}/>
                    </InputAdornment>
                  }}
                />
              </div>
            </div>
            <div className='row mt--25'>
          <div className='col-lg-12'>
            <div className='ml-auto d-flex justify-content-end'>
              <Fab
              onClick={() => this.salvar()}
              color='primary'
              style={{position: 'fixed'}}
            >
              <MdSave size={30} />
            </Fab>
            </div>
          </div>
        </div>

          </Paper>
        </div>
      </>
    )
  }
}
export default AddSocial
