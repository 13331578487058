import React, { Component, Fragment } from 'react'
import Slider from 'react-slick'

import Slide from '../elements/Slide'
import ServiceList from '../elements/service/ServiceList'
import { slickDot } from '../page-demo/script'

import BrandTwo from '../elements/BrandTwo'
import Helmet from '../components/common/Helmet'
import Team from '../elements/Team'

import { Link } from 'react-router-dom'
import { get } from '../api'
import LoadingScreen from 'react-loading-screen'

class DigitalAgency extends Component {
  constructor (props) {
    super(props)
    // this.getData = this.getData.bind(this)
    // this.postData = this.getData.bind(this)
    this.state = {
      SliderList: [],
      About: {},
      CardTrabalhos: [],
      IndexEquipe: {},
      Blog: [],
      Trabalhos: [],
      Estatico: this.props.Estatico,
      loading: true
    }
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener('load', function () {})
  }

  componentDidMount () {
    this.props.setCurrent('#home')
    Promise.all([
      get('banners/index'),
      get('about/index'),
      get('blog/index'),
      get('trabalhos/index')
    ]).then(data => {
      this.setState({
        SliderList: data[0].sort((a, b) => a.ordem - b.ordem),
        About: data[1],
        Blog: data[2],
        Trabalhos: data[3],
        loading: false
      })
    })
    // get('banners/index').then(data => this.setState({ SliderList: data }))
    // get('about/index').then(data => this.setState({ About: data }))
    // get('blog/index').then(data => this.setState({ Blog: data }))
    // get('trabalhos/index').then(data => {
    //   this.setState({ Trabalhos: data })
    // })
    window.scrollTo(0, 0)
  }
  menuTrigger () {
    document.querySelector('.header-wrapper').classList.toggle('menu-open')
  }
  CLoseMenuTrigger () {
    document.querySelector('.header-wrapper').classList.remove('menu-open')
  }
  stickyHeader () {}

  render () {
    return (
      <div id='rootEl'>
        <LoadingScreen
          loading={this.state.loading}
          bgColor='#f1f1f1'
          spinnerColor='#9ee5f8'
          textColor='#676767'
          logoSrc=''
        >
          <Helmet pageTitle='Home' />
          <div id='home'>
            {/* Start Slider Area   */}
            <Slide SliderList={this.state.SliderList} />
            {/* End Slider Area   */}

            {/* Start Service Area  */}
            <div
              id='service'
              className='service-area creative-service-wrapper ptb--120 bg_color--1'
            >
              <div className='container'>
                <div className='row creative-service'>
                  <div className='col-lg-12'>
                    <ServiceList
                      item='6'
                      column='col-lg-4 col-md-6 col-sm-6 col-12 text-left'
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* End Service Area  */}

            {/* Start About */}
            <div className='about-area ptb--120 bg_color--5' id='about'>
              <div className='about-wrapper'>
                <div className='container'>
                  <div className='row row--35 align-items-center'>
                    <div className='col-lg-5'>
                      <div className='thumbnail'>
                        <img
                          className='w-100'
                          src={`/static/images/about/01/${this.state.About.image1}`}
                          alt='Sobre nós imagem'
                        />
                      </div>
                    </div>
                    <div className='col-lg-7'>
                      <div className='about-inner inner'>
                        <div className='section-title'>
                          <h2 className='title'>{this.state.About.titulo1}</h2>
                          <p className='description'>
                            {this.state.About.paragrafo1}
                          </p>
                          <p>{this.state.About.paragrafo2}</p>
                          <p>{this.state.About.paragrafo3}</p>
                        </div>
                        {/* <div className='row mt--30'>
                        <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                          <div className='about-us-list'>
                            <h3 className='title'>
                              {this.state.About.titulo2}
                            </h3>
                            <p>{this.state.About.paragrafo2}</p>
                          </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                          <div className='about-us-list'>
                            <h3 className='title'>
                              {this.state.About.titulo3}
                            </h3>
                            <p>{this.state.About.paragrafo3}</p>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>

                  </div>
                  <div className='row justify-content-start'>
                      <div className='col-lg-12'>
                        <div className='view-more-btn mt--20 text-center'>
                          <Link
                            className='rn-button-style--2 btn-solid'
                            to='/sobre'
                          >
                            <span>Veja Mais</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            {/* End About */}

            {/* Start Portfolio Area */}
            <div
              className='portfolio-area pt--120 pb--140 bg_color--5'
              id='portfolio'
            >
              <div className='rn-slick-dot'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='section-title service-style--3 text-left mb--15 mb_sm--0'>
                        <h2 className='title'>
                          {this.state.Estatico.titulo_trabalhos}
                        </h2>
                        <p>{this.state.Estatico.descricao_trabalhos}</p>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='slick-space-gutter--15 slickdot--20'>
                        <Slider {...slickDot}>
                          {this.state.Trabalhos.map((value, index) => (
                            <div className='portfolio' key={index}>
                              <div className='thumbnail-inner'>
                                <div
                                  className={`thumbnail`}
                                  style={{
                                    backgroundImage: `url("/static/images/trabalhos/card/${value.cardImagem}`,
                                    backgroundPosition:
                                      'center center !important'
                                  }}
                                ></div>
                                <div className={`bg-blr-image`}></div>
                              </div>
                              <div className='content'>
                                <div className='inner'>
                                  <p>{value.categoria}</p>
                                  <h4>
                                    <Link to={`/trabalhos/${value.slug}`}>
                                      {value.titulo}
                                    </Link>
                                  </h4>
                                  <div className='portfolio-button'>
                                    <Link
                                      className='rn-btn'
                                      to={`/trabalhos/${value.slug}`}
                                    >
                                      {value.btn}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Portfolio Area */}
            {/* Start Equipe Area*/}

            <div className='rn-team-area ptb--120 bg_color--1' id='team'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='section-title service-style--3 text-left mb--25 mb_sm--0'>
                      <h2 className='title'>
                        {this.state.Estatico.titulo_equipe}
                      </h2>
                      <p>{this.state.Estatico.descricao_equipe}</p>
                    </div>
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <Team
                    column='col-lg-4 col-md-6 col-sm-6 col-12 mt--30'
                    teamStyle=''
                    item='4'
                  />
                </div>
              </div>
            </div>
            {/*End Equipe Area */}
            {/* Start Testimonial Area */}
            {/* <div
            className='rn-testimonial-area ptb--120 bg_color--5'
            id='testimonial'
          >
            <div className='container'>
              <Testimonial />
            </div>
          </div> */}
            {/* End Testimonial Area */}

            {/* Start Blog Area */}
            <div className='rn-blog-area pt--120 pb--80 bg_color--1' id='blog'>
              <div className='container'>
                <div className='row align-items-end'>
                  <div className='col-lg-12'>
                    <div className='section-title text-left service-style--3'>
                      <h2>{this.state.Estatico.titulo_blog}</h2>
                      <p>{this.state.Estatico.descricao_blog}</p>
                    </div>
                  </div>
                </div>
                <div className='row mt--60'>
                  {this.state.Blog.map((value, i) => (
                    <div className='col-lg-4 col-md-6 col-sm-6 col-12' key={i}>
                      <div className='blog blog-style--1 text-center'>
                        <div className='thumbnail'>
                          <Link to={`blog/${value.slug}`}>
                            <img
                              className='w-100'
                              src={`/static/images/blog/card/${value.cardImagem}`}
                              alt='Blog Images'
                            />
                          </Link>
                        </div>
                        <div className='content'>
                          <p className='blogtype'>{value.categoria}</p>
                          <h4 className='title'>
                            <Link to={`blog/${value.slug}`}>
                              {value.titulo}
                            </Link>
                          </h4>
                          <div className='blog-btn'>
                            <Link
                              className='rn-btn text-white'
                              to={`blog/${value.slug}`}
                            >
                              Saiba Mais
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='view-more-btn mt--20 text-center'>
                      <Link className='rn-button-style--2 btn-solid' to='/blog'>
                        <span>Veja Mais</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Blog Area */}
            {/* Start Contact Us */}
            {/* <div className='rn-contact-us ptb--120 bg_color--5' id='contact'>
            <Contact />
          </div> */}
            {/* End Contact Us */}
            {/* Start Brand Area */}
            <div className='rn-brand-area bg_color--1 ptb--120'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='section-title service-style--3 text-center mb--25 mb_sm--0 font--15'>
                      <h3 className='title'>
                        Clientes que confiam em nosso trabalho
                      </h3>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <BrandTwo />
                  </div>
                </div>
              </div>
            </div>
            {/* End Brand Area */}

            {/* Start Footer Style  */}
            {/* End Footer Style  */}
            {/* Start Back To Top */}

            {/* End Back To Top */}
          </div>
        </LoadingScreen>
      </div>
    )
  }
}

export default DigitalAgency
