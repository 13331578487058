import React, { Component } from 'react';
import Header from "../components/header/HeaderFour";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../components/footer/FooterTwo";

 class error404 extends Component {

    render() {
        return (
            <>

                {/* Start Page Error  */}
                <div className="error-page-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h1 className="title theme-gradient">404!</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Error  */}

            </>
        )
    }
}
export default error404;
