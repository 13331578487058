import React, { Component } from 'react'
import PageHelmet from '../components/common/Helmet'
import ModalVideo from 'react-modal-video'
import { FiClock, FiUser, FiMessageCircle, FiHeart } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import ScrollToTop from 'react-scroll-up'
import { FiChevronUp } from 'react-icons/fi'
import LoadingScreen from 'react-loading-screen'
import { get } from '../api'
import {
  ServicoModeloUm,
  ServicoModeloDois
} from '../elements/modelos/servico/ModeloServico'

import {
  ModeloBlogUm,
  ModeloBlogDois
} from '../elements/modelos/blog/ModeloBlog'

class BlogDetails extends Component {
  constructor () {
    super()
    this.state = {
      isOpen: false,
      loading: true
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal () {
    this.setState({ isOpen: true })
  }

  componentDidMount () {
    const slug_blog = this.props.match.params.titulo
    get(`blog/get/${slug_blog}`).then(data => {
      this.setState(data)
      this.setState({ date: new Date(this.state.date), loading:false })
    })
    window.scrollTo(0, 0)
  }

  render () {
    return (
      <React.Fragment>
      <LoadingScreen
          loading={this.state.loading}
          bgColor='#f1f1f1'
          spinnerColor='#9ee5f8'
          textColor='#676767'
        >
        <PageHelmet pageTitle='Blog Details' />

        {/* Start Breadcrump Area */}
        <div
          className='rn-page-title-area pt--120 pb--190 bg_image'
          style={{
            backgroundImage: `url("/static/images/blog/breadcrumb/${this.state.breadcrumb}")`
          }}
          data-black-overlay='7'
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='blog-single-page-title text-center pt--100'>
                  <h2 className='title theme-gradient'>{this.state.titulo}</h2>
                  <ul className='blog-meta d-flex justify-content-center align-items-center'>
                    {!this.state.date ? (
                      ` `
                    ) : (
                      <li>
                        {new Date(this.state.date).getMonth() < 9
                          ? `0${new Date(this.state.date).getMonth() + 1}`
                          : new Date(this.state.date).getMonth() + 1}
                        {`/${new Date(this.state.date).getFullYear()}`}
                      </li>
                    )}
                    <li> | </li>
                    <li>{this.state.autor}</li>
                    <li> | </li>
                    <li>{this.state.categoria}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className='rn-blog-details pt--110 pb--70 bg_color--1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner-wrapper'>
                  <div className='inner'>
                    {this.state.blocos
                      ? this.state.blocos.map(bloco => qualModelo(bloco))
                      : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start BLog Comment Form  */}
        <div className='blog-comment-form pb--120 bg_color--1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner'>
                  <h3 className='title mb--40 fontWeight500'>Deixe um comentário</h3>
                  <form action='/mensagens/enviar' method="post">
                    <div className='row'>
                      <div className='col-lg-6 col-md-12 col-12'>
                        <div className='rnform-group'>
                          <input type='text' placeholder='Nome' required/>
                        </div>
                        <div className='rnform-group'>
                          <input type='email' placeholder='Email' required/>
                        </div>
                        <div className='rnform-group'>
                          <input type='text' placeholder='Website' />
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-12 col-12'>
                        <div className='rnform-group'>
                          <textarea
                            type='text'
                            placeholder='Comente'
                          ></textarea>
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <div className='blog-btn'>
                          {/* <a className="rn-button-style--2 btn-solid" href="#"></a> */}
                          <button onClick={(e) => this.handleMsg(e) } type="submit" className="rn-button-style--2 btn-solid">
                            <span>Enviar Comentário</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End BLog Comment Form  */}

        {/* Start Back To Top */}
        </LoadingScreen>
      </React.Fragment>
    )
  }
}
export default BlogDetails

const qualModelo = bloco => {
  const conteudo = bloco.conteudo
  switch (bloco.modelo) {
    case 'blog-modelo-um':
      return React.createElement(ModeloBlogUm, {
        bloco: conteudo, readOnly: true
      })
    case 'blog-modelo-dois':
      return React.createElement(ModeloBlogDois, {
        bloco: conteudo, readOnly: true
      })
    default:
      break
  }
}
