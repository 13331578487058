import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { get, post } from '../../api'
import Paper from '@material-ui/core/Paper'
import { Link } from 'react-router-dom'
import LoadingScreen from 'react-loading-screen'
import parse from 'html-react-parser'
import auth from '../../auth.json'
export const Index = () => {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  const [conteudo, setConteudo] = useState([])
  let { id } = useParams()
  const [isLoading, setLoading] = useState(true)
  let param = id
  let addr = `${param}/index`

  const backgroundImage = value => {
    if (value.bg) {
      return {
        backgroundImage: `url("/static/images/${id}/${value.bg}")`
      }
    }
    if (value.cardImagem) {
      return {
        backgroundImage: `url("/static/images/${id}/card/${value.cardImagem}")`
      }
    }
    if (value.breadcrumb) {
      return {
        backgroundImage: `url("/static/images/${id}/breadcrumb/${value.breadcrumb}")`
      }
    }
    if (value.imagem) {
      return {
        backgroundImage: `url("/static/images/${id}/${value.imagem}")`
      }
    }
    if (value.logo) {
      return {
        backgroundImage: `url("/static/images/${id}/${value.logo}")`
      }
    }
    if (value.picture) {
      return {
        backgroundImage: `url("${value.picture}")`
      }
    }
  }
  const titulo = value => {
    if (value.titulo && value.modelo === 'imagem') {
      return JSON.parse(value.titulo).map(el => parse(el.data.text))
    }
    if (value.titulo) return value.titulo
    if (value.nome) return value.nome
    if (value.empresa) return value.empresa
    if (value.name) return value.name
  }
  const descricao = value => {
    if (value.descricao) return value.descricao
    if (value.cargo) return value.cargo
    if (value.categoria) return value.categoria
    if (value.email) return value.email
    if (value.subtitulo)
      return JSON.parse(value.subtitulo).map(el => parse(el.data.text))
  }
  const ordem = value => {
    if (value.ordem) return value.ordem
  }
  const emailVerificado = value => {
    if (value.email_verified) {
      return <span>Sim</span>
    }
    return <span>Não</span>
  }
  const getData = () => {
    if (addr === 'usuarios/index') {
      fetch('https://dev-z5qcv7az.auth0.com/oauth/token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body:
          '{"client_id":"4FYXVChXGGS09t61schVVYrvxiQ8ZG4b","client_secret":"QvIVwZYyiBF7-_PTqyV-mU-vV-FF9UraG_ebQ9T2i3238XSq_iykXJBYVHlUnuYK","audience":"https://dev-z5qcv7az.auth0.com/api/v2/","grant_type":"client_credentials"}'
      })
        .then(response =>  response.json())
        .then(data => {
          console.log(data)
          fetch('https://dev-z5qcv7az.auth0.com/api/v2/users', {
            method: 'get',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: `Bearer ${data.access_token}`
            }
          })
            .catch(err => console.log('error aqui', err))
            .then(response => response.json())
            .then(data => {
              setConteudo(data)
              setLoading(false)
              return
            })
            .catch(err => `Não foi possivel connectar pois ${err}`)
        })
    } else {
      get(addr).then(data => {
        setConteudo(data)
        setLoading(false)
      })
    }
  }
  const deletar = id => {
    if (addr === 'usuarios/index') {
      fetch(`https://dev-z5qcv7az.auth0.com/api/v2/users/${id}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
        method: 'DELETE'
      })
        .then(data => {
          let conteudoFiltrado = conteudo.filter(user => user.user_id !== id)
          setConteudo(conteudoFiltrado)
        })
        .catch(err => console.log(err))
      return
    }
    post(`${param}/delete`, { id: id })
      .then(data => {
        let conteudoFiltrado = conteudo.filter(c => c.id !== data.id)
        setConteudo(conteudoFiltrado)
        getData()
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addr])

  return (
    <LoadingScreen
      loading={isLoading}
      bgColor='#f1f1f1'
      spinnerColor='#9ee5f8'
      textColor='#676767'
    >
      <Paper className='pt--30 pb--30 pl--30 pr--30 mb--25'>
        <div className='container-fluid'>
          <div className='row'>
            <h3 className='text-capitalize mb--25'>{`${id}`}</h3>
          </div>

          <div className='grid' style={{ gap: '15px' }}>
            <div className=' indexGdi'>
              <Link to={`/gdi/${id}/add`}>
                <div className='thumbnail-inner'>
                  <div
                    className={`thumbnail`}
                    style={{
                      backgroundImage: '/static/images/index.jpg !important'
                    }}
                  ></div>
                  <div
                    className={`bg-blr-image`}
                    style={{ backgroundImage: '/static/images/index.jpg' }}
                  ></div>
                </div>

                <div className='conteudo'>
                  <div className='inner'>
                    <h4>
                      <Link to={`/gdi/${id}/add`}>Adicionar {id}</Link>
                    </h4>
                    <p>
                      Aqui você pode adicionar novos {id} que iram aparecer na
                      sua página inicial
                    </p>
                    <div className='index-button d-flex justify-content-end'>
                      {id === 'banners' ? (
                        <BannersBtnCriar />
                      ) : (
                        <Link to={`/gdi/${id}/add`}>
                          <button className='add-btn'>Criar</button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            {conteudo.map((value, i) => (
              <div className='indexGdi' key={i}>
                <div className='thumbnail-inner' style={backgroundImage(value)}>
                  <div
                    style={backgroundImage(value)}
                    className={`thumbnail`}
                  ></div>
                  <div className={`bg-blr-image`}></div>
                  {value.email ? (
                    <span
                      style={{
                        color: '#fff',
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                        zIndex: 'inherit'
                      }}
                    >
                      <small>Email Verificado: {emailVerificado(value)}</small>
                    </span>
                  ) : (
                    ''
                  )}
                  {value.ordem ? (
                    <span
                      style={{
                        color: '#fff',
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                        zIndex: 'inherit'
                      }}
                    >
                      <small>Ordem {ordem(value)} </small>
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className='conteudo'>
                  <div className='inner'>
                    <h4>
                      <Link to="#">{titulo(value)}</Link>
                    </h4>
                    <p>{descricao(value)}</p>

                    <div className='index-button d-flex justify-content-end'>
                      {id === 'banners' ? (
                        <BannerBtnEditar id={value.id} modelo={value.modelo} />
                      ) : (
                        <Link
                          to={`/gdi/${id}/add/${value.id || value.user_id}`}
                        >
                          <button className='editar-btn'>Editar</button>
                        </Link>
                      )}

                      <button
                        type='submit'
                        className='rn-btn deletar'
                        href='/portfolio-details'
                        onClick={() => deletar(value.id || value.user_id)}
                      >
                        Deletar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Paper>
    </LoadingScreen>
  )
}

const BannersBtnCriar = () => {
  return (
    <>
      <Link to={`/gdi/banners-image/add/`}>
        <button className='add-btn mr--5'>Imagem</button>
      </Link>
      {/* <Link to={`/gdi/banners/add/`}>
        <button className='add-btn'>Icones</button>
      </Link> */}
    </>
  )
}

const BannerBtnEditar = ({ id, modelo }) => {
  if (modelo === 'icone') {
    return (
      <Link to={`/gdi/banners/add/${id}`}>
        <button className='editar-btn'>Editar</button>
      </Link>
    )
  } else {
    return (
      <Link to={`/gdi/banners-image/add/${id}`}>
        <button className='editar-btn'>Editar</button>
      </Link>
    )
  }
}
