import React ,{ Component }from "react";
import {get} from '../../api'
import {icones} from '../common/Icones'
import {Link} from 'react-router-dom'


class ServiceThree extends Component{
    constructor(props){
        super(props);
        this.state = {
            ServiceList: []
        }
    }
    componentDidMount() {
        get('servicos/index').then(data => this.setState({ServiceList: data}) )
    }
    render(){
        const {column} = this.props;
        const ServiceContent = this.state.ServiceList.slice(0 , this.props.item);
        return(

            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <Link to={`/servicos/${val.slug}`}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {icones[val.icone]}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.titulo}</h3>
                                        <p>{val.descricao}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
