import React, { Component, Fragment } from 'react'
import { AiOutlineFileAdd } from 'react-icons/ai'
import { MdSave, MdInsertPhoto } from 'react-icons/md'
import Button from '@material-ui/core/Button'
import Select, { components } from 'react-select'
import Bloco from './Bloco'
import EditableText from '../EditableText'
import { get, post, upload } from '../../api'
import { icones, option } from '../common/Icones'
import Modal from 'react-bootstrap/Modal'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab';
const { Option } = components

const template = {
  titulo: 'Titulo do card',
  descricao:
    'Pariatur officia id esse labore veniam mollit consectetur consequat cupidatat velit enim.',
  icone: 'Fachadas',
  breadcrumb: '',
  breadcrumbDescricao: 'Breadcrumb Descrição'
}

const IconOption = props => (
  <Option {...props}>
    {icones[props.data.value]}
    {props.data.label}
  </Option>
)

class AddServico extends Component {
  constructor (props) {
    super(props)
    this.state = {
      id: null,
      titulo: '',
      descricao: '',
      icone: 'MdWork',
      breadcrumb: '',
      breadcrumbDescricao: '',
      blocos: [],

      componentesBlocos: [],
      levelBlocos: 0, /// Vai determinar a ordem de mostrar o blocos na vizualização
      modalUm: false,
      editar: false,
      proporcao: ''
    }
  }
  showModalUm = pr => {
    this.setState({ proporcao: pr, modalUm: true })
  }
  deleteBlocos = id => {
    let cB = this.state.componentesBlocos.filter(bloco => bloco.id !== id)
    let b = this.state.blocos.filter(
      bloco => parseInt(bloco.id) !== parseInt(id)
    )
    this.setState({
      blocos: b,
      componentesBlocos: cB
    })
  }

  handleBreadcrumb (event) {
    const formData = new FormData()
    formData.append('usuarioImagem', event.target.files[0])
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      if (img.width === 1920 && img.height === 900) {
        upload('servicos/upload_breadcrumb', formData)
          .then(data => {
            event.preventDefault()
            this.setState({ breadcrumb: data.upload_data.file_name })
          })
          .catch(err => this.showModalUm(err.error))
        return true
      } else {
        this.showModalUm(' 1920x900')
      }

      return false
    }
  }

  sluglization = titulo => {
    return titulo
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase()
  }
  salvar = () => {
    let data = { ...this.state }

    delete data.componentesBlocos
    delete data.levelBlocos
    delete data.modalUm
    delete data.editar
    delete data.proporcao

    post('/servicos/editar', data)
      .then(
        data =>
          window.location.href = '/gdi/index/servicos'
      )
      .catch(err => console.log(err))
  }
  handleSave = newData => {
    let temp = this.state.blocos
    let novosBlocos = temp.filter(e => e.id !== newData.id)
    novosBlocos.push(newData)
    novosBlocos.sort((a, b) => a.id > b.id)
    this.setState({ blocos: novosBlocos })
  }

  addBlocos (modelo, conteudo = null, id = null) {
    const templateBloco = {
      id: id || this.state.levelBlocos,
      bloco: (
        <Bloco
          blocos={conteudo}
          handleSave={this.handleSave}
          modelo={modelo}
          levelBlocos={this.state.levelBlocos}
          delete={this.deleteBlocos}
          showModal={this.showModalUm}
        />
      )
    }
    this.setState({
      componentesBlocos: this.state.componentesBlocos.concat(templateBloco)
    })
    const level = templateBloco.id
    this.setState({ levelBlocos: level + 1 })
  }
  handleNotificao (notificao) {
    this.setState({ smShow: true })
    notificao.remove()
  }
  componentDidMount () {
    const id_servico = this.props.match.params.id
    if (id_servico) {
      this.setState({ editar: true })
      get(`servicos/index/${id_servico}`).then(data => {
        this.setState(data)
        this.state.blocos.map(bloco =>
          this.addBlocos(
            bloco.modelo,
            { conteudo: bloco.conteudo },
            parseInt(bloco.id)
          )
        )
      })
    } else {
      this.setState(template)
    }
  }
  render () {
    return (
      <div className='container'>
        <Modal
          size='lg'
          show={this.state.modalUm}
          onHide={() => this.setState({ modalUm: false })}
          aria-labelledby='example-modal-sizes-title-sm'
        >
          <Modal.Header closeButton>
            <Modal.Title id='example-modal-sizes-title-sm'>
              Dimensões da imagem
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            As dimensões do banner estão incorretas. As dimensões devem ser
            {this.state.proporcao}
          </Modal.Body>
        </Modal>
        <div className='row mb--25'>
          <div className='col-lg-12'>
            <h3>Adicionar Serviços</h3>
            <p>
              Você pode personalizar os serviços que você oferece no seu site
              adicionando titulo e descrições no card e adicionar um artigo à
              respeito desse serviço nessa página.
            </p>
          </div>
        </div>
        <div className='row  mb--25'>
          <div className='col-lg-4'>
            <Paper style={{ padding: '15px' }}>
              <h3 className='text-center'>Personalizar Card</h3>
              <div className='service service__style--2'>
                <div className='icon'>
                  {icones[this.state.icone]}
                </div>
                <div className='content'>
                  {this.state.titulo ? (
                    <h3 className='title'>
                      <EditableText
                        value={this.state.titulo}
                        onChange={e =>
                          this.setState({
                            titulo: e,
                            slug: this.sluglization(e)
                          })
                        }
                      />
                    </h3>
                  ) : (
                    ' '
                  )}
                  {this.state.descricao ? (
                    <p style={{ wordBreak: 'break-all' }}>
                      <EditableText
                        value={this.state.descricao}
                        onChange={d => this.setState({ descricao: d })}
                      />
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className='icone_selecao'>
                <label>Icone</label>
                <Select
                  defaultValue={option[0]}
                  options={option}
                  label='Single select'
                  components={{ Option: IconOption }}
                  onChange={event => this.setState({ icone: event.value })}
                />
              </div>
            </Paper>
          </div>

          <div className='col-lg-8'>
            <Paper style={{ padding: '15px' }}>
              <h3 className='text-center'>Personalizar Breadcrumb</h3>

              <div
                className=' breadcrumb-area rn-bg-color ptb--120 bg_image'
                style={{
                  backgroundImage: `url("/static/images/servicos/breadcrumb/${this.state.breadcrumb}`
                }}
                data-black-overlay='6'
              >
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='rn-page-title text-center pt--100'>
                        <h2 className='title theme-gradient'>
                          {this.state.titulo}
                        </h2>
                        <p>
                          {this.state.breadcrumbDescricao ? (
                            <p style={{ wordBreak: 'break-all' }}>
                              <EditableText
                                value={this.state.breadcrumbDescricao}
                                onChange={d => this.setState({ breadcrumbDescricao: d })}
                              />
                            </p>
                          ) : (
                            ''
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='text-center'>
                <input
                  accept='image/*'
                  id='breadcrumb'
                  type='file'
                  style={{ display: 'none' }}
                  onChange={e => this.handleBreadcrumb(e)}
                />
                <label style={{ cursor: 'pointer' }} htmlFor='breadcrumb'>
                  <IconButton
                    color='primary'
                    aria-label='upload picture'
                    component='span'
                  >
                    <MdInsertPhoto size={40} />
                  </IconButton>
                  <small> Adicionar Imagem (1920x900)</small>
                </label>
              </div>
            </Paper>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            <Paper style={{ padding: '15px' }}>
              <h3>Blocos</h3>
              <p>Corpo do artigo</p>
              <div className='rn-service-details ptb--120 bg_color--1'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='service-details-inner'>
                        <div className='inner'>
                          <div id='areaDosBlocos'>
                            {this.state.componentesBlocos.map((bloco, i) => (
                              <Fragment key={i}>{bloco.bloco}</Fragment>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row text-lg-center'>
                <div className='col-lg-6 '>
                  <button
                    style={{ width: '100%' }}
                    className='btn btn-primary'
                    onClick={() => this.addBlocos('servico-modelo-um')}
                  >
                    <AiOutlineFileAdd /> Adicionar Modelo 1
                  </button>
                </div>
                <div className='col-lg-6'>
                  <button
                    style={{ width: '100%' }}
                    className='btn btn-danger'
                    onClick={() => this.addBlocos('servico-modelo-dois')}
                  >
                    <AiOutlineFileAdd /> Adicionar Modelo 2
                  </button>
                </div>
              </div>
            </Paper>
          </div>
        </div>

        <div className='row mt--50 mb--25'>
          <div className='col-lg-12'>
            <div className='ml-auto d-flex justify-content-end'>
              <Fab
              onClick={() => this.salvar()}
              color='primary'
              style={{position: 'fixed'}}
            >
              <MdSave size={30} />
            </Fab>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default AddServico

const preview = {
  border: '.25px solid #393939',
  marginTop: '20px',
  borderRadius: '20px',
  padding: '1rem 4rem'
}
const label = {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'start',
  marginTop: '-1.9rem',
  marginBottom: '5.9rem'
}
