import React, { Component } from "react";
import {get} from '../api'
class BrandTwo extends Component{
    constructor(){
        super()
        this.state ={
            parceiros : []
        }
    }
    componentDidMount() {
        get('parceiros/index')
            .then(data => this.setState({parceiros: data}))
    }
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                {this.state.parceiros.map((v,i) =>(
                        <li key={i}>
                            <img src={`/static/images/parceiros/${v.logo}`} alt={v.nome}/>
                        </li>
                    ))}

                    {/* <li>
                        <img src="/static/images/brand/brand-01.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/static/images/brand/brand-02.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/static/images/brand/brand-03.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/static/images/brand/brand-04.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/static/images/brand/brand-05.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/static/images/brand/brand-06.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/static/images/brand/brand-02.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/static/images/brand/brand-03.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/static/images/brand/brand-04.png" alt="Logo Images"/>
                    </li> */}
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;