import React, { Component } from 'react'
import EditableText from '../EditableText'
import { get, post, upload } from '../../api'
// import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { icones, option } from '../common/Icones'
import Modal from 'react-bootstrap/Modal'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import { MdInsertPhoto, MdSave } from 'react-icons/md'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
// const IconOption = props => (
//   <Option {...props}>
//     {icones[props.data.value]}
//     {props.data.label}
//   </Option>
// )

class AddBanner extends Component {
  constructor (props) {
    super(props)
    this.state = {
      bg: '',
      titulo: '',
      descricao: '',
      btn1: '',
      btn2: '',
      btn1_icone: '',
      btn2_icone: '',
      modelo: 'icones',

      editar: false,
      smShow: false
    }
  }
  componentDidMount() {
    const id = this.props.match.params.id
    if (id) {
      get(`/banners/index/${id}`).then(data => {
        this.setState({
          bg: data.bg,
          titulo: data.titulo,
          descricao: data.descricao,
          btn1: data.btn1,
          btn2: data.btn2,
          btn1_icone: data.btn1_icone,
          btn2_icone: data.btn2_icone,
          linkBtn1: data.linkBtn1,
          linkBtn2: data.linkBtn2,
          editar: true
        })
      })
    } else {
      this.setState({
        bg: 'banners_model.jpg',
        titulo: 'Titulo',
        descricao: 'Descrição',
        btn1: 'btn1',
        btn2: 'btn2',
        btn1_icone: 'MdWork',
        btn2_icone: 'MdWork'
      })
    }
  }
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  setShowModal () {
    this.setState({ smShow: !this.state.Show })
  }
  handleChangeImage (event) {
    const formData = new FormData()
    formData.append('bgImage', event.target.files[0])
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      if (img.width === 1920 && img.height === 700) {
        upload('banners/do_upload', formData)
          .then(data => {
            event.preventDefault()
            this.setState({ bg: data.upload_data.file_name })
          })
          .catch(err => console.log(err))
        return true
      }
      this.setState({ smShow: true })
      return false
    }
  }
  handleBannerClick () {
    document.getElementById('input-image').click()
  }
  handleTitulo (event) {
    this.setState({ titulo: event })
  }
  handleDescription (event) {
    this.setState({ descricao: event })
  }

  salvar (id) {
    const data = {
      id: id,
      bg: this.state.bg,
      titulo: this.state.titulo,
      descricao: this.state.descricao,
      btn1: this.state.btn1,
      btn2: this.state.btn2,
      btn1_icone: this.state.btn1_icone,
      btn2_icone: this.state.btn2_icone,
      linkBtn1: this.state.linkBtn1,
      linkBtn2: this.state.linkBtn2,
      modelo: 'icone'
    }
    if (this.state.editar) {
      post('banners/editar', data)
        .then(data => window.location.href = '/gdi/index/banners')
    } else {
      post('banners/store', data)
        .then(data => window.location.href = '/gdi/index/banners')
    }

  }

  render () {
    return (
      <div className='container' style={{ width: 'auto', margin: '0 auto' }}>
        {/* Modal Area */}
        <Modal
          size='lg'
          show={this.state.smShow}
          onHide={() => this.setState({ smShow: false })}
          aria-labelledby='example-modal-sizes-title-sm'
        >
          <Modal.Header closeButton>
            <Modal.Title id='example-modal-sizes-title-sm'>
              Dimensões do Banner
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            As dimensões do banner estão incorretas. As dimensões devem ser
            1920x700
          </Modal.Body>
        </Modal>
        <div className="row mb--25">
          <div className="col-lg-10">
            <h3>Adicionar Banners</h3>
            <p>Aqui você pode adicionar novos banners a sua página inicial e personalizar os icones e links que irão aparecer no banner.</p>
          </div>
        </div>


        {/* Banner Area */}
        <Paper className="mb--25" style={{padding: '15px'}}>
        <div className='slider-wrapper' id='home'>
          <div className='slider-activation'>
            <div
              className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image`}
              style={{
                backgroundImage: `url("/static/images/banners/${this.state.bg}") `
              }}
            >
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-8 col-sm-12 '>
                    <div className={`inner`}>
                      {this.state.titulo ? (
                        <h1 className='title'>
                          <EditableText
                            value={this.state.titulo}
                            onChange={change =>
                              this.setState({ titulo: change })
                            }
                          />
                        </h1>
                      ) : (
                        ''
                      )}
                      {this.state.descricao ? (
                        <p className='description'>
                          <EditableText
                            value={this.state.descricao}
                            onChange={change =>
                              this.setState({ descricao: change })
                            }
                          />
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div
                      className='inner h-100'
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'flex-end',
                        marginLeft: 'auto'
                      }}
                    >
                      {this.state.btn1 ? (
                        <a className="d-flex align-items-center flex-column">
                          <div className="banner-icone">
                            {icones[this.state.btn1_icone]}
                          </div>
                          <br />
                          <p style={{ color: '#fafafa', textAlign: 'center' }}>
                            <EditableText
                              value={this.state.btn1}
                              onChange={change =>
                                this.setState({ btn1: change })
                              }
                            />
                          </p>
                        </a>
                      ) : (
                        ''
                      )}
                      {this.state.btn2 ? (
                        <a className="d-flex align-items-center flex-column">
                        <div className="banner-icone">
                          {icones[this.state.btn2_icone]}
                          </div>
                          <br />
                          <p style={{ color: '#fafafa', textAlign: 'center' }}>
                            <EditableText
                              value={this.state.btn2}
                              onChange={change =>
                                this.setState({ btn2: change })
                              }
                            />
                          </p>
                        </a>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Options area */}
        </Paper>
        <Paper className="p-3">
        <div className="row">
          <div className="col-lg-6 mt--10">
            <FormControl variant='outlined' fullWidth>
                  <InputLabel id='demo-simple-select-outlined-label'>
                    Icone do btn 1
                  </InputLabel>
                  <Select
                    name='btn1_icone'
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={this.state.btn1_icone}
                    onChange={this.handleChange}
                    label='Icone do btn 1'

                  >
                    {
                      option.map(option =>(
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
          </div>
          <div className="col-lg-6 mt--10">
            <TextField
                  defaultValue={this.state.linkBtn1}
                  value={this.state.linkBtn1}
                  label='Link para o primeiro btn'
                  fullWidth
                  variant='outlined'
                  id='input-with-icon-adornment'
                  name='linkBtn1'
                  placeholder="Link do btn 1"
                  onChange = {this.handleChange}/>
          </div>
        </div>
        <div className="row mt--15">
          <div className="col-lg-6 mt--10">
            <FormControl variant='outlined' fullWidth>
                  <InputLabel id='demo-simple-select-outlined-label'>
                    Icone do btn 2
                  </InputLabel>
                  <Select
                    name='btn2_icone'
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={this.state.btn2_icone}
                    onChange={this.handleChange}
                    label='Icone do btn 2'

                  >
                    {
                      option.map(option =>(
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
          </div>
          <div className="col-lg-6 mt--10">
            <TextField
                  defaultValue={this.state.linkBtn2}
                  value={this.state.linkBtn2}
                  label='Link para o segundo btn'
                  fullWidth
                  variant='outlined'
                  id='input-with-icon-adornment'
                  name='linkBtn2'
                  placeholder="Link do btn2"
                  onChange = {this.handleChange}/>
          </div>
        </div>
        </Paper>
        <div className='row mt--25'>
            <div className='col-lg-6 ml-auto d-flex justify-content-end align-items-end'>
              <Button
                variant='contained'
                color='primary'
                size='large'
                startIcon={<MdSave />}
                onClick={() => this.salvar(this.props.match.params.id || false)}
              >
                Salvar
              </Button>
            </div>
          </div>
      </div>
    )
  }
}
export default AddBanner
