import React, { Component } from 'react'
import PageHelmet from '../components/common/Helmet'
import Breadcrumb from '../elements/common/Breadcrumb'
import Testimonial from '../elements/Testimonial'
import BrandTwo from '../elements/BrandTwo'
import { get } from '../api'
import Team from './Team'
import LoadingScreen from 'react-loading-screen'
import Galeria from './Galeria'
class About extends Component {
  constructor () {
    super()
    this.state = {
      about: [],
      galeria: [],
      loading: true
    }
  }
  componentDidMount () {
    window.scrollTo(0, 0)
    this.props.setCurrent('#about')
    get('about/index').then(data =>{
      this.setState({galeria: JSON.parse(data.galeria)}, () => console.log(this.state.galeria))
      delete data.galeria
      this.setState({ about: data, loading: false })
    }

    )
  }

  render () {
    return (
      <React.Fragment>
        <LoadingScreen
          loading={this.state.loading}
          bgColor='#f1f1f1'
          spinnerColor='#9ee5f8'
          textColor='#676767'
        >
          <PageHelmet pageTitle={'Sobre nós' || ''} />

          <div id='about'>
            {/* Start Breadcrump Area */}
            <Breadcrumb title={this.props.Estatico.breadcrumb_sobre} description={this.props.Estatico.descricao_breadcrumb_sobre} />
            {/* End Breadcrump Area */}

            {/* Start About Area  */}
            <div className='rn-about-area ptb--120 bg_color--1'>
              <div className='rn-about-wrapper'>
                <div className='container'>
                  <div className='row row--35 align-items-center'>
                    <div className='col-lg-5'>
                      <div className='thumbnail'>
                        <img
                          src={`/static/images/about/01/${this.state.about.image1}`}
                          alt='Finding Images'
                        />
                      </div>
                    </div>
                    <div className='col-lg-7'>
                      <div className='about-inner inner'>
                        <div className='section-title'>
                          {/* <h2 className="title">{title}</h2> */}
                          <h2 className='title'>{this.state.about.titulo1}</h2>
                          <p className='description'>
                            {this.state.about.paragrafo1}
                          </p>
                          <p>{this.state.about.paragrafo2}</p>
                          <p>{this.state.about.paragrafo3}</p>
                        </div>
                        {/* <div className='row mt--30'>
                          <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                            <div className='about-us-list'>
                              <h3 className='title'>
                                {this.state.about.titulo2}
                              </h3>
                              <p>{this.state.about.paragrafo2}</p>
                            </div>
                          </div>
                          <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                            <div className='about-us-list'>
                              <h3 className='title'>
                                {this.state.about.titulo3}
                              </h3>
                              <p>{this.state.about.paragrafo3}</p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End About Area  */}

            <div className='rn-about-wrapper mb--120'>
              <div className='container'>
                <div className='row row--35 align-items-center'>
                  <div className='col-lg-12'>
                  <h2 className="title">{this.state.about.galeriaTitulo}</h2>
                  <p className="description">{this.state.about.galeriaDescricao}</p>
                  </div>
                  </div>
                <div className='row row--35 align-items-center mt--25'>
                  <div className='col-lg-12'>
                    <Galeria galeria={this.state.galeria}/>
                  </div>
                </div>
              </div>
            </div>

            {/* Start Finding Us Area  */}
            <div className='rn-finding-us-area rn-finding-us bg_color--1'>
              <div className='inner'>
                <div className='content-wrapper'>
                  <div className='content'>
                    <h4 className='theme-gradient'>
                      {this.state.about.titulo4}
                    </h4>
                    <p>{this.state.about.paragrafo4}</p>
                    <a className='rn-btn btn-white' href='/contato'>
                      {this.state.about.btn}
                    </a>
                  </div>
                </div>
                <div className='thumbnail'>
                  <div className='image'>
                    <img
                      src={`/static/images/about/02/${this.state.about.image2}`}
                      alt='Finding Images'
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* End Finding Us Area  */}

            {/* Start Single Team  */}
            <div className='rn-team-area ptb--120 bg_color--1' id='team'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='section-title service-style--3 text-left mb--25 mb_sm--0'>
                      <h2 className='title'>
                        {this.props.Estatico.titulo_equipe}
                      </h2>
                      <p>{this.props.Estatico.descricao_trabalhos}</p>
                    </div>
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <Team
                    column='col-lg-4 col-md-6 col-sm-6 col-12 mt--30'
                    teamStyle=''
                    item='4'
                  />
                </div>
              </div>
            </div>
            {/* End Single Team  */}
            {/* End Team Area  */}

            {/* Start Testimonial Area */}
            <div className='rn-testimonial-area bg_color--5 ptb--120'>
              <div className='container'>
                <Testimonial />
              </div>
            </div>
            {/* End Testimonial Area */}
          </div>
        </LoadingScreen>
      </React.Fragment>
    )
  }
}
export default About
