import React, { Component ,Fragment } from "react";
import {get} from '../../api'
import {Link} from 'react-router-dom'
class BLogList extends Component{
    constructor(){
        super()
        this.state = {blog:[]}
    }
    componentDidMount() {
        get('blog/index').then(data => this.setState({ blog: data }))
    }

    render() {
        const PostList = this.state.blog.slice(0 , 6);
        return (
             <Fragment>
                 <div className="row">
                    {PostList.map((value , i ) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="blog blog-style--1">
                                <div className="thumbnail">
                                    <Link to={`blog/${value.slug}`}>
                                        <img className="w-100" src={`/static/images/blog/card/${value.cardImagem}`} alt="Blog Images"/>
                                    </Link>
                                </div>
                                <div className="content">
                                    <p className="blogtype">{value.categoria}</p>
                                    <h4 className="title"><Link to={`blog/${value.slug}`}>{value.titulo}</Link></h4>
                                    <div className="blog-btn">
                                        <Link className="rn-btn text-white" to={`blog/${value.slug}`}>Saiba Mais</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                     ))}
                 </div>
             </Fragment>
        );
    }
}
export default BLogList;