import React, { Component } from 'react'

import { Link } from 'react-router-dom'
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaYoutube
} from 'react-icons/fa'
import { get } from '../../api'
const SocialShare = {
  facebook: <FaFacebookF />,
  linkedin: <FaLinkedinIn />,
  instagram: <FaInstagram />,
  twitter: <FaTwitter />,
  youtube: <FaYoutube />
}

class Footer extends Component {
  constructor (props) {
    super(props)
    this.state = { Estatico: this.props.Estatico, redes: {}, email: [] }
  }
  componentDidMount () {
    get('redes/index').then(data => this.setState({ redes: data }))
    delete this.state.redes.id
    get('contato/index').then(data => this.setState({email: [data[0].email1]}))
    window.scrollTo(0, 0)

  }
  render () {
    return (
      <React.Fragment>
        <footer className='footer-area'>
          <div className='footer-wrapper'>
            <div className='row align-items-end row--0'>
              <div className='col-lg-6'>
                <div className='footer-left'>
                  <div className='inner'>
                    <span>{this.props.Estatico.footer_descricao}</span>
                    <h2>{this.props.Estatico.footer_titulo}</h2>
                    <a className='rn-button-style--2' href='/contact'>
                      <span>Contate-Nos</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='footer-right' data-black-overlay='6'
                  style={{
                    backgroundImage: `url("/static/images/footer/${this.props.Estatico.footerDois}")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionY: 'bottom'
                  }}
                >
                  <div className='row'>
                    {/* Start Single Widget  */}
                    <div className='col-lg-6 col-sm-6 col-12'>
                      <div className='footer-link'>
                        <h4>Links</h4>
                        <ul className='ft-link'>
                          <li>
                            <Link to='/trabalhos'>Trabalho</Link>
                          </li>
                          <li>
                            <Link to='/sobre'>Sobre Nós</Link>
                          </li>
                          <li>
                            <Link to='/contato'>Contato</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className='col-lg-6 col-sm-6 col-12 mt_mobile--30'>
                      <div className='footer-link'>
                        <h4>Fale Conosco</h4>
                        <ul className='ft-link'>
                        {this.state.email.map((email,i) =>  (<li key={i}>
                            <a  href={`mailto:${email}`}>{email}</a>
                          </li> ))}
                        </ul>

                        <div className='social-share-inner'>
                          <ul className='social-share social-style--2 d-flex justify-content-start liststyle mt--15'>
                           <RedesSocias redes={this.state.redes}/>
                          </ul>
                        </div>
                      </div>

                    </div>
                    {/* End Single Widget  */}

                    <div className='col-lg-8'>
                      <div className='copyright-text'>
                        <p>
                         Copyright © {new Date().getFullYear()} Todos direitos
                          reservados.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="copyright-logo">
                      <img src="/static/images/taura/taura.png" alt="Logo Taura"
                        />
                    </div>
                  </div>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    )
  }
}
export default Footer

const RedesSocias = ({redes}) => {
  let components = []
  for (let p in redes) {
    if(p === 'id' || redes[p] === ''){

    }else{
        components.push({
      Social: SocialShare[p],
      link: redes[p]
    })
    }

  }
  return (
    <>
      {components.map((val, i) => (
        <li key={i}>
          <a href={`${val.link}`}>{val.Social}</a>
        </li>
      ))}
    </>
  )
}