import React, { Component } from 'react'
import { get, post, upload } from '../../api'
import Modal from 'react-bootstrap/Modal'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import { MdInsertPhoto, MdSave } from 'react-icons/md'
import Button from '@material-ui/core/Button'
import EditorJs from 'react-editor-js'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Fab from '@material-ui/core/Fab';
const sanitizerConfig = {
  br: false
}
const removeBr = blocks => {
  const r = /br/
  return blocks.map(block => {
    if (r.test(block.data.text)) {
      block.data.text.replace('<br>', '')
      return block
    }
    return block
  })
}

class AddBannerImagem extends Component {
  constructor () {
    super()
    this.state = {
      montar: false,
      bg: 'bg-image-31.jpg',
      modelo: 'imagem',
      modo_editar: false,
      smModal: false,
      tituloAlign: 'align-items-center',
      subtituloAlign: 'align-items-center',
      titulo: [
        { type: 'paragraph', data: { text: 'Per' } },
        { type: 'paragraph', data: { text: 'sonali' } },
        { type: 'paragraph', data: { text: 'ze' } }
      ],
      subtitulo: [
        { type: 'paragraph', data: { text: 'sua frota' } },
        { type: 'paragraph', data: { text: 'e sua fachada' } }
      ],
      linkBtn1: '',
      ordem: 0,
      nova_ordem: 0,
      ordemArray: []
    }
  }
  salvar () {
    let data = {
      id: this.state.id || null,
      bg: this.state.bg,
      titulo: JSON.stringify(this.state.titulo),
      subtitulo: JSON.stringify(this.state.subtitulo),
      tituloAlign: this.state.tituloAlign,
      subtituloAlign: this.state.subtituloAlign,
      modelo: 'imagem',
      linkBtn1: this.state.linkBtn1,
      ordem: this.state.ordem,
      nova_ordem: this.state.nova_ordem
    }
    if (this.state.modo_editar) {
      post('banners/editar', data).then(
        data => {window.location.href = '/gdi/index/banners'}
      )
    } else {
      post('banners/store', data).then(
        data => {window.location.href = '/gdi/index/banners'}
      )
    }
  }
  handleChangeImage = e => {
    const formData = new FormData()
    formData.append('bgImage', e.target.files[0])
    let img = new Image()
    img.src = window.URL.createObjectURL(e.target.files[0])
    img.onload = () => {
      if (img.width === 1920 && img.height === 700) {
        upload('banners/do_upload', formData).then(data => {
          e.preventDefault()
          this.setState({ bg: data.upload_data.file_name })
        })
        return true
      }
      this.setState({ smShow: true })
      return false
    }
  }
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  handleTituloChange = data => {
    let blocks = removeBr(data.blocks)
    this.setState({
      titulo: blocks
    })
  }
  handleSubtituloChange = data => {
    let blocks = removeBr(data.blocks)
    this.setState({
      subtitulo: blocks
    })
  }
  swapOrdem = e => {
    this.setState({ nova_ordem: e.target.value })
  }
  componentDidMount () {
    get('banners/index').then(banners => {
      if (banners[0]) {
        banners.sort((a, b) => parseInt(a.ordem) - parseInt(b.ordem))
        let ordemArray = []
        for (let i = 0; i < parseInt(banners[banners.length - 1].ordem); i++) {
          ordemArray[i] = i + 1
        }
        this.setState({ ordemArray: ordemArray })
      }

      if (this.props.match.params.id) {
        this.setState({ modo_editar: true })
        get(`banners/index/${this.props.match.params.id}`).then(d => {
          this.setState({
            id: d.id,
            bg: d.bg,
            titulo: JSON.parse(d.titulo),
            subtitulo: JSON.parse(d.subtitulo),
            tituloAlign: d.tituloAlign,
            subtituloAlign: d.subtituloAlign,
            montar: true,
            modo_editar: true,
            linkBtn1: d.linkBtn1,
            ordem: parseInt(d.ordem),
            nova_ordem: parseInt(d.ordem)
          })
        })
      } else {
        this.setState({ montar: true })
        get('banners/index').then(banners => {
          if (banners[0]) {
            this.setState({
              ordem: parseInt(banners[banners.length - 1].ordem) + 1
            })
            this.setState({ nova_ordem: this.state.ordem })
            this.setState({
              ordemArray: this.state.ordemArray.concat(
                this.state.ordemArray[this.state.ordemArray.length - 1] + 1
              )
            })
          }else{
            this.setState({ordem: 1, nova_ordem: 1, ordemArray: [1]})
          }

        })
      }
    })
  }

  render () {
    return (
      <div className='container'>
        <Modal
          className='mt--50'
          show={this.state.smShow}
          onHide={() => this.setState({ smShow: false })}
          size='lg'
          aria-labelledby='example-modal-sizes-title-sm'
        >
          <Modal.Header closeButton>
            <Modal.Title id='example-modal-sizes-title-sm'>
              Dimensões do Banner
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            As dimensões do banner estão incorretas. As dimensões devem ser
            1920x700
          </Modal.Body>
        </Modal>
        <div className='row'>
          <div className='col-lg-12'>
            <h3>Adicionar Banner apenas com imagens</h3>
            <p>
              Você pode adicionar banners que possuam apenas images sem texto
              personalizado
            </p>
          </div>
        </div>
        <Paper>
          <div className='row mt--25'>
            <div className='col-lg-12'>
              <div className='slider-wrapper' id='home'>
                <div className='slider-activation'>
                  <div
                    className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image`}
                    style={{
                      backgroundImage: `url("/static/images/banners/${this.state.bg}") `
                    }}
                  >
                    <div className='container h-100 w-100'>
                      <div className='row h-100 w-100'>
                        <div
                          className={`col-lg-6 d-flex justify-content-end jcmc ${this.state.tituloAlign} h-100`}
                        >
                          <div className={`inner p-0`}>
                            <h1 className='title'>
                              {this.state.montar ? (
                                <EditorTitulo
                                  data={this.state.titulo}
                                  handleTituloChange={this.handleTituloChange}
                                />
                              ) : (
                                ''
                              )}
                            </h1>
                          </div>
                        </div>
                        <div
                          className={`jcmc col-lg-6 col-sm-12 d-flex justify-content-start h-100 ${this.state.subtituloAlign}`}
                        >
                          <div className={`inner p-0`}>
                            <p className='subtitulo-banner-imagem'>
                              <div
                                id='subtitulo'
                                className='banner-titulo-imagens'
                              >
                                {this.state.montar ? (
                                  <EditorSubtitulo
                                    data={this.state.subtitulo}
                                    handleSubtituloChange={
                                      this.handleSubtituloChange
                                    }
                                  />
                                ) : (
                                  ' '
                                )}
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class='d-flex justify-content-center'>
                <div className='text-center'>
                  <input
                    accept='image/*'
                    id='imageDois'
                    type='file'
                    style={{ display: 'none' }}
                    onChange={this.handleChangeImage}
                  />
                  <label style={{ cursor: 'pointer' }} htmlFor='imageDois'>
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                    >
                      <MdInsertPhoto size={40} />
                    </IconButton>
                    <small> Adicionar Imagem (1920x700) </small>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Paper>
        <Paper className='p-3 mt--25'>
          <div className='container'>
            <div className='row mt--25'>
              <div className='col-lg-12 text-center'>
                <h3>Link e ordem do banner</h3>
              </div>
            </div>
            <div className='row mt--15 justify-content-start align-items-center'>
              <div className='col-lg-6'>
                <TextField
                  defaultValue={this.state.linkBtn1}
                  value={this.state.linkBtn1}
                  fullWidth
                  variant='outlined'
                  label={'Link'}
                  onChange={e => this.setState({ linkBtn1: e.target.value })}
                />
              </div>
              <div className='col-lg-6'>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel id='demo-simple-select-outlined-label'>
                    Ordem
                  </InputLabel>
                  <Select
                    name='ordem'
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={this.state.nova_ordem}
                    onChange={this.swapOrdem}
                    label='Ordem'
                  >
                    {this.state.ordemArray.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </Paper>
        <Paper className='p-3 mt--25'>
          <div className='container'>
            <div className='row mt--25'>
              <div className='col-lg-12'>
                <h3>Posição dos textos no Banner</h3>
              </div>
            </div>
            <div className='row mt--15'>
              <div className='col-lg-6'>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel id='demo-simple-select-outlined-label'>
                    Titulo
                  </InputLabel>
                  <Select
                    name='tituloAlign'
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={this.state.tituloAlign}
                    onChange={this.handleChange}
                    label='Titulo'
                  >
                    <MenuItem value={'align-items-start'}>Acima</MenuItem>

                    <MenuItem value='align-items-center'>
                      <em>Centro</em>
                    </MenuItem>
                    <MenuItem value={'align-items-end'}>Abaixo</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className='col-lg-6'>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel id='demo-simple-select-outlined-label'>
                    Subtitulo
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-outlined-label'
                    name='subtituloAlign'
                    id='demo-simple-select-outlined'
                    value={this.state.subtituloAlign}
                    onChange={this.handleChange}
                    label='Subtitulo'
                    fullWidth
                  >
                    <MenuItem value={'align-items-start'}>Acima</MenuItem>
                    <MenuItem value='align-items-center'>
                      <em>Centro</em>
                    </MenuItem>
                    <MenuItem value={'align-items-end'}>Abaixo</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </Paper>
        <div className='row mt--25'>
          <div className='col-lg-12 d-flex justify-content-end'>
            <Fab
              onClick={() => this.salvar()}
              color='primary'
              style={{position: 'fixed'}}
            >
              <MdSave size={30} />
            </Fab>
          </div>
        </div>
      </div>
    )
  }
}
export default AddBannerImagem

class EditorSubtitulo extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: this.props.data ? { blocks: this.props.data } : 'ds'
    }
  }
  handleSave = data => {
    this.props.handleSubtituloChange(data)
  }
  render () {
    return (
      <div id='subtitulo' className='banner-titulo-imagens'>
        <EditorJs
          className='banner-titulo-imagens'
          onChange={e => {
            e.saver.save().then(data => this.handleSave(data))
          }}
          holder={`subtitulo`}
          autofocus={true}
          data={this.state.data}
        />
      </div>
    )
  }
}
class EditorTitulo extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: this.props.data ? { blocks: this.props.data } : null
    }
  }
  handleSave = data => {
    this.props.handleTituloChange(data)
  }
  render () {
    return (
      <div id='titulo' className='banner-titulo-imagens'>
        <EditorJs
          className='banner-titulo-imagens'
          onChange={e => {
            e.saver.save().then(data => this.handleSave(data))
          }}
          holder={`titulo`}
          autofocus={true}
          data={this.state.data}
        />
      </div>
    )
  }
}
