import React, { Component } from 'react'
import { icones } from '../common/Icones'
import { Link } from 'react-router-dom'
class BannerIcones extends Component {
  render () {
    const {
      bg,
      category,
      titulo,
      descricao,
      btn1_icone,
      btn1,
      btn2_icone,
      btn2,
      linkBtn1,
      linkBtn2
    } = this.props

    return (
      <>
        <div
          className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image`}
          style={{
            backgroundImage: `url("/static/images/banners/${bg}") `
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-8 col-sm-12 text-md-center text-lg-left'>
                <div className={`inner `}>
                  {category ? <span>{category}</span> : ''}
                  {titulo ? <h1 className='title'>{titulo}</h1> : ''}
                  {descricao ? <p className='description'>{descricao}</p> : ''}
                </div>
              </div>
              <div className='col-lg-4 col-sm-12'>
                <div className='inner d-flex h-100 justify-content-center mt-sm-4'>
                  {btn1 ? (
                    <Link
                      to={`${linkBtn1}`}
                      className='d-flex mr-3 mr-5 mt-4 justify-content-end align-items-center flex-column'
                    >
                      <div className='banner-icone'>{icones[btn1_icone]}</div>
                      <br />
                      <p
                        style={{
                          color: '#fafafa',
                          textAlign: 'center'
                        }}
                      >
                        {btn1}
                      </p>
                    </Link>
                  ) : (
                    ''
                  )}
                  {btn2 ? (
                    <Link
                      to={`${linkBtn2}`}
                      className='d-flex justify-content-end align-items-center flex-column'
                    >
                      <div className='banner-icone'>{icones[btn2_icone]}</div>
                      <br />
                      <p
                        style={{
                          color: '#fafafa',
                          textAlign: 'center'
                        }}
                      >
                        {btn2}
                      </p>
                    </Link>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default BannerIcones
