const template = [
  {
    type: 'embed',
    data: {
      service: 'youtube',
      source: 'https://www.youtube.com/watch?v=UFc-RPbq8kg',
      embed: 'https://www.youtube.com/embed/UFc-RPbq8kg',
      width: 580,
      height: 320,
      caption: ''
    }
  },
{
    type: 'image',
    data: {
      file: {
        url: '/static/images/portfolio/portfolio-big-02.jpg',
        width: 600,
        height: 650
      },
      caption: '',
      withBorder: false,
      stretched: false,
      withBackground: false
    }
  },
  {
    type: 'image',
    data: {
      file: {
        url: '/static/images/portfolio/portfolio-big-02.jpg',
        width: 600,
        height: 650
      },
      caption: '',
      withBorder: false,
      stretched: false,
      withBackground: false
    }
  },
  {
    type: 'image',
    data: {
      file: {
        url: '/static/images/portfolio/portfolio-big-02.jpg',
        width: 600,
        height: 650
      },
      caption: '',
      withBorder: false,
      stretched: false,
      withBackground: false
    }
  },
]
export default template;
