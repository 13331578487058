import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'

export default class Galeria extends Component {
  constructor () {
    super()
    this.state = {
      open: false,
      openEdit: false
    }
  }
  handleClick = imagem =>
    this.setState({ imagemSelecionada: imagem, open: true })
  handleClose = () => this.setState({ open: false })
  render () {
    return (
      <div className='galeria'>
        <Dialog
          className='galeria-dialogo'
          open={this.state.open}
          onClose={this.handleClose}
        >
          {this.state.imagemSelecionada ? (
            <div className='imagem-dialogo'>
              <img
                src={`/static/images/about/galeria/${this.state.imagemSelecionada.nome}`}
                alt='Imagem da galeria'
              />
              <div className='detalhes'>
                <div className='conteudo'>
                  <h3>{this.state.imagemSelecionada.titulo}</h3>
                  <p>{this.state.imagemSelecionada.descricao}</p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </Dialog>

        {this.props.galeria.map((imagem, index) => (
          <div className='imagem' onClick={() => this.handleClick(imagem)}>
            <img
              className='grid'
              src={`/static/images/about/galeria/${imagem.nome}`}
              key={index}
              alt='Imagem da galeria sobre'
            />
            <div className='detalhes'>
              <div className='conteudo'>
                <h3>{imagem.titulo}</h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}
