import React from 'react'
import {
  MdSettings,
  MdClose,
  MdMenu,
  MdHome,
  MdViewCarousel,
  MdWork,
  MdGroup,
  MdFace,
  MdChromeReaderMode,
  MdBrush,
  MdPerson
} from 'react-icons/md'
import { ReactSVG } from 'react-svg'
export const icones = {
  Fachadas: (
    <ReactSVG className='icon' src='/static/images/Icones/svg/fachadas.svg'
      beforeInjection= {svg =>  {
        svg.setAttribute('style', 'width: 1em')
        svg.setAttribute('fill', 'currentColor')
        svg.setAttribute('stroke', 'currentColor')
        }}
     />
  ),
  Letra: (
    <ReactSVG className='icon' src='/static/images/Icones/svg/letra.svg'
      beforeInjection= {svg =>  {
        svg.setAttribute('style', 'width: 1em')
        svg.setAttribute('fill', 'currentColor')
        svg.setAttribute('stroke', 'currentColor')

        }}
     />
  ),
  Sinalizacao: (
    <ReactSVG className='icon' src='/static/images/Icones/svg/sinalizacao.svg'
      beforeInjection= {svg =>  {
        svg.setAttribute('style', 'width: 1em')
        svg.setAttribute('fill', 'currentColor')
        svg.setAttribute('stroke', 'currentColor')

        }}
     />
  ),
  Totten: (
    <ReactSVG className='icon' src='/static/images/Icones/svg/totten.svg'
      beforeInjection= {svg =>  {
        svg.setAttribute('style', 'width: 1em')
        svg.setAttribute('fill', 'currentColor')
        svg.setAttribute('stroke', 'currentColor')

        }}
     />
  ),
  Transporte: (
    <ReactSVG className='icon' src='/static/images/Icones/svg/transporte.svg'
      beforeInjection= {svg =>  {
        svg.setAttribute('style', 'width: 1em')
        svg.setAttribute('fill', 'currentColor')
        svg.setAttribute('stroke', 'currentColor')

        }}
     />
  ),
  Outdoors: (
    <ReactSVG className='icon' src='/static/images/Icones/svg/outdoors.svg'
      beforeInjection= {svg =>  {
        svg.setAttribute('style', 'width: 1em')
        svg.setAttribute('fill', 'currentColor')
        svg.setAttribute('stroke', 'currentColor')

        }}
     />
  ),

  MdSettings: <MdSettings />,
  MdClose: <MdClose />,
  MdMenu: <MdMenu />,
  MdHome: <MdHome />,
  MdViewCarousel: <MdViewCarousel />,
  MdWork: <MdWork />,
  MdGroup: <MdGroup />,
  MdFace: <MdFace />,
  MdChromeReaderMode: <MdChromeReaderMode />,
  MdBrush: <MdBrush />,
  MdPerson: <MdPerson />,
}

// Esse formato é estranho mas está na documentação dessa maneira, então ...
export let option = []
for (let key in icones) {
  option.push({
    label: key,
    value: key
  })
}
