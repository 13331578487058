import { readEditor, blogReadEditor } from '../readEditor'
import React from 'react'
export const ModeloBlogUm = data => {
  let formatacao = blogReadEditor(data)
  return (
    <>
      {data.readOnly ? (
        formatacao.map(v => <>{v.el}</>)
      ) : (
        <div className='rn-blog-details pt--110 pb--70 bg_color--1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner-wrapper'>
                  <div className='inner'>
                    {formatacao.map(v => (
                      <>{v.el}</>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export const ModeloBlogDois = data => {
  let { ...conteudo } = readEditor(data)
  let o = conteudo.textos.map(el => {
    if (el.texto.type.split('')[0] === 'h') {
      return React.cloneElement(el.texto, { className: 'title' })
    } else {
      return el.texto
    }
  })

  return (
    <>
      {data.readOnly ? (
        <div className='blog-single-list-wrapper d-flex flex-wrap mt--40 mb--40'>
          <div className='thumbnail'>
            {conteudo.media.map(m => (
              <img className='w-100' src={m.src} alt={m.caption} />
            ))}
          </div>

          <div className='content'>
            {o.map(v => (
              <>{v}</>
            ))}
          </div>
        </div>
      ) : (
        <div className='rn-blog-details pt--110 pb--70 bg_color--1'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner-wrapper'>
                  <div className='inner'>
                    <div className='blog-single-list-wrapper d-flex flex-wrap'>
                      <div className='thumbnail '>
                        {conteudo.media.map(m => (
                          <img  className='w-100' src={m.src} alt={m.caption} />
                        ))}
                      </div>

                      <div className='content'>
                        {o.map(v => (
                          <>{v}</>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
