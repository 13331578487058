import React, { Component, Fragment } from 'react'
import EditableText from '../EditableText'
import Bloco from './Bloco'
import { AiOutlineFileAdd } from 'react-icons/ai'
import { Input } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { MdInsertPhoto, MdSave } from 'react-icons/md'
import { get, upload, post } from '../../api'
import Modal from 'react-bootstrap/Modal'
import Paper from '@material-ui/core/Paper'
import Dropzone from './Dropzone'
import Fab from '@material-ui/core/Fab'

const template_blog = {
  blocos: [],
  componentesBlocos: [],
  levelBlocos: 0,
  cardImagem: 'card-modelo.jpg',
  breadcrumb: 'breadcrumb-modelo.jpg',
  titulo: 'Titulo',
  btn: 'Saiba Mais',
  descricao: 'Descricao',
  categoria: 'Categoria',
  slug: '',
  modalUm: false,
  modo_editar: false,
  proporcao: ''
}

class AddTrabalho extends Component {
  constructor (props) {
    super(props)
    this.state = {
      blocos: [],
      componentesBlocos: [],
      levelBlocos: 0,
      cardImagem: '',
      breadcrumb: '',
      btn: '',
      titulo: '',
      descricao: '',
      categoria: '',
      slug: '',
      modalUm: false,
      modo_editar: false,
      proporcao: '',
      galeria: []
    }
  }
  showModalUm = pr => {
    this.setState({ proporcao: pr, modalUm: true })
  }
  sluglization = titulo => {
    return titulo
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase()
  }
  handleBreadcrumb (event) {
    const formData = new FormData()
    formData.append('usuarioImagem', event.target.files[0])
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      if (img.width === 1920 && img.height === 900) {
        upload('trabalhos/upload_breadcrumb', formData)
          .then(data => {
            event.preventDefault()
            this.setState({ breadcrumb: data.upload_data.file_name })
          })
          .catch(err => console.log(err))
        return true
      }
      this.setState({ proporcao: '1920x900', modalUm: true })
      return false
    }
  }
  handleCard (event) {
    const formData = new FormData()
    formData.append('usuarioImagem', event.target.files[0])
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      if (img.width === 390 && img.height === 532) {
        upload('trabalhos/upload_card', formData)
          .then(data => {
            event.preventDefault()
            this.setState({ cardImagem: data.upload_data.file_name })
          })
          .catch(err => console.log(err))
        return true
      }
      this.setState({ proporcao: '390x532', modalUm: true })
      return false
    }
  }
  handleSave = newData => {
    let temp = this.state.blocos
    let novosBlocos = temp.filter(e => e.id !== newData.id)
    novosBlocos.push(newData)
    novosBlocos.sort((a, b) => a.id > b.id)
    this.setState({ blocos: novosBlocos })
  }
  deleteBlocos = id => {
    console.log(id)
    let cB = this.state.componentesBlocos.filter(
      bloco => parseInt(bloco.id) !== parseInt(id)
    )
    let b = this.state.blocos.filter(
      bloco => parseInt(bloco.id) !== parseInt(id)
    )
    this.setState({
      blocos: b,
      componentesBlocos: cB
    })
  }
  addBlocos (modelo, conteudo = null, id = null) {
    const templateBloco = {
      id: id || this.state.levelBlocos,
      bloco: (
        <Bloco
          delete={this.deleteBlocos}
          blocos={conteudo}
          handleSave={this.handleSave}
          modelo={modelo}
          levelBlocos={id || this.state.levelBlocos}
          showModal={this.showModalUm}
        />
      )
    }
    this.setState({
      componentesBlocos: this.state.componentesBlocos.concat(templateBloco),
      levelBlocos: templateBloco.id + 1
    })
  }
  componentDidMount () {
    const id_post = this.props.match.params.id
    if (id_post) {
      this.setState({ modo_editar: true })
      get(`trabalhos/index/${id_post}`).then(data => {
        this.setState(
          {
            galeria:
              JSON.parse(data.galeria) === null ? [] : JSON.parse(data.galeria)
          },
          () =>
            this.state.galeria.length > 0
              ? this.setState({ dropzone: true })
              : this.setState({ dropzone: false })
        )
        delete data.galeria
        this.setState(data)
        this.state.blocos.map(bloco =>
          this.addBlocos(
            bloco.modelo,
            { conteudo: bloco.conteudo },
            parseInt(bloco.id)
          )
        )
      })
    } else {
      this.setState(template_blog)
    }
  }

  salvar () {
    let data = { ...this.state }
    data.galeria = JSON.stringify(this.state.galeria)
    delete data.modo_editar
    delete data.modalUm
    delete data.proporcao
    delete data.componentesBlocos
    delete data.levelBlocos
    delete data.dropzone

    if (this.state.modo_editar) {
      post('trabalhos/editar', data)
        .then(d => {
          console.log(d)
          window.location.href = '/gdi/index/trabalhos'
        })
        .then()
    } else {
      post('trabalhos/salvar', data).then(
        response => (window.location.href = '/gdi/index/trabalhos')
      )
    }
  }
  render () {
    return (
      <>
        <>
          <Modal
            size='lg'
            show={this.state.modalUm}
            onHide={() => this.setState({ modalUm: false })}
            aria-labelledby='example-modal-sizes-title-sm'
          >
            <Modal.Header closeButton>
              <Modal.Title id='example-modal-sizes-title-sm'>
                Dimensões da imagem
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              As dimensões da imagem estão incorretas. As dimensões devem ser{' '}
              {this.state.proporcao}
            </Modal.Body>
          </Modal>
        </>
        <div className='container'>
          <div className='row mb--50'>
            <div className='col-lg-10'>
              <h3>Trabalhos</h3>
              <p>
                Aqui você pode adicionar os seus trabalhos e personalizar o card
                que irá aparecer na página inicial
              </p>
            </div>
          </div>
          <div className='row '>
            <div className='col-lg-4 col-sm-6'>
              <Paper className='mb--25' style={{ padding: '15px' }}>
                <h3>Card</h3>
                <div className='portfolio'>
                  <div className='thumbnail-inner'>
                    <div
                      style={{
                        backgroundImage: `url("/static/images/trabalhos/card/${this.state.cardImagem}`,
                        backgroundPosition: 'center center !important'
                      }}
                      className={`thumbnail`}
                    ></div>
                    <div
                      style={{
                        background: `url("/static/images/trabalhos/card/${this.state.cardImagem}`,
                        backgroundPosition: 'center center !important'
                      }}
                      className={`bg-blr-image`}
                    ></div>
                  </div>
                  <div className='content'>
                    <div className='inner'>
                      <p>
                        {this.state.categoria ? (
                          <EditableText
                            value={this.state.categoria}
                            onChange={e => this.setState({ categoria: e })}
                          />
                        ) : (
                          'Categoria'
                        )}
                      </p>
                      <h4>
                        <a>
                          {this.state.titulo ? (
                            <EditableText
                              value={this.state.titulo}
                              onChange={e => {
                                this.setState({ slug: this.sluglization(e) })
                                this.setState({ titulo: e })
                              }}
                            />
                          ) : (
                            'Titulo'
                          )}
                        </a>
                      </h4>
                      <div className='portfolio-button'>
                        <a className='rn-btn'>
                          {this.state.btn ? (
                            <EditableText
                              value={this.state.btn}
                              onChange={e => this.setState({ btn: e })}
                            />
                          ) : (
                            'Saiba Mais'
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='text-center'>
                  <input
                    accept='image/*'
                    id='card'
                    type='file'
                    style={{ display: 'none' }}
                    onChange={e => this.handleCard(e)}
                  />
                  <label style={{ cursor: 'pointer' }} htmlFor='card'>
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                    >
                      <MdInsertPhoto size={40} />
                    </IconButton>
                    <small> Adicionar Imagem (390x532)</small>
                  </label>
                </div>
              </Paper>
              {/* <input type='file' /> */}
            </div>
            <div className='col-lg-8'>
              <Paper className='mb--25' style={{ padding: '15px' }}>
                <h3>Breadcrumb</h3>
                <div
                  className='rn-page-title-area pt--120 pb--190 bg_image'
                  data-black-overlay='7'
                  style={{
                    backgroundImage: `url("/static/images/trabalhos/breadcrumb/${this.state.breadcrumb}`,
                    backgroundPosition: 'center center'
                  }}
                >
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <div className='rn-page-title text-center pt--100'>
                          <h2 className='title theme-gradient'>
                            {this.state.titulo}
                          </h2>
                          <div>
                            <p>
                              {this.state.descricao ? (
                                <EditableText
                                  value={this.state.descricao}
                                  onChange={e =>
                                    this.setState({ descricao: e })
                                  }
                                />
                              ) : (
                                'Descricao'
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='text-center'>
                  <input
                    accept='image/*'
                    id='breadcrumb'
                    type='file'
                    style={{ display: 'none' }}
                    onChange={e => this.handleBreadcrumb(e)}
                  />
                  <label style={{ cursor: 'pointer' }} htmlFor='breadcrumb'>
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                    >
                      <MdInsertPhoto size={40} />
                    </IconButton>
                    <small> Adicionar Imagem (1920x900)</small>
                  </label>
                </div>
              </Paper>
            </div>
          </div>

          <div className='row mt--50'>
            <div col-lg-12>
              <Paper className='mb--25' style={{ padding: '15px' }}>
                <h3>Blocos</h3>
                <p>
                  Os blocos iram aparecer dentro da página detalhes do seu
                  serviço, você pode adicionar quantos blocos achar necessário,
                  basta escolher os blocos existentes para a página atual.
                </p>
                <div className='rn-portfolio-details ptb--120 bg_color--1'>
                  <div className='container'>
                    <div className='portfolio-details w-100'>
                      <div id='areaDosBlocos w-100'>
                        {this.state.componentesBlocos.map((bloco, i) => (
                          <Fragment key={i}>{bloco.bloco}</Fragment>
                        ))}
                        {this.state.dropzone ? (
                          <>
                            {this.state.galeria.map((imagem, index) => (
                              <div className='portfolio-thumb-inner'>
                                <div className='thumb position-relative mb--30'>
                                  <img
                                    key={index}
                                    src={`/static/images/trabalhos/${imagem.nome}`}
                                    alt={imagem.alt}
                                  />
                                </div>
                              </div>
                            ))}

                            <Dropzone
                              galeria={this.state.galeria}
                              width={1230}
                              height={850}
                              url='/trabalhos/upload_modelo_tres'
                              path='/static/images/trabalhos'
                              setImage={imgs =>
                                this.setState({ galeria: imgs })
                              }
                            />
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-4'>
                    <button
                      style={{ width: '100%' }}
                      className='btn btn-primary text-lg-center'
                      onClick={() => this.addBlocos('trabalho-modelo-um')}
                    >
                      <AiOutlineFileAdd /> Adicionar titulo e textos
                    </button>
                  </div>
                  <div className='col-lg-4'>
                    <button
                      style={{ width: '100%' }}
                      className='btn btn-danger text-lg-center'
                      onClick={() => this.addBlocos('trabalho-modelo-dois')}
                    >
                      <AiOutlineFileAdd /> Adicionar descrições e redes sociais
                    </button>
                  </div>
                  <div className='col-lg-4'>
                    <button
                      style={{ width: '100%' }}
                      className='btn btn-success text-lg-center'
                      onClick={() =>
                        this.setState({ dropzone: !this.state.dropzone })
                      }
                    >
                      <AiOutlineFileAdd />{' '}
                      {this.state.dropzone ? `Remover` : 'Adicionar'} Galeria
                    </button>
                  </div>
                </div>
              </Paper>
            </div>
          </div>
          <div className='ml-auto d-flex justify-content-end mt--50 mb--25'>
            <Fab
              onClick={() => this.salvar()}
              color='primary'
              style={{ position: 'fixed' }}
            >
              <MdSave size={30} />
            </Fab>
          </div>
        </div>
      </>
    )
  }
}
export default AddTrabalho
