const template = [
  {
    type: 'paragraph',
    data: {
      text:
        "but the majority"
    }
  },
  {
    type: 'header',
    data: {
      level: 4,
      text: 'Proceess '
    }
  },
  {
    type: 'paragraph',
    data: {
      text:
        "but the majority"
    }
  },
  {
    type: 'header',
    data: {
      level: 4,
      text: 'Proceess '
    }
  },
  {
    type: 'paragraph',
    data: {
      text:
        "but the majority"
    }
  },
  {
    type: 'header',
    data: {
      level: 4,
      text: 'Proceess '
    }
  },
]

export default template
