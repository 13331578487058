import React, { Component } from 'react'
import PageHelmet from '../components/common/Helmet'
import { FiHeadphones, FiMail, FiMapPin } from 'react-icons/fi'
import {MdPlace} from 'react-icons/md'
import GoogleMapReact from 'google-map-react'
import ContactTwo from '../elements/contact/ContactTwo'
import LoadingScreen from 'react-loading-screen'
import { get } from '../api'

const AnyReactComponent = ({ text }) => <div>{text}</div>

class Contact extends Component {
  static defaultProps = {
    center: {
      lat: -29.221304,
      lng: -51.341053
    },
    zoom: 18
  }
  constructor (props) {
    super(props)
    this.state = {
      contato: {},
      loading: true,
      apiKey: ''
    }
  }
  componentDidMount () {
    this.props.setCurrent('#contato')
    get('contato/index').then(data => {
      this.setState({ contato: data[0] })
      get('gerenciamento/index').then(data =>
        this.setState({ apiKey: data.maps })
      )
      this.setState({ loading: false })
    })
  }

  render () {
    return (
      <React.Fragment>
        <LoadingScreen
          loading={this.state.loading}
          bgColor='#f1f1f1'
          spinnerColor='#9ee5f8'
          textColor='#676767'
        >
          <PageHelmet pageTitle='Contato' />
          {/* Start Breadcrump Area */}
          <div
            className='rn-page-title-area pt--120 pb--190 bg_image'
            style={{
              backgroundImage: `url("/static/images/contatos/breadcrumb/${this.state.contato.breadcrumbImagem}")`
            }}
            data-black-overlay='6'
          >
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='rn-page-title text-center pt--100'>
                    <h2 className='title theme-gradient'>
                      {this.state.contato.breadcrumbTitulo}
                    </h2>
                    <p>{this.state.contato.breadcrumbDescricao}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrump Area */}
          {/* Start Contact Top Area  */}
          <div className='rn-contact-top-area ptb--120 bg_color--5'>
            <div className='container'>
              <div className='row'>
                {/* Start Single Address  */}
                <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                  <div className='rn-address'>
                    <div className='icon'>
                      <FiHeadphones />
                    </div>
                    <div className='inner'>
                      <h4 className='title'>
                        {this.state.contato.tituloTelefone}
                      </h4>
                      <p>
                        <a href={`tel:${this.state.contato.telefone1}`}>
                          {this.state.contato.telefone1}
                        </a>
                      </p>
                      <p>
                        <a href={`tel:${this.state.contato.telefone2}`}>
                          {this.state.contato.telefone2}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}

                {/* Start Single Address  */}
                <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50'>
                  <div className='rn-address'>
                    <div className='icon'>
                      <FiMail />
                    </div>
                    <div className='inner'>
                      <h4 className='title'>
                        {this.state.contato.tituloEmail}
                      </h4>
                      <p>
                        <a href={`mailto:${this.state.contato.email1}`}>
                          {this.state.contato.email1}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}

                {/* Start Single Address  */}
                <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50'>
                  <div className='rn-address'>
                    <div className='icon'>
                      <FiMapPin />
                    </div>
                    <div className='inner'>
                      <h4 className='title'>
                        {this.state.contato.Localizacao}
                      </h4>
                      <p>{this.state.contato.endereco}</p>
                    </div>
                  </div>
                </div>
                {/* End Single Address  */}
              </div>
            </div>
          </div>
          {/* End Contact Top Area  */}
          {/* Start Contact Page Area  */}
          <div className='rn-contact-page ptb--120 bg_color--1'>
            <ContactTwo />
          </div>
          {/* End Contact Page Area  */}
          {/* Start Contact Map  */}
          <div className='rn-contact-map-area position-relative'>
            <div style={{ height: '650px', width: '100%' }}>
              {this.state.apiKey ? (
                <GoogleMapReact
                  bootstrapURLKeys={{ key: this.state.apiKey }}
                  defaultCenter={this.props.center}
                  defaultZoom={this.props.zoom}
                >
                  <AnyReactComponent
                    lat={-29.221304}
                    lng={-51.341053}
                    text={<MdPlace size={40} color={'red'}/>}
                  />
                </GoogleMapReact>
              ) : (
                ''
              )}
            </div>
          </div>
          {/* End Contact Map  */}
        </LoadingScreen>
      </React.Fragment>
    )
  }
}
export default Contact
