import React, { Component } from 'react'
import EditableText from '../EditableText'
import DateFnsUtils from '@date-io/date-fns'
import InputLabel from '@material-ui/core/InputLabel'
import { Input } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { MdInsertPhoto, MdSave } from 'react-icons/md'
import { get, upload, post } from '../../api'
import brLocale from 'date-fns/locale/pt-BR'
import Modal from 'react-bootstrap/Modal'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import Bloco from './Bloco'

const templateBlog = {
  autor: 'Nome do Autor',
  titulo: 'Titulo',
  categoria: 'Categoria',
  breadcrumb: 'breadcrumb-modelo.jpg',
  cardImagem: 'blog-card-modelo.jpg',
  blocos: [],
  date: new Date(),
  slug: ''
}
class AddBlog extends Component {
  constructor (props) {
    super()
    this.state = {
      autor: '',
      titulo: '',
      categoria: '',
      breadcrumb: '',
      cardImagem: '',
      blocos: [],
      date: new Date(),
      slug: '',

      componentesBlocos: [],
      levelBlocos: 0,
      modalUm: false,
      proporcao: ''
    }
  }
  showModalUm = pr => {
    this.setState({ proporcao: pr, modalUm: true })
  }
  sluglization = titulo => {
    return titulo
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase()
  }

  handleChange (novoValor) {
    this.setState({ Autor: novoValor })
  }
  handleChangeImage (event) {
    const formData = new FormData()
    formData.append('usuarioImagem', event.target.files[0])
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      if (img.width === 1921 && img.height === 683) {
        upload('blog/upload_breadcrumb', formData)
          .then(data => {
            event.preventDefault()
            this.setState({ breadcrumb: data.upload_data.file_name })
          })
          .catch(err => console.log(err))
        return true
      }
      this.setState({ proporcao: '1921x683', modalUm: true })
      return false
    }
  }
  handleChangeImageCard (event) {
    const formData = new FormData()
    formData.append('usuarioImagem', event.target.files[0])
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      if (img.width === 390 && img.height === 532) {
        upload('blog/upload_card', formData)
          .then(data => {
            event.preventDefault()
            this.setState({ cardImagem: data.upload_data.file_name })
          })
          .catch(err => console.log(err))
        return true
      }
      this.setState({ proporcao: '390x532', modalUm: true })
      return false
    }
  }
  addBloco (modelo, conteudo = null, id=null) {
    const templateBloco = {
      id: id || this.state.levelBlocos,
      bloco: (
        <Bloco
          blocos={conteudo}
          modelo={modelo}
          levelBlocos={id || this.state.levelBlocos}
          delete={this.deleteBlocos}
          handleSave={this.handleSave}
          showModal={this.showModalUm}
        />
      )
    }
    this.setState({
      componentesBlocos: this.state.componentesBlocos.concat(templateBloco),
      levelBlocos: templateBloco.id + 1
    })
  }
  handleSave = newData => {
    let temp = this.state.blocos
    let novosBlocos = temp.filter(e => e.id !== newData.id)
    novosBlocos.push(newData)
    novosBlocos.sort((a, b) => a.id > b.id)
    this.setState({ blocos: novosBlocos })
  }
  deleteBlocos = id => {
    let cB = this.state.componentesBlocos.filter(bloco => bloco.id !== id)
    let b = this.state.blocos.filter(
      bloco => parseInt(bloco.id) !== parseInt(id)
    )
    this.setState({
      blocos: b,
      componentesBlocos: cB
    })
  }
  salvar () {
    this.setState({ slug: this.sluglization(this.state.titulo) })

    let data = { ...this.state }
    delete data.componentesBlocos
    delete data.levelBlocos
    delete data.modalUm
    delete data.proporcao
    delete data.modo_editar
    if (this.state.modo_editar) {
      post('blog/editar', data).then(response => window.location.href = '/gdi/index/blog')
    } else {
      post('blog/salvar', data).then(response =>window.location.href = '/gdi/index/blog')
    }
  }
  componentDidMount () {
    const id_post = this.props.match.params.id
    if (id_post) {
      get(`blog/index/${id_post}`).then(data => {
        this.setState({ modo_editar: true })
        const temp = { ...data }
        this.setState({ date: new Date(temp.date) })
        delete temp.date
        this.setState(temp)
        this.state.blocos.map(bloco =>
          this.addBloco(bloco.modelo, { conteudo: bloco.conteudo })
        )
      })
    } else {
      this.setState(templateBlog)
    }
  }

  render () {
    return (
      <>
        <>
          <Modal
            size='lg'
            show={this.state.modalUm}
            onHide={() => this.setState({ modalUm: false })}
            aria-labelledby='example-modal-sizes-title-sm'
          >
            <Modal.Header closeButton>
              <Modal.Title id='example-modal-sizes-title-sm'>
                Dimensões da imagem
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              As dimensões da imagem estão incorretas. As dimensões devem ser{' '}
              {this.state.proporcao}
            </Modal.Body>
          </Modal>
        </>

        <div className='container'>
          <div className='row'>
            <div className='col-lg-10'>
              <h3>Adicionar Post para o blog</h3>
              <p>
                Aqui você pode personalizar os cards com a apresentação da sua postagem e seu post para o blog, com textos, imagens, listas e citações.
              </p>
            </div>
          </div>
          <div className='row mt--50'>
            <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
              <Paper className='mb--25' style={{ padding: '15px' }}>
                <h3 className="text-center">Card</h3>
                <div className='blog blog-style--1 text-center'>
                  <div className='thumbnail'>
                    <a href='/blog-details'>
                      <img
                        className='w-100'
                        src={`/static/images/blog/card/${this.state.cardImagem}`}
                        alt='Blog Images'
                      />
                    </a>
                  </div>
                  <div className='content'>
                    <p className='blogtype'>
                      {this.state.categoria ? (
                        <EditableText
                          value={this.state.categoria}
                          onChange={e => this.setState({ categoria: e })}
                        />
                      ) : (
                        ''
                      )}
                    </p>
                    <h4 className='title'>
                      <a>
                        {this.state.titulo ? (
                          <EditableText
                            value={this.state.titulo}
                            onChange={e => {
                              this.setState({ titulo: e })
                              this.setState({ slug: this.sluglization(e) })
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </a>
                    </h4>
                    <div className='blog-btn'>
                      <a className='rn-btn text-white'>Saiba Mais</a>
                    </div>
                  </div>
                </div>
                <div className='text-center'>
                  <input
                    accept='image/*'
                    id='imageUm'
                    type='file'
                    style={{ display: 'none' }}
                    onChange={e => this.handleChangeImageCard(e)}
                  />
                  <label style={{ cursor: 'pointer' }} htmlFor='imageUm'>
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                    >
                      <MdInsertPhoto size={40} />
                    </IconButton>
                    <small> Adicionar Imagem (390x532) </small>
                  </label>
                </div>
              </Paper>
            </div>

            {/* </div> */}
            {/* <div className='row'> */}
            <div className='col-lg-8 '>
              <Paper className='mb--25' style={{ padding: '15px' }}>
                <h3 className="text-center">BreadCrumb da página</h3>
                <>
                  <div
                    className='rn-page-title-area pt--120 pb--190 bg_image'
                    data-black-overlay='7'
                    style={{
                      backgroundImage: `url("/static/images/blog/breadcrumb/${this.state.breadcrumb}")`
                    }}
                  >
                    <div className='container'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='blog-single-page-title text-center pt--100'>
                            <h2 className='title theme-gradient'>
                              {this.state.titulo}
                            </h2>
                            <ul className='blog-meta d-flex justify-content-center align-items-center'>
                              {!this.state.date ? (
                                ` `
                              ) : (
                                <li>
                                  {this.state.date.getMonth() < 9
                                    ? `0${this.state.date.getMonth() + 1}`
                                    : this.state.date.getMonth() + 1}
                                  {`/${this.state.date.getFullYear()}`}
                                </li>
                              )}
                              <li>{this.state.autor}</li>
                              <li>{this.state.categoria}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-center'>
                    <input
                      accept='image/*'
                      id='imageDois'
                      type='file'
                      style={{ display: 'none' }}
                      onChange={e => this.handleChangeImage(e)}
                    />
                    <label style={{ cursor: 'pointer' }} htmlFor='imageDois'>
                      <IconButton
                        color='primary'
                        aria-label='upload picture'
                        component='span'
                      >
                        <MdInsertPhoto size={40} />
                      </IconButton>
                      <small> Adicionar Imagem (1921x683) </small>
                    </label>
                  </div>
                </>
              </Paper>
            </div>
          </div>
          {/* Detalhes do artigo */}
          <div className='container mt--50'>
            <Paper className='mb--25' style={{ padding: ' 30px 15px' }}>
              <h3 className='text-center'>Informações sobre a publicação</h3>
              <div className='row'>
                <div className='col-lg-4'>
                  <MuiPickersUtilsProvider
                    className='col-lg-4'
                    utils={DateFnsUtils}
                    locale={brLocale}
                  >
                    <KeyboardDatePicker
                      className='w-100'
                      disableToolbar
                      variant='inline'
                      format='MM/yyyy'
                      margin='normal'
                      id='date-picker-inline'
                      label='Mes da Publicação'
                      onChange={e => this.setState({ date: e })}
                      value={this.state.date}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className='col-lg-4'>
                  <InputLabel className='mt--10' id='demo-simple-select-label'>
                    Autor
                  </InputLabel>
                  <Input
                    type='text'
                    placeholder='Nome'
                    autoFocus='true'
                    value={this.state.autor}
                    onChange={e => this.setState({ autor: e.target.value })}
                    fullWidth
                  />
                </div>
                <div className='col-lg-4'>
                  <InputLabel className='mt--10' id='demo-simple-select-label'>
                    Categoria
                  </InputLabel>

                  <Input
                    readOnly
                    type='text'
                    placeholder='Categoria'
                    disabled
                    value={this.state.categoria}
                    onChange={e => this.setState({ categoria: e.target.value })}
                    fullWidth
                  />
                </div>
              </div>
            </Paper>
          </div>
          <Paper className='mb--25' style={{ padding: '15px' }}>
            <div className='container mt--50'>

              <div className='row'>
                <div className='col-lg-12'>
                  <h3>Corpo do artigo</h3>
                  <p>
                    Você pode personalizar a sua postagem para o blog com as informações que quiser e aplicar os modelos disponíveis para seu site.
                  </p>
                </div>
              </div>

              {/* Blocos de edição */}
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='areaDosBlocos'>
                    {this.state.componentesBlocos.map((bloco, i) => (
                      <div key={i}>{bloco.bloco}</div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Actions dos blocos */}
              <div className='row mt--50'>
                <div className='col-lg-6'>
                  {
                    <button
                      className='btn btn-danger w-100 text-center'
                      onClick={() => this.addBloco('blog-modelo-um')}
                    >
                      Criar
                    </button>
                  }
                </div>
                <div className='col-lg-6'>
                  {
                    <button
                      className='btn btn-primary w-100 text-center'
                      onClick={() => this.addBloco('blog-modelo-dois')}
                    >
                      Criar
                    </button>
                  }
                </div>
              </div>

            </div>
          </Paper>

          <div className='row justify-content-end mt--25 mb--25'>
            <Fab
              onClick={() => this.salvar()}
              color='primary'
              style={{position: 'fixed'}}
            >
              <MdSave size={30} />
            </Fab>
          </div>
        </div>
      </>
    )
  }
}
export default AddBlog
