import React, { Component } from 'react'
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa'
import EditableText from '../EditableText'
import IconButton from '@material-ui/core/IconButton'
import { MdInsertPhoto, MdSave } from 'react-icons/md'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Modal from 'react-bootstrap/Modal'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab';
import { get, post, upload } from '../../api'

const templateState = {
  imagem: 'modelo.jpg',
  nome: 'Nome',
  cargo: 'Cargo',
  facebook: '',
  instagram: '',
  twitter: ''
}

class AddEquipe extends Component {
  constructor (props) {
    super()
    this.state = {
      modalUm: false,
      modo_editar: false,
      id: null,
      imagem: '',
      nome: '',
      cargo: '',
      facebook: '',
      instagram: '',
      twitter: ''
    }
  }
  handleChangeImage (event) {
    const formData = new FormData()
    formData.append('usuarioImagem', event.target.files[0])
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      if (img.width === 500 && img.height === 665) {
        upload('equipe/upload_image', formData)
          .then(data => {
            event.preventDefault()
            console.log(data)
            this.setState({ imagem: data.upload_data.file_name })
          })
          .catch(err => console.log(err))
        return true
      }
      this.setState({ modalUm: true })
      return false
    }
  }
  salvar () {
    let data = { ...this.state }
    delete data.modalUm
    delete data.modo_editar

    if (this.state.modo_editar) {
      post('equipe/editar', data).then(r => window.location.href = '/gdi/index/equipe')
    } else {
      post('equipe/salvar', data).then(r => window.location.href = '/gdi/index/equipe')
    }
  }
  componentDidMount () {
    const id_equipe = this.props.match.params.id
    if (id_equipe) {
      get(`equipe/index/${id_equipe}`).then(data => this.setState(data))
      this.setState({ modo_editar: true })
    } else {
      this.setState(templateState)
    }
  }

  render () {
    return (
      <>
        <>
          <Modal
            size='lg'
            show={this.state.modalUm}
            onHide={() => this.setState({ modalUm: false })}
            aria-labelledby='example-modal-sizes-title-sm'
          >
            <Modal.Header closeButton>
              <Modal.Title id='example-modal-sizes-title-sm'>
                Dimensões da imagem
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              As dimensões da imagem estão incorretas. As dimensões devem ser
              500x665
            </Modal.Body>
          </Modal>
        </>
        <div className='container'>
        <div className="row mb--25">
        <div className="col-lg-10">
  <h3>Adicionar membro da empresa</h3>
  <p>Adicione os membros da sua equipe e adicione informações importantes sobre eles.</p>
        </div>
        </div>

          <div className='row'>
            <div className='col-lg-4'>
              <Paper className='mb--25' style={{ padding: '15px' }}>
                <h3 className='text-center'>Card</h3>
                <div className='team'>
                  <div className='thumbnail'>
                    <img
                      src={`/static/images/equipe/${this.state.imagem}`}
                      alt='Blog Images'
                    />
                  </div>
                  <div className='content'>
                    <h4 className='title'>
                      {this.state.nome ? (
                        <EditableText
                          value={this.state.nome}
                          onChange={e => this.setState({ nome: e })}
                        />
                      ) : (
                        ''
                      )}
                    </h4>
                    <p className='designation'>
                      {this.state.cargo ? (
                        <EditableText
                          value={this.state.cargo}
                          onChange={e => this.setState({ cargo: e })}
                        />
                      ) : (
                        ''
                      )}
                    </p>
                  </div>

                  <ul className='social-icon'>
                    <li>
                      <a>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a>
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a>
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className='text-center'>
                  <input
                    accept='image/*'
                    id='icon-button-file'
                    type='file'
                    style={{ display: 'none' }}
                    onChange={event => this.handleChangeImage(event)}
                  />
                  <label
                    style={{ cursor: 'pointer' }}
                    htmlFor='icon-button-file'
                  >
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                    >
                      <MdInsertPhoto size={40} />
                    </IconButton>
                    <span className='font--12'>
                      Adicionar imagem (500 x 665)
                    </span>
                  </label>
                </div>
              </Paper>
            </div>
            <div className='col-lg-8 '>
              <Paper className='mb--25 ml-auto mr-auto d-flex flex-column justify-content-between'  style={{ padding: '15px', paddingTop:'50px' }}>
                <div>
                  <h3>Redes Sociais</h3>
                  <p>
                    Adicionar redes sociais que iram aparecer na pagina inicial,
                    na area relacionada à equipe
                  </p>
                </div>
                <div className='mb--100 ml-auto mr-auto'>
                  <Grid container spacing={1} alignItems='flex-end'>
                    <Grid item>
                      <FaFacebookF />
                    </Grid>
                    <Grid item>
                      <TextField
                        id='input-with-icon-grid'
                        label='/usuario_facebook'
                        value={this.state.facebook}
                        onChange={e =>
                          this.setState({ facebook: e.target.value })
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} alignItems='flex-end'>
                    <Grid item>
                      <FaTwitter />
                    </Grid>
                    <Grid item>
                      <TextField
                        id='input-with-icon-grid'
                        value={this.state.twitter}
                        label='usuario_twitter'
                        onChange={e =>
                          this.setState({ twitter: e.target.value })
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} alignItems='flex-end'>
                    <Grid item>
                      <FaInstagram />
                    </Grid>
                    <Grid item>
                      <TextField
                        id='input-with-icon-grid'
                        label='usuario_instagram'
                        value={this.state.instagram}
                        onChange={e =>
                          this.setState({ instagram: e.target.value })
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </Paper>

            </div>
          </div>
          <div className="row  justify-content-end">
              <Fab
              onClick={() => this.salvar()}
              color='primary'
              style={{position: 'fixed'}}
            >
              <MdSave size={30} />
            </Fab>
          </div>
        </div>
      </>
    )
  }
}
export default AddEquipe
