import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { MdInsertPhoto, MdSave } from 'react-icons/md'
import { Input } from '@material-ui/core'
import {get, upload, post} from '../../api'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab';
class AddParceiro extends Component {
  constructor (props) {
    super()
    this.state = {
      nome: '',
      modo_editar: false,
      logo: 'parceiro_modelo.jpg'
    }
  }
  handleChangeImage (event) {
    const formData = new FormData()
    formData.append('usuarioImagem', event.target.files[0])
    upload('parceiros/upload_image', formData)
          .then(data => {
            event.preventDefault()
            this.setState({ logo: data.upload_data.file_name })
          })
          .catch(err => console.log(err))
  }
  salvar(){
    let data = {...this.state}
    delete data.modo_editar
    if(this.state.modo_editar){
      post('parceiros/editar', data)
        .then(r => window.location.href = '/gdi/index/parceiros')
    }else{
      post('parceiros/salvar', data)
        .then(r => window.location.href = '/gdi/index/parceiros')
    }
  }
  componentDidMount() {
    const id_parceiro = this.props.match.params.id;
    if(id_parceiro) {
      this.setState({modo_editar: true})
      get(`parceiros/index/${id_parceiro}`)
        .then(data => this.setState(data))
      };


  }

  render () {
    return (
      <>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>

              <h3>Adicionar Parceiro</h3>
            </div>
          </div>
          <div className='row mt--25'>
            <div className='col-lg-6 text-center'>
            <Paper className='mb--25' style={{ padding: '15px' }}>
              <h5>Logo do Parceiro</h5>
              <ul className='brand-style-2'>
                <li>
                  <img
                    src={`/static/images/parceiros/${this.state.logo}`}
                    alt='Logo Images'
                  />
                </li>
              </ul>
              <div className='text-center'>
                <input
                  accept='image/*'
                  id='imageDois'
                  type='file'
                  style={{ display: 'none' }}
                  onChange={e => this.handleChangeImage(e)}
                />
                <label style={{ cursor: 'pointer' }} htmlFor='imageDois'>
                  <IconButton
                    color='primary'
                    aria-label='upload picture'
                    component='span'
                  >
                    <MdInsertPhoto size={40} />
                  </IconButton>
                  <small> Adicionar Imagem + </small>
                </label>
              </div>
              </Paper>
            </div>
            <div className='col-lg-6 text-center'>
            <Paper className='mb--25' style={{ padding: '15px' }}>
              <h5>Nome do Parceiro</h5>
              <div className='d-flex flex-grow-1 justify-content-center align-items-center h-50'>
                <Input type='text' placeholder='Nome' autoFocus='true' value={this.state.nome}
                  onChange={(e) => this.setState({nome: e.target.value})}
                />
              </div>
              </Paper>
            </div>

          </div>
          <div className='row mt--50'>
            <div className='col-lg-12'>
              <div className='ml-auto d-flex justify-content-end'>
                <Fab
              onClick={() => this.salvar()}
              color='primary'
              style={{position: 'fixed'}}
            >
              <MdSave size={30} />
            </Fab>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default AddParceiro
