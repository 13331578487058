import React, { Component } from 'react'
import PageHelmet from '../components/common/Helmet'
import Breadcrumb from '../elements/common/Breadcrumb'
import Pagination from '../elements/common/Pagination'
import BlogList from '../elements/blog/BlogList'

import LoadingScreen from 'react-loading-screen'
class Blog extends Component {
  constructor () {
    super()
    this.state = { loading:true}
  }
  componentDidMount () {
    this.props.setCurrent("#blog")
    window.scrollTo(0, 0)
    setTimeout(() => {
      this.setState({ loading: false })
    }, 500)
  }

  render () {
    const { descricao_blog, titulo_blog, breadcrumb_blog, descricao_breadcrumb_blog } = this.props.Estatico
    return (
      <React.Fragment>
        <LoadingScreen
          loading={this.state.loading}
          bgColor='#f1f1f1'
          spinnerColor='#9ee5f8'
          textColor='#676767'
        >
          <>
            <PageHelmet
              pageTitle={titulo_blog || 'blog'}
              description={descricao_blog || 'descrição'}
            />
          </>

          {/* Start Breadcrump Area */}
          <Breadcrumb title={breadcrumb_blog} description={descricao_breadcrumb_blog} />
          {/* End Breadcrump Area */}

          {/* Start Blog Area */}
          <div className='rn-blog-area ptb--120 bg_color--1'>
            <div className='container'>
              <BlogList />
              <div className='row mt--20'>
                <div className='col-lg-12'>
                  {/* Start Pagination Area */}
                  {/* <Pagination /> */}
                  {/* End Pagination Area */}
                </div>
              </div>
            </div>
          </div>
          {/* End Blog Area */}
        </LoadingScreen>
      </React.Fragment>
    )
  }
}
export default Blog
