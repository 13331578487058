const template = [
  {
    type: 'image',
    data: {
      file: {
        url: '/static/images/service/service-01.png',
        width: 600,
        height: 650
      },
      caption: '',
      withBorder: false,
      stretched: false,
      withBackground: false
    }
  },

  {
    type: 'paragraph',
    data: {
      text:
        "but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing"
    }
  },
  {
    type: 'paragraph',
    data: {
      text:
        'hidden in the middle of text. All the Lorem Ipsum generators tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet.'
    }
  },
  {
    type: 'header',
    data: {
      level: 4,
      text: 'Proceess of metel'
    }
  },
  {
    type: 'list',
    data: {
      style: 'ordered',
      items: [
        'Goldfinch realistic sporadic ingenuous',
        'Abominable this abidin far successfully then like piquan',
        'Risus commodo viverra',
        'Lorem ipsum dolor sit amet, consectetur adipiscing'
      ]
    }
  }
]

export default template
