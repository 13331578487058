import React, { Component } from 'react'
import EditableText from '../EditableText'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Button from '@material-ui/core/Button'
import { MdInsertPhoto, MdSave } from 'react-icons/md'
import { MdExpandMore } from 'react-icons/md'
import IconButton from '@material-ui/core/IconButton'
import { get, upload, post } from '../../api'
import Fab from '@material-ui/core/Fab';
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn
} from 'react-icons/fa'
const classes = {
  root: {
    width: '100%'
  },
  heading: {
    fontSize: '15px',
    flexBasis: '33.33%',
    flexShrink: 0,
    justifyContent: 'space-between'
  },
  secondaryHeading: {
    fontSize: '15px',
    color: 'grey'
  }
}

const SocialShare = [
  { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
  { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
  { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
  { Social: <FaTwitter />, link: 'https://twitter.com/' }
]
class AddStatico extends Component {
  constructor () {
    super()
    this.state = {
      expanded: '',
      logo: '',
      titulo_equipe: '',
      footerDois : '',
      footerUm: '',
      descricao_equipe:
        '',

      titulo_trabalhos: '',
      descricao_trabalhos:
        '',

      titulo_blog: 'Blog',
      descricao_blog:'',

      titulo_servicos: '',
      descricao_servicos:
        '',
      footer_descricao: ''
    }
  }
  handleChange = panel => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false })
  }
  handleLogo (event) {
    const formData = new FormData()
    formData.append('usuarioImagem', event.target.files[0])

    upload('estatico/upload_logo', formData)
      .then(data => {
        event.preventDefault()
        this.setState({ logo: data.upload_data.file_name })
      })
      .catch(err => console.log(err))
  }
  handleFooterUm (event) {
    const formData = new FormData()
    formData.append('usuarioImagem', event.target.files[0])

    upload('estatico/upload_footer_um', formData).then(data => {
      event.preventDefault()
      this.setState({ footerUm: data.upload_data.file_name })
    })
      .catch(err => console.log(err))
  }
  handleFooterDois (event) {
    const formData = new FormData()
    formData.append('usuarioImagem', event.target.files[0])

    upload('estatico/upload_footer_dois', formData).then(data => {
      event.preventDefault()
      this.setState({ footerDois: data.upload_data.file_name })
    })
      .catch(err => console.log(err))
  }
  salvar(){
      let data = {...this.state}
      delete data.expanded
      delete data.modo_editar
      post('estatico/editar', data)
        .then(data => console.log(data))
        .catch(err => console.log(err))
  }
  componentDidMount() {
    get('estatico/index').then(data => this.setState(data[0]))
  }

  render () {
    return (
      <>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h3>Adicionar Conteudo Estático</h3>
              <p>Titulos e descrições que iram aparecer nas paginas do site</p>
            </div>
          </div>
          <ExpansionPanel
            className='mt--25'
            expanded={this.state.expanded === 'panel0'}
            onChange={this.handleChange('panel0')}>
            <ExpansionPanelSummary
              expandIcon={<MdExpandMore />}
              aria-controls='panel0bh-content'
              id='panel1bh-header'
            >
              <div>
                <p>Logo</p>
              </div>
              <div className='ml-auto '>
                <p className='font--11 color-4'>Adicionar Logo do site</p>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className='row justify-content-center w-100'>
                <div className='col-lg-4'>
                  <div className='logo'>
                    <a>
                      <img
                        className='logo-2'
                        src={`/static/images/logo/${this.state.logo}`}
                        alt='Logo Images'
                      />
                    </a>
                  </div>
                </div>
                <div class-lg-8>
                  <div className='text-center'>
                    <input
                      accept='image/*'
                      id='card'
                      type='file'
                      style={{ display: 'none' }}
                      onChange={e => this.handleLogo(e)}
                    />
                    <label style={{ cursor: 'pointer' }} htmlFor='card'>
                      <IconButton
                        color='primary'
                        aria-label='upload picture'
                        component='span'
                      >
                        <MdInsertPhoto size={40} />
                      </IconButton>
                      <br />
                      <small> + Logo </small>
                    </label>
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            expanded={this.state.expanded === 'panel1'}
            onChange={this.handleChange('panel1')}>
            <ExpansionPanelSummary
              expandIcon={<MdExpandMore />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <div>
                <p>Trabalhos</p>
              </div>
              <div className='ml-auto '>
                <p className='font--11 color-4'>
                  Personalizar os textos dos trabalhos
                </p>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='section-title service-style--3 text-left mb--15 mb_sm--0'>
                    {this.state.titulo_trabalhos ? (
                      <h2 className='title'>
                        <EditableText
                          value={this.state.titulo_trabalhos}
                          onChange={e => this.setState({ titulo_trabalhos: e })}
                        />
                      </h2>
                    ) : (
                      'Equipe'
                    )}

                    <p>
                      {this.state.descricao_trabalhos ? (
                        <EditableText
                          value={this.state.descricao_trabalhos}
                          onChange={e =>
                            this.setState({ descricao_trabalhos: e })
                          }
                        />
                      ) : (
                        'Equipe'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            expanded={this.state.expanded === 'panel2'}
            onChange={this.handleChange('panel2')}>
            <ExpansionPanelSummary
              expandIcon={<MdExpandMore />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <div>
                <p>Breadcrumb Trabalhos</p>
              </div>
              <div className='ml-auto '>
                <p className='font--11 color-4'>
                  Textos da página /trabalhos
                </p>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className='row'>
                <div className='col-lg-12 '>
                  <div className='section-title service-style--3 mb--15 mb_sm--0  text-center'>
                    {this.state.breadcrumb_trabalhos ? (
                      <h2 className='title'>
                        <EditableText
                          value={this.state.breadcrumb_trabalhos}
                          onChange={e => this.setState({ breadcrumb_trabalhos: e })}
                        />
                      </h2>
                    ) : (
                      'breadcrumb trabalhos'
                    )}

                    <p>
                      {this.state.descricao_breadcrumb_trabalhos ? (
                        <EditableText
                          value={this.state.descricao_breadcrumb_trabalhos}
                          onChange={e =>
                            this.setState({ descricao_breadcrumb_trabalhos: e })
                          }
                        />
                      ) : (
                        'Equipe'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            expanded={this.state.expanded === 'panel3'}
            onChange={this.handleChange('panel3')}>
            <ExpansionPanelSummary
              expandIcon={<MdExpandMore />}
              aria-controls='panel2bh-content'
              id='panel2bh-header'
            >
              <div>
                <p>Equipe</p>
              </div>
              <div className='ml-auto '>
                <p className='font--11 color-4'>
                  Textos da pagina inicial
                </p>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='section-title service-style--3 text-left mb--25 mb_sm--0'>
                    {this.state.titulo_equipe ? (
                      <h2 className='title'>
                        <EditableText
                          value={this.state.titulo_equipe}
                          onChange={e => this.setState({ titulo_equipe: e })}
                        />
                      </h2>
                    ) : (
                      'Equipe'
                    )}

                    <p>
                      {this.state.descricao_equipe ? (
                        <EditableText
                          value={this.state.descricao_equipe}
                          onChange={e => this.setState({ descricao_equipe: e })}
                        />
                      ) : (
                        'Equipe'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            expanded={this.state.expanded === 'panel4'}
            onChange={this.handleChange('panel4')}>
            <ExpansionPanelSummary
              expandIcon={<MdExpandMore />}
              aria-controls='panel3bh-content'
              id='panel3bh-header'
            >
              <div>
                <p>Blog</p>
              </div>
              <div className='ml-auto '>
                <p className='font--11 color-4'>
                  Ira aparecer na pagina incial e pagina do blog
                </p>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className='row align-items-end'>
                <div className='col-lg-12'>
                  <div className='section-title text-left service-style--3'>
                    {this.state.titulo_blog ? (
                      <h2 className='title'>
                        <EditableText
                          value={this.state.titulo_blog}
                          onChange={e => this.setState({ titulo_blog: e })}
                        />
                      </h2>
                    ) : (
                      'Equipe'
                    )}

                    <p>
                      {this.state.descricao_blog ? (
                        <EditableText
                          value={this.state.descricao_blog}
                          onChange={e => this.setState({ descricao_blog: e })}
                        />
                      ) : (
                        'Equipe'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            expanded={this.state.expanded === 'panel5'}
            onChange={this.handleChange('panel5')}>
            <ExpansionPanelSummary
              expandIcon={<MdExpandMore />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <div>
                <p>Breadcrumb Blog</p>
              </div>
              <div className='ml-auto '>
                <p className='font--11 color-4'>
                  Textos da página /blog
                </p>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='section-title service-style--3 text-left mb--15 mb_sm--0 text-center'>
                    {this.state.breadcrumb_blog ? (
                      <h2 className='title'>
                        <EditableText
                          value={this.state.breadcrumb_blog}
                          onChange={e => this.setState({ breadcrumb_blog: e })}
                        />
                      </h2>
                    ) : (
                      'Equipe'
                    )}

                    <p>
                      {this.state.descricao_breadcrumb_blog ? (
                        <EditableText
                          value={this.state.descricao_breadcrumb_blog}
                          onChange={e =>
                            this.setState({ descricao_breadcrumb_blog: e })
                          }
                        />
                      ) : (
                        'Equipe'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            expanded={this.state.expanded === 'panel6'}
            onChange={this.handleChange('panel6')}>
            <ExpansionPanelSummary
              expandIcon={<MdExpandMore />}
              aria-controls='panel4bh-content'
              id='panel4bh-header'
            >
              <div>
                <p>Serviços</p>
              </div>
              <div className='ml-auto '>
                <p className='font--11 color-4'>
                  ira aparecer na pagina serviços
                </p>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className='service-area ptb--120 bg_color--5 w-100'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='section-title text-center '>
                        {this.state.titulo_servicos ? (
                          <h2 className='title'>
                            <EditableText
                              value={this.state.titulo_servicos}
                              onChange={e =>
                                this.setState({ titulo_servicos: e })
                              }
                            />
                          </h2>
                        ) : (
                          'Equipe'
                        )}

                        <p>
                          {this.state.descricao_servicos ? (
                            <EditableText
                              value={this.state.descricao_servicos}
                              onChange={e =>
                                this.setState({ descricao_servicos: e })
                              }
                            />
                          ) : (
                            'Equipe'
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            expanded={this.state.expanded === 'panel7'}
            onChange={this.handleChange('panel7')}>
            <ExpansionPanelSummary
              expandIcon={<MdExpandMore />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <div>
                <p>Breadcrumb Serviços</p>
              </div>
              <div className='ml-auto '>
                <p className='font--11 color-4'>
                  Textos da página /serviços
                </p>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='section-title service-style--3 mb--15 mb_sm--0 text-center'>
                    {this.state.breadcrumb_servicos ? (
                      <h2 className='title'>
                        <EditableText
                          value={this.state.breadcrumb_servicos}
                          onChange={e => this.setState({ breadcrumb_servicos: e })}
                        />
                      </h2>
                    ) : (
                      'Equipe'
                    )}

                    <p>
                      {this.state.descricao_breadcrumb_servicos ? (
                        <EditableText
                          value={this.state.descricao_breadcrumb_servicos}
                          onChange={e =>
                            this.setState({ descricao_breadcrumb_servicos: e })
                          }
                        />
                      ) : (
                        'Equipe'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            expanded={this.state.expanded === 'panel8'}
            onChange={this.handleChange('panel8')}>
            <ExpansionPanelSummary
              expandIcon={<MdExpandMore />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <div>
                <p>Breadcrumb Sobre Nós</p>
              </div>
              <div className='ml-auto '>
                <p className='font--11 color-4'>
                  Textos da página /sobre
                </p>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='section-title service-style--3 mb--15 mb_sm--0 text-center'>
                    {this.state.breadcrumb_sobre ? (
                      <h2 className='title'>
                        <EditableText
                          value={this.state.breadcrumb_sobre}
                          onChange={e => this.setState({ breadcrumb_sobre: e })}
                        />
                      </h2>
                    ) : (
                      ''
                    )}

                    <p>
                      {this.state.descricao_breadcrumb_sobre ? (
                        <EditableText
                          value={this.state.descricao_breadcrumb_sobre}
                          onChange={e =>
                            this.setState({ descricao_breadcrumb_sobre: e })
                          }
                        />
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            expanded={this.state.expanded === 'panel9'}
            onChange={this.handleChange('panel9')}>
            <ExpansionPanelSummary
              expandIcon={<MdExpandMore />}
              aria-controls='panel0bh-content'
              id='panel1bh-header'
            >
              <div>
                <p>Footer</p>
              </div>
              <div className='ml-auto'>
                <p className='font--11 color-4'>Adicionar Textos do footer</p>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <footer className='footer-area'>
                <div className='footer-wrapper'>
                  <div className='row align-items-end row--0'>
                    <div className='col-lg-6'>
                      <div className='footer-left'>
                        <div className='inner'>
                          {
                            this.state.footer_descricao
                            ? <span>
                              <EditableText value={this.state.footer_descricao} onChange= {(e) =>this.setState({footer_descricao: e})}/>
                            </span>
                            : <EditableText onChange= {(e) => this.setState({footer_descricao: e})}/>
                          }
                          <h2>
                            {
                            this.state.footer_titulo
                            ? <span>
                              <EditableText value={this.state.footer_titulo} onChange= {(e) =>this.setState({footer_titulo: e})}/>
                            </span>
                            : <EditableText onChange= {(e) => this.setState({footer_titulo: e})}/>
                          }
                          </h2>
                          <a className='rn-button-style--2' href='/contact'>
                            <span>Contate-Nos</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div
                        className='footer-right'
                        data-black-overlay='6'
                        style={{
                          backgroundImage: `url("/static/images/footer/${this.state.footerDois}")`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPositionY: 'bottom'
                        }}
                      >
                        <div className='row'>
                          {/* Start Single Widget  */}
                          <div className='col-lg-6 col-sm-6 col-12'>
                            <div className='footer-link'>
                              <h4>Links</h4>
                              <ul className='ft-link'>
                                <li>
                                  <a>Trabalho</a>
                                </li>
                                <li>
                                  <a>Sobre Nós</a>
                                </li>
                                <li>
                                  <a>Contato</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {/* End Single Widget  */}
                          {/* Start Single Widget  */}
                          <div className='col-lg-6 col-sm-6 col-12 mt_mobile--30'>
                            <div className='footer-link'>
                              <h4>Fale Conosco</h4>
                              <ul className='ft-link'>
                                  <li>
                                    <a>exemplo@email.com</a>
                                  </li>
                              </ul>

                              <div className='social-share-inner'>
                                <ul className='social-share social-style--2 d-flex justify-content-start liststyle mt--15'>
                                  <li><a><FaTwitter/></a></li>
                                  <li><a><FaFacebookF/></a></li>
                                  <li><a><FaInstagram/></a></li>
                                  <li><a><FaLinkedinIn/></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {/* End Single Widget  */}

                          <div className='col-lg-12'>
                            <div className='copyright-text'>
                              <p>
                                Copyright © {new Date().getFullYear()} Todos
                                direitos reservados.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <div className='row mt--50'>
            <div className='col-lg-12'>
              <div className='ml-auto d-flex justify-content-end align-items-end flex-grow-1 h-100'>
                <Fab
                onClick={() => this.salvar()}
                color='primary'
                style={{ position: 'fixed' }}
              >
                <MdSave size={30} />
              </Fab>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default AddStatico
