import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import {get}  from '../api'

class Testimonial extends Component {
  constructor (props) {
    super(props)
    this.state = {
      Testemunhos: [],
      selected: 0
    }
  }
  componentDidMount () {
    get('testemunhos/index').then(data =>{
      this.setState({ Testemunhos: data })
      this.setState({active: 0})
    }
    )
  }

  render () {
    return (
      <React.Fragment>
        <div className='row'>
          <div className='col-lg-12'>
            <Tabs defaultActiveKey={this.state.active} selectedIndex={this.state.selected}>
              {this.state.Testemunhos.map((value, i) => (
                <TabPanel key={i} >
                  <div className='rn-testimonial-content text-center'>
                    <div className='inner'>
                      <p>{value.descricao}</p>
                    </div>
                    <div className='author-info'>
                      <h6>
                        <span>{value.autor} </span> - {value.empresa}
                      </h6>
                    </div>
                  </div>
                </TabPanel>
              ))}

              <TabList className='testimonial-thumb-wrapper'>
                {this.state.Testemunhos.map((value, i) => (
                  <Tab key={i} isActive={true} onClick={() =>this.setState({selected: i})}>
                    <div className='testimonial-thumbnai'>
                      <div className='thumb'>
                        <img
                          src={`/static/images/testemunhos/${value.imagem}`}
                          alt='Testimonial Images'
                        />
                      </div>
                    </div>
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default Testimonial
