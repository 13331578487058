import React, { Component } from 'react'
import { get, post } from '../../api'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import {
  MdDelete,
  MdDrafts,
  MdContentCopy,
  MdDone,
  MdSave
} from 'react-icons/md'

import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import Alert from 'react-bootstrap/Alert'

import Paper from '@material-ui/core/Paper'

import Button from '@material-ui/core/Button'

const emailList = [
  {
    nome: 'Fugiat',
    email: 'fugiat@email.com',
    msg: 'Cupidatat ad nisi do qui Lorem ea pariatur elit dolor culpa culpa.'
  },
  {
    nome: 'Fugiat',
    email: 'fugiat@email.com',
    msg: 'Cupidatat ad nisi do qui Lorem ea pariatur elit dolor culpa culpa.'
  },
  {
    nome: 'Fugiat',
    email: 'fugiat@email.com',
    msg: 'Cupidatat ad nisi do qui Lorem ea pariatur elit dolor culpa culpa.'
  },
  {
    nome: 'Fugiat',
    email: 'fugiat@email.com',
    msg:
      'Pariatur reprehenderit amet dolore dolor aliqua. Commodo proident nulla tempor esse do eiusmod do duis. Tempor dolore aute tempor sunt proident culpa. Aliquip culpa pariatur duis nulla. Esse officia tempor Lorem et tempor pariatur ea. Eiusmod ea nulla eu velit consequat et labore aliqua. Amet irure amet dolore non nulla commodo proident pariatur proident eiusmod excepteur exercitation enim consequat.'
  }
]

const rows = [
  { nome: 'Fulano', email: 'fulano@gmail.com' },
  { nome: 'Fulano', email: 'fulano@gmail.com' },
  { nome: 'Fulano', email: 'fulano@gmail.com' },
  { nome: 'Fulano', email: 'fulano@gmail.com' },
  { nome: 'Fulano', email: 'fulano@gmail.com' },
  { nome: 'Fulano', email: 'fulano@gmail.com' },
  { nome: 'Fulano', email: 'fulano@gmail.com' },
  { nome: 'Fulano', email: 'fulano@gmail.com' },
  { nome: 'Fulano', email: 'fulano@gmail.com' },
  { nome: 'Fulano', email: 'fulano@gmail.com' }
]

class EmailList extends Component {
  constructor (props) {
    super()
    this.state = {
      selectedIndex: 0,
      openClipboardMsg: false,
      emails: [],
      open: false
    }
  }
  handleListItemClick (evento, index) {
    this.setState({ selectedIndex: index })
  }
  handleCopy () {
    this.setState({ openClipboardMsg: true })
  }
  handleClose (e, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ openClipboardMsg: false })
  }
  handleDialog = () =>{
    this.setState({open:!this.state.open})
  }
  handleClickEmail = (e, email) =>{
    this.setState({
      email: email.email,
      autor: email.nome,
      assunto: email.assunto,
      mensagem:email.mensagem,
      open: !this.state.open
    })

  }
  componentDidMount() {
    get('email/index')
      .then(data => this.setState({emails: data}))
  }
  deletar = (id) => {
    post('email/deletar', {id:id})
      .then(data => {
        get('email/index')
          .then(data => this.setState({emails:data}))
      })
  }
  render () {
    return (
      <>
        <div className='container'>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            autoHideDuration={6000}
            onClose={(e, r) => this.handleClose(e, r)}
            open={this.state.openClipboardMsg}
            transition={'SlideTransition'}
          >
            <Alert
              onClose={(e, r) => this.handleClose(e, r)}
              severity='success'
              style={{ backgroundColor: '#4caf50', color: 'white' }}
            >
              <MdDone size={25} className='mr--5' />
              Email copiados com sucesso
            </Alert>
          </Snackbar>

          <div className='row mb--40'>
              <Paper className="col-lg-6 d-flex flex-fill flex-column p-4 ">
                <h3>Configuração do Email</h3>
                <span>
                  Se existir algum provedor de email configurado para esse GDI,
                  você pode adicionar um email no campo abaixo para que todas
                  emails seja redirecionado para essa conta de email.
                </span>
                <div component={Paper} className='flex-grow-1'>
                  <TextField
                    className='w-100 mt--25 mb--25'
                    id='outlined-basic'
                    label='Email'
                    variant='outlined'
                  />
                </div>
                <div className='d-flex justify-content-between align-items-end ml-auto '>
                <Button
                  onClick={() => this.handleCopy()}
                  variant='contained'
                  color='primary'
                >
                  {' '}
                  <MdSave className='mr--5' /> Salvar
                </Button>

            </div>
              </Paper>
            <div className='col-lg-6 '>
              <h3>Contatos</h3>
              <TableContainer component={Paper} style={{ maxHeight: '400px' }}>
                <Table aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.emails.map(contato => (
                      <TableRow hover key={contato.nome}>
                        <TableCell>{contato.nome}</TableCell>
                        <TableCell>{contato.email}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className='mt--20 d-flex justify-content-end ml-auto w-75'>
                <Button
                  style={{ backgroundColor: '#26a69a' }}
                  onClick={() => this.handleCopy()}
                  variant='contained'
                  color='primary'
                >
                  {' '}
                  <MdContentCopy className='mr--5' /> Copiar Emails
                </Button>

              </div>

            </div>
            <Paper className="w-100 p-4 mt--25">
            <div className='col-lg-12'>
              <h3>Email recebidos</h3>
            </div>
            <div className='col-lg-12'>
              <List component='nav' aria-label='main mailbox folders'>
                {this.state.emails.map(email => (
                  <>
                    <ListItem
                      style={{ backgroundColor: '#fafafa' }}
                      button
                      selected={this.state.selectedIndex === 0}
                      onClick={event => this.handleClickEmail(event, email)}
                    >
                      <ListItemIcon>
                        <MdDrafts size={25} />
                      </ListItemIcon>
                      <ListItemText primary={email.nome} />
                      <ListItemText secondary={email.email} />
                      <ListItemText
                        secondary={`${email.mensagem.substr(0, 15)}...`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton aria-label='delete' onClick={() => this.deletar(email.id)}>
                          <MdDelete size={25} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </>
                ))}
              </List>
            </div>
          </Paper>
          </div>
          <Email
            open={this.state.open}
            handleClose={this.handleDialog}
            assunto={this.state.assunto}
            autor={this.state.autor}
            email={this.state.email}
            mensagem={this.state.mensagem}
          />
        </div>
      </>
    )
  }
}

export default EmailList


const Email = (props) => {
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.open]);
  return(
<div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{props.assunto} - {props.autor}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <p>Nome: {props.autor}</p>
            <p>Email: {props.email}</p>
            <p>Mensagem: {props.mensagem}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button onClick={props.handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}