import React, { Component } from 'react'
import parse from 'html-react-parser'
class Banner extends Component {
  render () {
    const {bg, titulo, subtitulo, tituloAlign, subtituloAlign, link} = this.props

    return (
      <>
        <div
          className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image`}
          style={{
            backgroundImage: `url("/static/images/banners/${bg}")`,
            backgroundPosition: 'center'
          }}
        >
          <div className='container h-100 w-100'>
            <div className='row h-100 w-100'>
              <div
                className={`col-lg-6 col-sm-12 d-flex justify-content-end jcmc ${tituloAlign} h-100`}
              >
                <div className={`inner p-0`}>
                <a href={link}>
                  <h1 className='title'>{
                    JSON.parse(titulo || '[]').map((el,index) => (<><span key={index}>{parse(el.data.text)}</span> <br/></>))
                  }</h1></a>
                </div>
              </div>
              <div
                className={`col-lg-6 col-sm-12 d-flex justify-content-start jcmc h-100 ${subtituloAlign}`}
              >
                <div className={`inner p-0`}>
                <a href={link}>
                  <p className='subtitulo-banner-imagem font-weight-light'
                    style={{lineHeight:'46px'}}
                  >
                  {
                    JSON.parse(subtitulo || '[]').map((el,index) => (<><span key={index}>{parse(el.data.text)} <br/></span></>))
                  }
                  </p>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </>
    )
  }
}
export default Banner
