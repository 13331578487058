import React, { Component } from 'react'
import EditableText from '../EditableText'
import IconButton from '@material-ui/core/IconButton'
import { MdInsertPhoto, MdSave } from 'react-icons/md'
import Button from '@material-ui/core/Button'
import Modal from 'react-bootstrap/Modal'
import { get, post, upload } from '../../api'
import Dropzone from './Dropzone'
import Fab from '@material-ui/core/Fab'
import Galeria from '../Galeria'
class AddAbout extends Component {
  constructor (props) {
    super()
    this.state = {
      id: 0,
      breadcrumb: 'Edição em linha',
      titulo1: '',
      paragrafo1: '',
      titulo2: '',
      paragrafo2: '',
      titulo3: '',
      paragrafo3: '',
      titulo4: '',
      paragrafo4: '',
      btn: '',
      image1: '',
      image2: '',
      galeriaTitulo: '',
      galeriaDescricao: '',
      modalUm: false,
      modalDois: false,
      galeria: [],
      render: false
    }
  }
  componentDidMount () {
    get('about/index').then(data =>
      this.setState(
        {
          id: data.id,
          image1: data.image1,
          image2: data.image2,
          breadcrumb: data.breadcrumb,
          titulo1: data.titulo1,
          titulo2: data.titulo2,
          titulo3: data.titulo3,
          titulo4: data.titulo4,
          paragrafo1: data.paragrafo1,
          paragrafo2: data.paragrafo2,
          paragrafo3: data.paragrafo3,
          paragrafo4: data.paragrafo4,
          btn: data.btn,
          galeriaTitulo: data.galeriaTitulo,
          galeriaDescricao: data.galeriaDescricao,
          galeria:
            JSON.parse(data.galeria) === null ? [] : JSON.parse(data.galeria)
        },
        () => this.setState({ render: true })
      )
    )
  }
  setImage = imgs => this.setState({ galeria: imgs })
  salvar () {
    const data = {
      id: this.state.id,
      image1: this.state.image1,
      image2: this.state.image2,
      breadcrumb: this.state.breadcrumb,
      titulo1: this.state.titulo1,
      titulo2: this.state.titulo2,
      titulo3: this.state.titulo3,
      titulo4: this.state.titulo4,
      paragrafo1: this.state.paragrafo1,
      paragrafo2: this.state.paragrafo2,
      paragrafo3: this.state.paragrafo3,
      paragrafo4: this.state.paragrafo4,
      btn: this.state.btn,
      galeria: JSON.stringify(this.state.galeria),
      galeriaTitulo: this.state.galeriaTitulo,
      galeriaDescricao: this.state.galeriaDescricao
    }
    post('about/salvar', data)
      .then(data => (window.location.href = '/gdi'))
      .catch(err => console.log(err))
  }
  handleChangeImage (event) {
    const formData = new FormData()
    formData.append('bgImage', event.target.files[0])
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      if (img.width === 500 && img.height === 665) {
        upload('about/upload_image01', formData)
          .then(data => {
            event.preventDefault()
            this.setState({ image1: data.upload_data.file_name })
          })
          .catch(err => console.log(err))
        return true
      }
      this.setState({ modalUm: true })
      return false
    }
  }
  handleImage2 (event) {
    const formData = new FormData()
    formData.append('bgImage', event.target.files[0])
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      if (img.width === 1287 && img.height === 703) {
        upload('about/upload_image02', formData)
          .then(data => {
            event.preventDefault()
            this.setState({ image2: data.upload_data.file_name })
          })
          .catch(err => console.log(err))
        return true
      }
      this.setState({ modalDois: true })
      return false
    }
  }
  render () {
    return (
      <>
        <Modal
          size='lg'
          show={this.state.modalUm}
          onHide={() => this.setState({ modalUm: false })}
          aria-labelledby='example-modal-sizes-title-sm'
        >
          <Modal.Header closeButton>
            <Modal.Title id='example-modal-sizes-title-sm'>
              Dimensões da imagem
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            As dimensões da imagem estão incorretas. As dimensões devem ser
            500x665
          </Modal.Body>
        </Modal>

        <Modal
          size='lg'
          show={this.state.modalDois}
          onHide={() => this.setState({ modalDois: false })}
          aria-labelledby='example-modal-sizes-title-sm'
        >
          <Modal.Header closeButton>
            <Modal.Title id='example-modal-sizes-title-sm'>
              Dimensões da imagem
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            As dimensões da imagem estão incorretas. As dimensões devem ser
            1287x703
          </Modal.Body>
        </Modal>
        <div className='row'>
          <div className='col-lg-10'>
            <h3>Personalizar pagina "Sobre Nós"</h3>
            <p>
              Aqui você pode editar o conteudo que irá aparecer na página
              inicial e na página sobre nós. Basta clicar nos textos para
              atualizar e abaixo de cada imagem você pode clicar no icone para
              trocar a imagem.
            </p>
          </div>
        </div>

        <div className='rn-about-area ptb--50 bg_color--1'>
          <h4
            style={{
              width: '100%',
              textAlign: 'center',
              borderBottom: '1px solid #212121',
              lineHeight: '0.1em',
              margin: '10px 0 20px'
            }}
          >
            <span style={{ backgroundColor: '#fff', padding: '0 10px' }}>
              Edição Inline
            </span>
          </h4>
          <div className='rn-about-wrapper'>
            <div className='container'>
              <div className='row row--35 align-items-center'>
                <div className='col-lg-5'>
                  <div className='thumbnail'>
                    <img
                      className='w-100'
                      src={`/static/images/about/01/${this.state.image1}`}
                      alt='About Images'
                    />
                    <div className='text-center'>
                      <input
                        accept='image/*'
                        id='icon-button-file'
                        type='file'
                        style={{ display: 'none' }}
                        onChange={event => this.handleChangeImage(event)}
                      />
                      <label
                        style={{ cursor: 'pointer' }}
                        htmlFor='icon-button-file'
                      >
                        <IconButton
                          color='primary'
                          aria-label='upload picture'
                          component='span'
                        >
                          <MdInsertPhoto size={40} />
                        </IconButton>
                        Adicionar imagem (500 x 665)
                      </label>
                    </div>
                  </div>
                </div>
                <div className='col-lg-7'>
                  <div className='about-inner inner'>
                    <div className='section-title'>
                      <h2 className='title'>
                        {this.state.titulo1 ? (
                          <EditableText
                            value={this.state.titulo1}
                            onChange={e => this.setState({ titulo1: e })}
                          />
                        ) : (
                          ''
                        )}
                      </h2>
                      <p className='description'>
                        {this.state.paragrafo1 ? (
                          <EditableText
                            value={this.state.paragrafo1}
                            onChange={e => this.setState({ paragrafo1: e })}
                          />
                        ) : (
                          ''
                        )}
                      </p>
                      <p>
                        {this.state.paragrafo2 ? (
                          <EditableText
                            value={this.state.paragrafo2}
                            onChange={e => this.setState({ paragrafo2: e })}
                          />
                        ) : (
                          ''
                        )}
                      </p>
                      <p>
                        {this.state.paragrafo3 ? (
                          <EditableText
                            value={this.state.paragrafo3}
                            onChange={e => this.setState({ paragrafo3: e })}
                          />
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                    {/* <div className='row mt--30'>
                      <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                        <div className='about-us-list'>
                          <h3 className='title'>
                            {this.state.titulo2 ? (
                              <EditableText value={this.state.titulo2}
                                onChange={(e) => this.setState({titulo2:e})}
                               />
                            ) : (
                              ''
                            )}
                          </h3>

                        </div>
                      </div>
                      <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                        <div className='about-us-list'>
                          <h3 className='title'>
                            {this.state.titulo3 ? (
                              <EditableText value={this.state.titulo3}
                                onChange={(e) => this.setState({titulo3:e})}
                              />
                            ) : (
                              ''
                            )}
                          </h3>

                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='rn-about-wrapper'>
          <div className='container'>
            <div className='row row--35'>
              <div className='col-lg-12'>
                <h2 className='title'>
                  {this.state.galeriaTitulo ? (
                    <EditableText
                      value={this.state.galeriaTitulo}
                      onChange={e => this.setState({ galeriaTitulo: e })}
                    />
                  ) : (
                    ''
                  )}
                </h2>
                <p className='description'>
                  {this.state.galeriaDescricao ? (
                    <EditableText
                      value={this.state.galeriaDescricao}
                      onChange={e => this.setState({ galeriaDescricao: e })}
                    />
                  ) : (
                    ''
                  )}
                </p>
              </div>
            </div>
            <div className='row row--35 align-items-center mt--15'>
              <div className='col-lg-12'>
                <Galeria galeria={this.state.galeria} />
              </div>
            </div>
          </div>
        </div>
        {this.state.render ? (
          <Dropzone
            galeria={this.state.galeria ? this.state.galeria : []}
            width={1500}
            height={1000}
            url='/about/upload_galeria'
            path='/static/images/about/galeria'
            setImage={this.setImage}
          />
        ) : (
          ''
        )}
        <div className='rn-finding-us-area rn-finding-us bg_color--1'>
          <div className='inner'>
            <div className='content-wrapper'>
              <div className='content'>
                <h4 className='theme-gradient'>
                  {this.state.titulo4 ? (
                    <EditableText
                      value={this.state.titulo4}
                      onChange={e => this.setState({ titulo4: e })}
                    />
                  ) : (
                    ''
                  )}
                </h4>
                <p>
                  {this.state.paragrafo4 ? (
                    <EditableText
                      value={this.state.paragrafo4}
                      onChange={e => this.setState({ paragrafo4: e })}
                    />
                  ) : (
                    ''
                  )}
                </p>
                <a className='rn-btn btn-white'>
                  {this.state.btn ? (
                    <EditableText value={'Personalizado'} />
                  ) : (
                    ''
                  )}
                </a>
              </div>
            </div>
            <div className='thumbnail'>
              <div className='image'>
                <img
                  src={`/static/images/about/02/${this.state.image2}`}
                  alt='Finding Images'
                />
                <div className='text-center mt--50'>
                  <input
                    accept='image/*'
                    id='imageDois'
                    type='file'
                    style={{ display: 'none' }}
                    onChange={e => this.handleImage2(e)}
                  />
                  <label style={{ cursor: 'pointer' }} htmlFor='imageDois'>
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                    >
                      <MdInsertPhoto size={40} />
                    </IconButton>
                    Adicionar imagem (1287x703)
                  </label>
                  <h4
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      borderBottom: '1px solid #212121',
                      lineHeight: '0.1em',
                      margin: '10px 0 20px'
                    }}
                  >
                    <span
                      style={{ backgroundColor: '#fff', padding: '0 10px' }}
                    >
                      Fim Edição Inline
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row mt--125'>
            <div className='col-lg-6 ml-auto d-flex justify-content-end align-items-end'>
              <Fab
                onClick={() => this.salvar()}
                color='primary'
                style={{ position: 'fixed' }}
              >
                <MdSave size={30} />
              </Fab>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default AddAbout
