import React, { Component } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from 'react-bootstrap/Alert'
import {
  MdDone,
  MdClear,
} from 'react-icons/md'
import { get, post } from '../../api'
class ContactTwo extends Component {
  constructor (props) {
    super(props)
    this.state = {
      rnName: '',
      rnEmail: '',
      rnSubject: '',
      rnMessage: '',
      contatoIndex: {},
      open: false,
      success: false,
      msg: 'Error'
    }
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ open: false })
  }
  validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }
  componentDidMount () {
    get('contato/index').then(data => this.setState({ contatoIndex: data[0] }))
  }
  sendMessage = () => {
    if (this.state.rnEmail === '') {
        this.setState({msg: 'Preencha os campos obrigatorios*', success: false,  open:true})
    } else {
      if (!this.validateEmail(this.state.rnEmail)) {
        this.setState({msg: 'Preencha um email válido', success:false,  open:true})
      }else{
        post('email/salvar', {
          nome: this.state.rnName,
          email:this.state.rnEmail,
          assunto:this.state.rnSubject,
          mensagem: this.state.rnMessage
        })
          .then(data => {
            this.setState({success:true,  open:true})
          })

      }
    }
  }
  render () {
    return (
      <div className='contact-form--1'>
     <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            autoHideDuration={6000}
            onClose={(e, r) => this.handleClose(e, r)}
            open={this.state.open}
            transition={'SlideTransition'}
          >
          {
            this.state.success ?
            <Alert
              onClose={(e, r) => this.handleClose(e, r)}
              severity='success'
              style={{ backgroundColor: '#4caf50', color: 'white' }}
            >
              <MdDone size={25} className='mr--5' />
              Mensagem Enviada
            </Alert>
            :
            <Alert
              onClose={(e, r) => this.handleClose(e, r)}
              severity='success'
              style={{ backgroundColor: '#f44336', color: 'white' }}
            >
              <MdClear size={25} className='mr--5' />
              {this.state.msg}
            </Alert>
          }

          </Snackbar>
        <div className='container'>
          <div className='row row--35 align-items-start'>
            <div className='col-lg-6 order-2 order-lg-1'>
              <div className='section-title text-left mb--50'>
                <h2 className='title'>{this.state.contatoIndex.formTitulo}</h2>
                <p className='description'>
                  {this.state.contatoIndex.formDescricao}
                </p>
              </div>
              <div className='form-wrapper'>
                <label htmlFor='item01'>
                  <input
                    type='text'
                    name='name'
                    id='item01'
                    value={this.state.rnName}
                    onChange={e => {
                      this.setState({ rnName: e.target.value })
                    }}
                    placeholder='Nome  *'
                  />
                </label>

                <label htmlFor='item02'>
                  <input
                    type='text'
                    name='email'
                    id='item02'
                    value={this.state.rnEmail}
                    onChange={e => {
                      this.setState({ rnEmail: e.target.value })
                    }}
                    placeholder='Email *'
                  />
                </label>

                <label htmlFor='item03'>
                  <input
                    type='text'
                    name='subject'
                    id='item03'
                    value={this.state.rnSubject}
                    onChange={e => {
                      this.setState({ rnSubject: e.target.value })
                    }}
                    placeholder='Assunto'
                  />
                </label>
                <label htmlFor='item04'>
                  <textarea
                    type='text'
                    id='item04'
                    name='message'
                    value={this.state.rnMessage}
                    onChange={e => {
                      this.setState({ rnMessage: e.target.value })
                    }}
                    placeholder='Mensagem'
                  />
                </label>
                <button
                  className='rn-button-style--2 btn-solid'
                  name='submit'
                  id='mc-embedded-subscribe'
                  onClick={this.sendMessage}
                >
                  Enviar
                </button>
              </div>
            </div>
            <div className='col-lg-6 order-1 order-lg-2'>
              <div className='thumbnail mb_md--30 mb_sm--30'>
                <img
                  alt=''
                  src={`/static/images/contatos/form/${this.state.contatoIndex.formImagem}`}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
export default ContactTwo
