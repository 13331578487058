const template = [
  {
    type: 'paragraph',
    data: {
      text:
        "but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing"
    }
  },
  {
    type: 'image',
    data: {
      file: {
        url: '/static/images/blog/bl-big-01.jpg',
        width: 600,
        height: 650
      },
      caption: 'Descricao',
      withBorder: false,
      stretched: false,
      withBackground: false
    }
  },
  {
    type: 'paragraph',
    data: {
      text:
        'hidden in the middle of text. All the Lorem Ipsum generators tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet.'
    }
  },
  {
    type: 'quote',
    data: {
      text:
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes.',
      caption: '',
      alignment: 'left'
    }
  },
  {
    type: 'paragraph',
    data: {
      level: 4,
      text: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text."
    }
  }
]

export default template
