import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { MdVpnKey, MdSave } from 'react-icons/md'
import Paper from '@material-ui/core/Paper'
import { Input } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import auth from '../../auth'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from 'react-bootstrap/Alert'
import {
  MdDone,
  MdClear,
} from 'react-icons/md'

export default class AddUsuario extends Component {
  constructor () {
    super()
    this.state = {
      nome: '',
      email: '',
      senha: ''
    }
  }
  handleChangeInput = input => this.setState({ [input.name]: input.value })
  isEmailValidate = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }
  isPasswordValidate = senha => {
    if  (this.state.user_id || senha.length >= 8) {
      return true
    }
    return false
  }
  mudarSenha = () => {
    fetch('https://dev-z5qcv7az.auth0.com/api/v2/tickets/password-change', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`
      },
      body: JSON.stringify({
        user_id: this.state.user_id
      })
    })
      .then(response => response.json())
      .then(data => window.location = data.ticket)
      .catch(err => console.log(err))
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ open: false })
  }
  salvar = () => {
    if (
      this.state.nome.length > 0 &&
      this.state.email.length > 0 &&
      (  this.state.user_id || this.state.senha.length > 0)
    ) {
      if (
        this.isEmailValidate(this.state.email) &&
        this.isPasswordValidate(this.state.senha)
      ) {
        if(this.state.user_id){
          fetch(`https://dev-z5qcv7az.auth0.com/api/v2/users/${this.state.user_id}`, {
          method:  'PATCH',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`
          },
          body: JSON.stringify({
            name: this.state.nome,
            email: this.state.email,
            connection: 'Username-Password-Authentication'
          })
        })
          .then(response => response.json())
          .then(data => {
            window.location = '/gdi/index/usuarios'
            return
          })
          .catch(err => `Não foi possivel connectar pois ${err}`)
        }else{
          fetch(`https://dev-z5qcv7az.auth0.com/api/v2/users`, {
          method:'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`
          },
          body: JSON.stringify({
            name: this.state.nome,
            email: this.state.email,
            password:this.state.senha,
            connection: 'Username-Password-Authentication'
          })
        })
          .then(response => response.json())
          .then(data => {
            window.location = '/gdi/index/usuarios'
            return
          })
          .catch(err => `Não foi possivel connectar pois ${err}`)
        }

      } else {
        if (!this.isEmailValidate(this.state.email)) {
          this.setState({open:true, msg: 'Email Inválido'})
        }
        if (!this.isPasswordValidate(this.state.senha)) {
          this.setState({open:true, msg: 'Senha Inválida. Minímo de oito caracteres'})
        }
      }
    } else {
      this.setState({open:true, msg: 'Preencha todos os campos'})
    }
  }
  componentDidMount () {
    const user_id = this.props.match.params.id
    this.setState({ user_id: user_id })
    if (user_id) {
      fetch(`https://dev-z5qcv7az.auth0.com/api/v2/users/${user_id}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          console.log(data)
          this.setState({
            nome: data.name,
            email: data.email,
            senha: data.password
          })
        })
        .catch(err => console.log(err))
    }
  }

  render () {
    return (
      <div className='container'>
      <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            autoHideDuration={6000}
            onClose={(e, r) => this.handleClose(e, r)}
            open={this.state.open}
            transition={'SlideTransition'}
          >
          {
            this.state.success ?
            <Alert
              onClose={(e, r) => this.handleClose(e, r)}
              severity='success'
              style={{ backgroundColor: '#4caf50', color: 'white' }}
            >
              <MdDone size={25} className='mr--5' />
              Usuário Criado
            </Alert>
            :
            <Alert
              onClose={(e, r) => this.handleClose(e, r)}
              severity='success'
              style={{ backgroundColor: '#f44336', color: 'white' }}
            >
              <MdClear size={25} className='mr--5' />
              {this.state.msg}
            </Alert>
          }

          </Snackbar>
        <h3>Adicionar um novo usuário</h3>
        <Paper className='pt--60 pb--60 pl--60 pr--60 mb--25'>
          <div className='row'>
            <div className='col-lg-12'>
              <h4>Dados:</h4>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <TextField
                value={this.state.nome}
                fullWidth
                type='name'
                name='nome'
                label='Nome'
                variant='outlined'
                onChange={e => this.handleChangeInput(e.target)}
              />
            </div>
            <div className='col-lg-6 mt-lg-0 mt-sm-2'>
              <TextField
                value={this.state.email}
                fullWidth
                type='email'
                name='email'
                label='Email'
                variant='outlined'
                onChange={e => this.handleChangeInput(e.target)}
              />
            </div>
            <div className='mt--15 col-lg-6'>
              {this.state.user_id ? (
                <Button
                  onClick={() => this.mudarSenha()}
                  variant='contained'
                  size='large'
                  startIcon={<MdVpnKey />}
                >
                  Mudar Senha
                </Button>
              ) : (
                <TextField
                  value={this.state.senha}
                  fullWidth
                  type='password'
                  name='senha'
                  label='Senha'
                  variant='outlined'
                  onChange={e => this.handleChangeInput(e.target)}
                />
              )}
            </div>
          </div>
        </Paper>
        <div className='row justify-content-end align-items-end'>
          <div className='col-lg-4'>
            <div className='ml-auto d-flex justify-content-end'>
              <Button
                onClick={() => this.salvar()}
                variant='contained'
                color='primary'
                size='large'
                startIcon={<MdSave />}
              >
                Salvar
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
