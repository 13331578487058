import React, { Component } from 'react'
import PageHelmet from '../components/common/Helmet'
import ModalVideo from 'react-modal-video'
import LoadingScreen from 'react-loading-screen'
import { get } from '../api'
import {
  ServicoModeloUm,
  ServicoModeloDois
} from '../elements/modelos/servico/ModeloServico'

class ServiceDetails extends Component {
  constructor () {
    super()
    this.state = {
      isOpen: false,
      conteudo: {},
      blocos: [],
      loading:true
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal () {
    this.setState({ isOpen: true })
  }
  componentDidMount () {
    let match = this.props.match.params.titulo
    match = match.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    get(`servicos/get/${match}`)
      .then(data => {
        this.setState(data)
        this.setState({loading:false})
        window.scrollTo(0, 0)
      })
      .catch(err => console.error(err))
  }

  render () {
    // let blocosF = readEditor(this.state.blocos);

    return (
      <React.Fragment>
      <LoadingScreen
          loading={this.state.loading}
          bgColor='#f1f1f1'
          spinnerColor='#9ee5f8'
          textColor='#676767'
        >
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle={this.state.titulo || ''} />
        {/* End Pagehelmet  */}

        {/* Start Breadcrump Area */}
        <div id='service'>
          <div
            className='rn-page-title-area pt--120 pb--190 bg_image'
            data-black-overlay='5'
            style={{
              background: `url('/static/images/servicos/breadcrumb/${this.state.breadcrumb}')`,
              backgroundPosition: 'center center'
            }}
          >
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='rn-page-title text-center pt--100 d-flex justify-content-center flex-column'>
                    <h2 className='title theme-gradient'>
                      {this.state.titulo}
                    </h2>
                    <p>{this.state.breadcrumbDescricao}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrump Area */}

          {/* Start Page Wrapper */}
          <div className='rn-service-details ptb--120 bg_color--1'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='service-details-inner'>
                    <div className='inner'>
                      {this.state.blocos.map(bloco =>
                        // <h1>{bloco.modelo}</h1>
                        qualModelo(bloco)
                      )}
                      {/* Start Single Area */}
                      {/* <div className='row sercice-details-content pb--80 align-items-center'>
                      <div className='col-lg-6 col-12'>
                        <div className='thumb'>
                          <img
                            className='w-100'
                            src='/static/images/service/service-01.png'
                            alt='Service Images'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6 col-12'>
                        <div className='details mt_md--30 mt_sm--30'>
                          <p>
                            but the majority have suffered alteration in some
                            form, by injected humour, or randomised words which
                            don't look even slightly believable. If you are
                            going to use a passage of Lorem Ipsum. You need to
                            be sure there isn't anything embarrassing
                          </p>
                          <p>
                            hidden in the middle of text. All the Lorem Ipsum
                            generators tend toitrrepeat predefined chunks.
                            Necessary, making this the first true generator on
                            the Internet.
                          </p>
                          <h4 className='title'>Proceess of metel</h4>
                          <ul className='liststyle'>
                            <li>Yet this above sewed flirted opened ouch</li>
                            <li>Goldfinch realistic sporadic ingenuous</li>
                            <li>
                              Abominable this abidin far successfully then like
                              piquan
                            </li>
                            <li>Risus commodo viverra</li>
                            <li>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                      {/* End Single Area */}

                      {/* Start Single Area */}
                      {/* <div className='row sercice-details-content align-items-center'>
                      <div className='col-lg-6 col-12 order-2 order-lg-1'>
                        <div className='details mt_md--30 mt_sm--30'>
                          <p>
                            but the majority have suffered alteration in some
                            form, by injected humour, or randomised words which
                            don't look even slightly believable. If you are
                            going to use a passage of Lorem Ipsum. You need to
                            be sure there isn't anything embarrassing
                          </p>
                          <p>
                            hidden in the middle of text. All the Lorem Ipsum
                            generators tend toitrrepeat predefined chunks.
                            Necessary, making this the first true generator on
                            the Internet.
                          </p>
                          <h4 className='title'>Our Working Process</h4>
                          <ul className='liststyle'>
                            <li>Yet this above sewed flirted opened ouch</li>
                            <li>Goldfinch realistic sporadic ingenuous</li>
                            <li>
                              Abominable this abidin far successfully then like
                              piquan
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-lg-6 col-12 order-1 order-lg-2'>
                        <div className='thumb position-relative'>
                          <img
                            className='w-100'
                            src='/static/images/service/service-02.png'
                            alt='Service Images'
                          />
                          <ModalVideo
                            channel='youtube'
                            isOpen={this.state.isOpen}
                            videoId='ZOoVOfieAF8'
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className='video-popup'
                            onClick={this.openModal}
                          >
                            <span className='play-icon'></span>
                          </button>
                        </div>
                      </div>
                    </div> */}
                      {/* End Single Area */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Page Wrapper */}
        </div>
        </LoadingScreen>
      </React.Fragment>
    )
  }
}
export default ServiceDetails

const qualModelo = bloco => {
  const conteudo = bloco.conteudo
  switch (bloco.modelo) {
    case 'servico-modelo-um':
      return React.createElement(ServicoModeloUm, {
        bloco: conteudo
      })
    case 'servico-modelo-dois':
      return React.createElement(ServicoModeloDois, {
        bloco: conteudo
      })
    default:
      break
  }
}
