import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { NavLink as RouterNavLink } from 'react-router-dom'
import { Index } from '../elements/gdi/Index'
import error404 from '../elements/error404'
import AddBanner from '../elements/gdi/AddBanner'
import AddBannerImagem from '../elements/gdi/AddBannerImagem'
import AddTrabalho from '../elements/gdi/AddTrabalho'
import AddServico from '../elements/gdi/AddServico'
import AddAbout from '../elements/gdi/AddAbout'
import AddEquipe from '../elements/gdi/AddEquipe'
import AddBlog from '../elements/gdi/AddBlog'
import AddContato from '../elements/gdi/AddContato'
import AddTestemunhos from '../elements/gdi/AddTestemunhos'
import AddParceiro from '../elements/gdi/AddParceiro'
import EmailList from '../elements/gdi/EmailList'
import AddSocial from '../elements/gdi/AddSocial'
import AddStatico from '../elements/gdi/AddStatico'
import Gerenciamento from '../elements/gdi/Gerenciamento'
import AddUsuario from '../elements/gdi/AddUsuario'
import Home from '../elements/gdi/Home'
import ReactHelmet from 'react-helmet'

import { useAuth0 } from '../react-auth0-spa'
import history from '../utils/history'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import {
  MdViewCarousel,
  MdWork,
  MdGroup,
  MdFace,
  MdChromeReaderMode,
  MdBrush,
  MdPerson,
  MdLocalPhone,
  MdGraphicEq,
  MdAssignment
} from 'react-icons/md'
import { RiHandHeartLine } from 'react-icons/ri'
import { FaHandsHelping } from 'react-icons/fa'
import { IoIosAt, IoMdGitNetwork } from 'react-icons/io'
import { GiTatteredBanner } from 'react-icons/gi'
import {useEffect} from 'react'
const drawerWidth = 220

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })

  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

  },
  drawerPaper: {
    width: drawerWidth,

  },
  drawerHeader: {

    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
}))

export default function GdiTeste () {
  const { user, logout } = useAuth0()
  const [open, setOpen] = React.useState(true)

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin
    })
  const size = useWindowSize();
  const classes = useStyles()
  const theme = useTheme()
  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    if(size.width > 720){
      setOpen(true)
    }else{
      setOpen(false)
    }
  }, [size])
  return (

    <Router history={history}>
    <ReactHelmet>
      <title>GDI ArtCenter</title>
      <link rel="icon"  href={`/static/images/taura/favicon.png`}/>
    </ReactHelmet>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          // position='static'
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open
          })}
          style={{ backgroundColor: '#4CB0AE' }}
        >
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <div
              className='d-flex justify-content-between ml-auto align-items-center'
              style={{ width: '60%' }}
            >
              <div className='logo'>
                <RouterNavLink
                  to='/'
                  exact
                  activeClassName='router-link-exact-active'
                ></RouterNavLink>
                <img
                  src='/static/images/logo/taura-gdi.png'
                  alt='Logo do GDI Taura'
                />
              </div>

              <div className='user-profile'>
                <UncontrolledDropdown
                  style={{ listStyle: 'none' }}
                  nav
                  inNavbar
                >
                  <DropdownToggle nav caret id='profileDropDown'>
                    <img
                      style={{ width: '2.5rem' }}
                      src={user.picture}
                      alt='Profile'
                      className='nav-user-profile rounded-circle'
                    />
                  </DropdownToggle>
                  <DropdownMenu className='pr--50'>
                    <DropdownItem header>{user.name}</DropdownItem>
                    <DropdownItem
                      tag={RouterNavLink}
                      to='/profile'
                      className='dropdown-profile'
                      activeClassName='router-link-exact-active'
                    ></DropdownItem>
                    <DropdownItem
                      id='qsLogoutBtn'
                      onClick={() => logoutWithRedirect()}
                    >
                      Deslogar
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor='left'
          open={open}

          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader} style={{background: '#fff'}}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            <label  className='ml--15 muted font--13 text-black-50'>
              <b>Geral</b>
            </label>
            <Link to='/gdi'>
              <ListItem button style={{ padding: '0 1rem' }}>
                <ListItemIcon style={{ padding: '0  ' }}>
                  <GiTatteredBanner size={20} />
                </ListItemIcon>
                <ListItemText className='mt--0 mb--0'>
                  <h6 className='mt--15 font--14'>Home</h6>
                </ListItemText>
              </ListItem>
            </Link>
            <Link to='/gdi/email-list'>
              <ListItem button style={{ padding: '0 1rem' }}>
                <ListItemIcon>
                  <IoIosAt size={20} />
                </ListItemIcon>
                <ListItemText className='mt--0 mb--0'>
                  <h6 className='mt--15 font--14'>Email</h6>
                </ListItemText>
              </ListItem>
            </Link>
          </List>
          <Divider />

          <List>
          <label  className='ml--15 mt--5 muted font--13 text-black-50'>
              <b>Conteúdo</b>
            </label>
            <Link to='/gdi/estatico/add'>
              <ListItem button style={{ padding: '0 1rem' }}>
                <ListItemIcon>
                  <MdGraphicEq size={20} />
                </ListItemIcon>
                <ListItemText className='mt--0 mb--0'>
                  <h6 className='mt--15 font--14' >Estático</h6>
                </ListItemText>
              </ListItem>
            </Link>
            <Link to='/gdi/index/banners'>
              <ListItem button style={{ padding: '0 1rem' }}>
                <ListItemIcon>
                  <MdViewCarousel size={20} />
                </ListItemIcon>
                <ListItemText className='mt--0 mb--0'>
                  <h6 className='mt--15 font--14'>Banners</h6>
                </ListItemText>
              </ListItem>
            </Link>
             <Link to='/gdi/index/servicos'>
              <ListItem button style={{ padding: '0 1rem' }}>
                <ListItemIcon>
                  <MdBrush size={20} />
                </ListItemIcon>
                <ListItemText className='mt--0 mb--0'>
                  <h6 className='mt--15 font--14'>Serviços</h6>
                </ListItemText>
              </ListItem>
            </Link>
            <Link to='/gdi/about'>
              <ListItem button style={{ padding: '0 1rem' }}>
                <ListItemIcon>
                  <MdGroup size={20} />
                </ListItemIcon>
                <ListItemText className='mt--0 mb--0'>
                  <h6 className='mt--15 font--14'>Sobre Nós</h6>
                </ListItemText>
              </ListItem>
            </Link>
            <Link to='/gdi/index/trabalhos'>
              <ListItem button style={{ padding: '0 1rem' }}>
                <ListItemIcon>
                  <MdWork size={20} />
                </ListItemIcon>
                <ListItemText className='mt--0 mb--0'>
                  <h6 className='mt--15 font--14'>Trabalhos</h6>
                </ListItemText>
              </ListItem>
            </Link>
            <Link to='/gdi/index/equipe'>
              <ListItem button style={{ padding: '0 1rem' }}>
                <ListItemIcon>
                  <MdFace size={20} />
                </ListItemIcon>
                <ListItemText className='mt--0 mb--0'>
                  <h6 className='mt--15 font--14'>Equipe</h6>
                </ListItemText>
              </ListItem>
            </Link>
            <Link to='/gdi/index/blog'>
              <ListItem button style={{ padding: '0 1rem' }}>
                <ListItemIcon>
                  <MdChromeReaderMode size={20} />
                </ListItemIcon>
                <ListItemText className='mt--0 mb--0'>
                  <h6 className='mt--15 font--14'>Blog</h6>
                </ListItemText>
              </ListItem>
            </Link>
            <Link to='/gdi/index/depoimentos'>
              <ListItem button style={{ padding: '0 1rem' }}>
                <ListItemIcon>
                  <RiHandHeartLine size={20} />
                </ListItemIcon>
                <ListItemText className='mt--0 mb--0'>
                  <h6 className='mt--15 font--14'>Depoimentos</h6>
                </ListItemText>
              </ListItem>
            </Link>
            <Link to='/gdi/contato/add'>
              <ListItem button style={{ padding: '0 1rem' }}>
                <ListItemIcon>
                  <MdLocalPhone size={20} />
                </ListItemIcon>
                <ListItemText className='mt--0 mb--0'>
                  <h6 className='mt--15 font--14'>Contato</h6>
                </ListItemText>
              </ListItem>
            </Link>
            <Link to='/gdi/index/parceiros'>
              <ListItem button style={{ padding: '0 1rem' }}>
                <ListItemIcon>
                  <FaHandsHelping size={20} />
                </ListItemIcon>
                <ListItemText className='mt--0 mb--0'>
                  <h6 className='mt--15 font--14'>Parceiros</h6>
                </ListItemText>
              </ListItem>
            </Link>

          </List>
          <Divider/>
          <List>
          <label  className='ml--15 muted font--13 text-black-50'>
              <b>Configurações</b>
            </label>
          <Link to='/gdi/social-medias'>
              <ListItem button style={{ padding: '0 1rem' }}>
                <ListItemIcon>
                  <IoMdGitNetwork size={20} />
                </ListItemIcon>
                <ListItemText className='mt--0 mb--0'>
                  <h6 className='mt--15 font--14'>Redes Socias</h6>
                </ListItemText>
              </ListItem>
            </Link>
            <Link to='/gdi/gerenciamento'>
              <ListItem button style={{ padding: '0 1rem' }}>
                <ListItemIcon>
                  <MdAssignment size={20} />
                </ListItemIcon>
                <ListItemText className='mt--0 mb--0'>
                  <h6 className='mt--15 font--14'>Gerenciamento</h6>
                </ListItemText>
              </ListItem>
            </Link>
            <Link to='/gdi/index/usuarios'>
              <ListItem button style={{ padding: '0 1rem' }}>
                <ListItemIcon>
                  <MdPerson size={20} />
                </ListItemIcon>
                <ListItemText className='mt--0 mb--0'>
                  <h6 className='mt--15 font--14'>Usuarios</h6>
                </ListItemText>
              </ListItem>
            </Link>
          </List>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open
          })}
        >
          <div className={classes.drawerHeader} />
          <Switch>
            <Route exact path='/gdi/' component={Home} />
            <Route exact path='/gdi/email-list' component={EmailList} />
            <Route exact path='/gdi/social-medias' component={AddSocial} />
            <Route exact path='/gdi/index/:id' component={Index} />
            {/* <Route exact path='/gdi/banners' component={IndexBanners}/> */}
            <Route exact path='/gdi/banners/add/:id' component={AddBanner} />
            <Route exact path='/gdi/banners/add/' component={AddBanner} />
            <Route exact path='/gdi/banners-image/add/:id' component={AddBannerImagem} />
            <Route exact path='/gdi/banners-image/add/' component={AddBannerImagem} />
            {/* <Route exact path='/gdi/servicos' component={IndexServicos}/> */}
            <Route exact path='/gdi/servicos/add/:id' component={AddServico} />
            <Route exact path='/gdi/servicos/add' component={AddServico} />

            <Route exact path='/gdi/about' component={AddAbout} />

            <Route exact path='/gdi/equipe/add/:id' component={AddEquipe} />
            <Route exact path='/gdi/equipe/add' component={AddEquipe} />


            <Route
              exact
              path='/gdi/depoimentos/add/:id'
              component={AddTestemunhos}
            />
            <Route
              exact
              path='/gdi/depoimentos/add'
              component={AddTestemunhos}
            />
            <Route
              exact
              path='/gdi/parceiros/add/:id'
              component={AddParceiro}
            />
            <Route exact path='/gdi/parceiros/add' component={AddParceiro} />

            <Route exact path='/gdi/contato/add/:id' component={AddContato} />
            <Route exact path='/gdi/contato/add' component={AddContato} />

            <Route
              exact
              path='/gdi/trabalhos/add/:id'
              component={AddTrabalho}
            />

            <Route exact path='/gdi/trabalhos/add' component={AddTrabalho} />

            <Route exact path='/gdi/blog/add' component={AddBlog} />
            <Route exact path='/gdi/blog/add/:id' component={AddBlog} />
            <Route exact path='/gdi/estatico/add' component={AddStatico} />
            <Route exact path='/gdi/gerenciamento' component={Gerenciamento}/>
            <Route exact path='/gdi/usuarios/add' component={AddUsuario}/>
            <Route exact path='/gdi/usuarios/add/:id' component={AddUsuario}/>
            <Route exact path='' component={error404} />
          </Switch>
        </main>
      </div>
    </Router>
  )
}
function useWindowSize() {

  const isClient = typeof window === 'object';



  function getSize() {

    return {

      width: isClient ? window.innerWidth : undefined,

      height: isClient ? window.innerHeight : undefined

    };

  }



  const [windowSize, setWindowSize] = React.useState(getSize);



  useEffect(() => {

    if (!isClient) {

      return false;

    }



    function handleResize() {

      setWindowSize(getSize());

    }



    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);

  }, []); // Empty array ensures that effect is only run on mount and unmount



  return windowSize;

}