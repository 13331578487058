import React, { Component, useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import { ReactSVG } from 'react-svg'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import { MdArrowForward, MdAdd } from 'react-icons/md'
import Profile from '../../components/profile'
import { useAuth0 } from '../../react-auth0-spa'
import { Link } from 'react-router-dom'
import { MdDelete, MdDrafts } from 'react-icons/md'
import {get, post} from '../../api'
const emailList = [
  {
    nome: 'Fugiat',
    email: 'fugiat@email.com',
    msg: 'Cupidatat ad nisi do qui Lorem ea pariatur elit dolor culpa culpa.'
  },
  {
    nome: 'Fugiat',
    email: 'fugiat@email.com',
    msg: 'Cupidatat ad nisi do qui Lorem ea pariatur elit dolor culpa culpa.'
  },
  {
    nome: 'Fugiat',
    email: 'fugiat@email.com',
    msg: 'Cupidatat ad nisi do qui Lorem ea pariatur elit dolor culpa culpa.'
  },
  {
    nome: 'Fugiat',
    email: 'fugiat@email.com',
    msg:
      'Pariatur reprehenderit amet dolore dolor aliqua. Commodo proident nulla tempor esse do eiusmod do duis. Tempor dolore aute tempor sunt proident culpa. Aliquip culpa pariatur duis nulla. Esse officia tempor Lorem et tempor pariatur ea. Eiusmod ea nulla eu velit consequat et labore aliqua. Amet irure amet dolore non nulla commodo proident pariatur proident eiusmod excepteur exercitation enim consequat.'
  }
]
class Home extends Component {
  constructor () {
    super()
    this.state = {
      selectedIndex: 0,
      emails: []
    }
  }
  handleListItemClick (evento, index) {
    this.setState({ selectedIndex: index })
  }
  componentDidMount() {
    get('email/index')
      .then(data => this.setState({emails: data}))
  }
  deletar = (id) => {
    post('email/deletar', {id:id})
      .then(data => {
        get('email/index')
          .then(data => this.setState({emails:data}))
      })
  }

  render () {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 pl--25 pr--25'>
            {/* Titulo e Data */}
            <div className='row justify-content-between'>
              <div classname='col-lg-4'>
                <h4>GDI</h4>
              </div>
              <div classname='col-lg-4 align-items-center'>
                <h6 className='text-muted'>{DiaHoje(new Date())}</h6>
              </div>
            </div>
            {/* FIM */}

            {/* Card de Introdução */}
            <Paper style={{ backgroundColor: '#e8eaf6' }}>
              <div className='row justify-content-around mt--25'>
                {/* Texto */}
                <div className='col-lg-6  p-lg-2 p-sm-5 col-md-8 align-content-center d-flex align-items-center justify-content-center flex-column'>
                  <h3>
                    Bem vindo,{' '}
                    <span style={{ color: '#3f51b5' }}>
                      <User />
                    </span>
                  </h3>
                  <span className='text-center'>
                    Aqui é sua pagina inicial, você encontra vários atalhos e
                    links úteis para construção do seu site.
                  </span>
                </div>
                {/* FIM */}

                {/* SVG */}
                <div className='col-lg-2 col-md-8'>
                  <div
                    style={{ width: '150px', height: '150px' }}
                    className='m-auto mt-sm-4'
                  >
                    <ReactSVG
                      className='m-auto'
                      src='/static/images/svg/gdi_home.svg'
                      beforeInjection={svg => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('style', 'width: 200px')
                      }}
                    />
                  </div>
                </div>
                {/* FIM */}
              </div>
            </Paper>
            {/* FIM */}

            {/* Email e Geranciamento */}

            {/* Header */}
            <div className='row mt--25 align-items-center'>
              <div className='col-lg-3 col-sm-6'>
                <h4>Emails</h4>
              </div>
              <div
                className='col-lg-3 col-sm-6 d-flex justify-content-end'
                style={{ marginTop: '-15px' }}
              >
                <Link to="/gdi/email-list">

                <span className='mr--10'>Mais</span>
                <MdArrowForward
                  size={20}
                  style={{ marginTop: '2px' }}
                  className='mr--10'
                />
                </Link>
              </div>

              <div className='col-lg-3 d-lg-flex d-sm-none'>
                <h4>Configurações</h4>
              </div>
              <div
                className='col-lg-3 d-lg-flex d-sm-none justify-content-end'
                style={{ marginTop: '-15px' }}
              >
                <Link to="gdi/gerenciamento">
                <span className='mr--10'>Mais</span>
                <MdArrowForward
                  size={20}
                  style={{ marginTop: '2px' }}
                  className='mr--10'
                />
                </Link>
              </div>
            </div>

            {/* Cards */}
            <div className='row '>
              <div className='col-lg-6'>
                <Paper className='mb-sm-4 mb-lg-0'>
                  <List component='Paper' aria-label='main mailbox folders'>
                    {this.state.emails.map((email, index) => (
                        <Link to="/gdi/email-list">
                        <ListItem
                          key={index}
                          style={{ backgroundColor: '#fafafa' }}
                          button
                          selected={this.state.selectedIndex === 0}
                          onClick={event => this.handleListItemClick(event, 0)}
                        >
                          <ListItemIcon>
                            <MdDrafts size={25} />
                          </ListItemIcon>
                          <ListItemText secondary={email.email} />
                          <ListItemSecondaryAction>
                            <IconButton aria-label='delete'>
                              <MdDelete size={25} onClick={() => this.deletar(email.id)}/>
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        </Link>
                    ))}
                  </List>
                </Paper>
              </div>

              <div className='col-lg-6 d-flex flex-column justify-content-between '>
                <div className='row d-lg-none d-sm-flex'>
                  <div className='col-sm-6 '>
                    <h4>Configurações</h4>
                  </div>
                  <div
                    className='col-sm-6 d-sm-flex justify-content-end align-items-center'
                    style={{ marginTop: '-15px' }}
                  >
                    <span className='mr--10'>Mais</span>
                    <MdArrowForward
                      size={20}
                      style={{ marginTop: '2px' }}
                      className='mr--10'
                    />
                  </div>
                </div>
                <div className='row '>
                  <div className='col-lg-12 '>
                    <Link to='/gdi/gerenciamento'>
                      <Paper
                        className='quickAccessCard'
                        style={{ backgroundColor: '#f0f4c3' }}
                      >
                        <div>
                          <ReactSVG
                            className='m-auto'
                            src='/static/images/svg/google_analytics-icon.svg'
                          />
                        </div>
                        <div class='text-center'>
                          Gerenciar <br /> Analytics
                        </div>
                        <div>
                          <MdArrowForward
                            size={20}
                            style={{ marginTop: '2px' }}
                            className='mr--10'
                          />
                        </div>
                      </Paper>
                    </Link>
                  </div>
                </div>
                <div className='row '>
                  <div className='col-lg-12'>
                    <Link to='/gdi/social-medias'>
                      <Paper
                        className='quickAccessCard'
                        style={{ backgroundColor: '#e8eaf6' }}
                      >
                        <div>
                          <ReactSVG
                            className='m-auto'
                            src='/static/images/svg/fb.svg'
                          />
                        </div>
                        <div class='text-center'>
                          Adicionar <br /> Redes Sociais
                        </div>
                        <div>
                          <MdArrowForward
                            size={20}
                            style={{ marginTop: '2px' }}
                            className='mr--10'
                          />
                        </div>
                      </Paper>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* FIM Email e Gerenciamento */}

            {/* Links Rápidos */}
            <div className='row mt--25'>
              <div className='col-lg-12'>
                <h4>Acessos Rápidos</h4>
              </div>
            </div>
            <div className='row justify-content-between mt--5'>
              <div className='col-lg-4'>
                <Link to='/gdi/banners-image/add'>
                  <Paper
                    className='quickAccessCard'
                    style={{ backgroundColor: '#00897b' }}
                  >
                    <div>
                      <ReactSVG
                        className='m-auto'
                        src='/static/images/svg/picture.svg'
                      />
                    </div>
                    <div class='text-center' style={{ color: 'white' }}>
                      Adicionar <br /> Banner
                    </div>
                    <div>
                      <MdAdd
                        size={15}
                        style={{ marginTop: '2px' }}
                        color={'white'}
                      />
                    </div>
                  </Paper>
                </Link>
              </div>
              <div className='col-lg-4'>
                <Link to='/gdi/blog/add'>
                  <Paper
                    className='quickAccessCard'
                    style={{ backgroundColor: '#3f51b5' }}
                  >
                    <div>
                      <ReactSVG
                        className='m-auto'
                        src='/static/images/svg/blogger.svg'
                      />
                    </div>
                    <div class='text-center text-white'>
                      Adicionar <br /> Post
                    </div>
                    <div>
                      <MdAdd
                        size={15}
                        style={{ marginTop: '2px' }}
                        color='white'
                      />
                    </div>
                  </Paper>
                </Link>
              </div>
              <div className='col-lg-4'>
                <Link to='/gdi/trabalhos/add'>
                  <Paper
                    className='quickAccessCard'
                    style={{ backgroundColor: '#e53935' }}
                  >
                    <div>
                      <ReactSVG
                        className='m-auto'
                        src='/static/images/svg/briefcase.svg'
                      />
                    </div>
                    <div class='text-center text-white'>
                      Adicionar <br /> Trabalho
                    </div>
                    <div>
                      <MdAdd
                        size={15}
                        style={{ marginTop: '2px' }}
                        color='white'
                      />
                    </div>
                  </Paper>
                </Link>
              </div>
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='row d-lg-flex d-sm-none'>
              <div className='col-lg-12 p-3 d-flex justify-content-around flex-column'>
                <Profile />
              </div>
            </div>
            <div className='row pl--25 mt-sm-5'>
              <div className='col-lg-12 '>
                <h4>Links Uteis</h4>
              </div>
            </div>
            <div className='row pl--25 '>
              <div className='col-lg-12 '>
                <a
                  href='https://compressor.io/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Paper className='p-3 quickLink'>
                    <div className='row justify-content-between'>
                      <div className='col-8'>
                        <p className='font--15'>
                          <b>compressor.io</b>
                        </p>
                      </div>
                      <div className='col-4 d-flex justify-content-end'>
                        <ReactSVG src='/static/images/svg/compress.svg' />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-10'>
                        <span className='text-muted font--12'>
                          Esse site irá ajudá-lo a diminuir o peso das imagens
                          sem perda de qualidade para que seu site carregue mais
                          rápido.
                        </span>
                      </div>
                      <div className='col-2 d-flex justify-content-end align-items-end'>
                        <MdArrowForward size={15} />
                      </div>
                    </div>
                  </Paper>
                </a>
              </div>
            </div>
            <div className='row pl--25 mt--25'>
              <div className='col-lg-12 '>
                <a
                  href='https://www.iloveimg.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Paper className='p-3 quickLink'>
                    <div className='row justify-content-between'>
                      <div className='col-8'>
                        <p className='font--15'>
                          <b>iloveimg.com</b>
                        </p>
                      </div>
                      <div className='col-4 d-flex justify-content-end'>
                        <ReactSVG src='/static/images/svg/iloveimg.svg' />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-10'>
                        <span className='text-muted font--12'>
                          Nesses sites você pode realizar o recorte preciso de
                          imagens.
                        </span>
                      </div>
                      <div className='col-2 d-flex justify-content-end align-items-end'>
                        <MdArrowForward size={15} />
                      </div>
                    </div>
                  </Paper>
                </a>
              </div>
            </div>
            <div className='row pl--25 mt--25'>
              <div className='col-lg-12 '>
                <a
                  href='https://developers.google.com/maps/documentation/javascript/get-api-key'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Paper className='p-3 quickLink'>
                    <div className='row justify-content-between'>
                      <div className='col-8'>
                        <p className='font--15'>
                          <b>maps.google.com</b>
                        </p>
                      </div>
                      <div className='col-4 d-flex justify-content-end'>
                        <ReactSVG src='/static/images/svg/google-maps.svg' />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-10'>
                        <span className='text-muted font--12'>
                          Aqui você pode pegar a chave da API para o mapa do
                          site funcionar corretamente.
                        </span>
                      </div>
                      <div className='col-2 d-flex justify-content-end align-items-end'>
                        <MdArrowForward size={15} />
                      </div>
                    </div>
                  </Paper>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Home

const DiaHoje = data => {
  const mes = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez'
  ]
  const diaDaSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']

  return `${data.getDate()} ${mes[data.getMonth()]} ${data.getFullYear()}, ${
    diaDaSemana[data.getDay()]
  }`
}

const User = () => {
  useEffect(() => {}, [])
  const { loading, user } = useAuth0()
  if (loading) {
    return 'carregando'
  }
  return <>{user.name}</>
}



