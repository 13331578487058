import React from 'react'
import parse from 'html-react-parser'

export const readEditor = data => {

  const d = data.bloco
  let textos = []
  let media = []

  d.forEach(block => {
    switch (block.type) {
      case 'paragraph':
        textos.push({ texto: <p>{parse(block.data.text)}</p> })
        break
      case 'header':
        const header = React.createElement(
          `h${block.data.level}`,
          {},
          parse(block.data.text)
        )
        textos.push({ texto: header })
        break
      case 'list':
        textos.push({
          texto: React.createElement(
            'ul',
            { className: 'list-style' },
            block.data.items.map(data =>
              React.createElement('li', {}, parse(data))
            )
          )
        })
        break
      case 'image':
        media.push({
          type: 'image',
          src: block.data.file.url,
          alt: block.caption
        })
        break
      case 'embed':
        media.push({
          type: 'embed',
          src: block.data.source,
          alt: block.caption
        })
        break
      default:
        break
    }
  })
  return { textos, media }
}

export const blogReadEditor = data => {
  const d = data.bloco
  let formatacao = []

  d.forEach(bloco => {
    switch (bloco.type) {
      case 'paragraph':
        formatacao.push({
          el: <p>{parse(bloco.data.text)}</p>
        })
        break
      case 'header':
        const header = React.createElement(
          `h${bloco.data.level}`,
          {},
          parse(bloco.data.text)
        )
        formatacao.push({ el: header })
        break
      case 'image':
        const imagem = React.createElement(
          'div',
          { className: 'thumbnail mb--40 important', style: {marginBottom: '40px !important'} },
          React.createElement('img', {
            src: bloco.data.file.url,
            alt: bloco.data.caption,

          })
        )
        formatacao.push({ el: imagem })
        break
      case 'quote':
        const blockquote = React.createElement('blockquote', {className: 'rn-blog-quote'}, parse(bloco.data.text))
        formatacao.push({el: blockquote})
        break;
      default:
        break
    }
  })
  return formatacao
}
